import React from "react";
import { FiChevronLeft } from "react-icons/fi";

const PAGE_NAMES = {
  BASE_CONFIG: "Base Config",
  DC_DESKTOP: "Desktop",
  DC_SMARTPHONE: "Smartphone",
  DC_TABLET: "Tablet",
};

class AutoPilotViewHeader extends React.PureComponent {
  render() {
    const {
      networkInfo,
      unitInfo,
      onBackToOverview,
      currentPage,
      currentHintId,
    } = this.props;

    return (
      <div className="bg-gray-100 p-4 border-b shadow sticky">
        {unitInfo ? (
          <div className="flex">
            {currentPage !== "OVERVIEW" && (
              <>
                <div
                  className="bg-gray-300 hover:bg-gray-400 text-gray-800 cursor-pointer px-2 -my-4 -ml-4 mr-4 px-4 flex items-center border-r border-gray-400"
                  onClick={onBackToOverview}
                >
                  <div className="mr-2">
                    <FiChevronLeft></FiChevronLeft>
                  </div>
                  Back
                </div>

                {currentHintId ? (
                  <div className="text-blue-700 px-2 -my-4 -ml-4 mr-4 px-4 flex items-center font-bold border-r border-gray-400">
                    <div>
                      <div className="text-xs text-gray-500 leading-tight">
                        USER HINT
                      </div>
                      {PAGE_NAMES[currentHintId]}
                    </div>
                  </div>
                ) : (
                  <div className="text-gray-800 px-2 -my-4 -ml-4 mr-4 px-4 flex items-center font-bold border-r border-gray-400">
                    {PAGE_NAMES[currentPage]}
                  </div>
                )}
              </>
            )}

            <div className="font-semibold text-xl">
              <span className="text-blue-600">Unit Auto Pilot Config: </span>
              {unitInfo && (
                <span>
                  {unitInfo.gamUnitId} {unitInfo.name}
                </span>
              )}
            </div>
          </div>
        ) : (
          <div className="font-semibold text-xl">
            <span className="text-blue-600">Network Auto Pilot Config: </span>
            {networkInfo && (
              <span>
                {networkInfo.gamNetworkId} {networkInfo.name}
              </span>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default AutoPilotViewHeader;
