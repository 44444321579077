import React from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

class PublishersTable extends React.PureComponent {
  render() {
    const { items, hideAction, isLoading } = this.props;
    const thClass =
      "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
    const tdClass = "px-4 py-2";

    return (
      <>
        <table className="min-w-full shadow divide-y">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass} style={{ width: "40%" }}>
                Publisher
              </th>
              {/* <th className={thClass}>Country</th> */}
              <th className={thClass}>Account Type</th>
              <th className={thClass}>Trial Status</th>

              {!hideAction && <th className={thClass}>Action</th>}
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-sm">
            {items.map((item) => {
              return (
                <tr key={item.publisherId} className="hover:bg-gray-100">
                  <td className={tdClass}>
                    <span className="text-lg font-semibold text-gray-800">
                      {item.publisherId} - {item.name}
                    </span>
                    <div className="text-gray-600 text-xs">
                      {item.type}
                      {/* - {item.googleAccountType} */}
                    </div>
                  </td>

                  {/* <td className={tdClass}>{item.geoCountry.alpha2}</td> */}

                  <td className={tdClass}>
                    <div className="font-semibold text-gray-700">
                      {item.accountType === "DUMMY" ? "-" : item.accountType}
                    </div>
                  </td>

                  <td className={tdClass}>
                    {item.accountStatus && item.accountStatus.trialStatus ? (
                      <div>
                        <div className="text-left">
                          <span className="text-gray-700">
                            Trial Expire Date:{" "}
                          </span>
                          <span>
                            {item.accountStatus.trialStatus.expDate
                              ? item.accountStatus.trialStatus.expDate.substring(
                                  0,
                                  10
                                )
                              : "-"}
                          </span>
                        </div>
                        <div className="text-left">
                          <span className="text-gray-700">
                            Billable Start Date:{" "}
                          </span>
                          <span>
                            {item.accountStatus.trialStatus.billableStartDate
                              ? item.accountStatus.trialStatus.billableStartDate.substring(
                                  0,
                                  10
                                )
                              : "-"}
                          </span>
                        </div>
                        {/* <div>
                          Remaining Days:{" "}
                          {item.accountStatus.trialStatus.remainingDays}
                        </div> */}
                      </div>
                    ) : (
                      "-"
                    )}
                  </td>

                  {!hideAction && (
                    <td className={tdClass}>
                      {item.accountType === "TRIAL" ||
                      item.accountType === "TRIAL_EXPIRED" ||
                      item.accountType === "ACTIVE" ||
                      item.accountType === "INACTIVE" ||
                      item.accountType === "CHARGE_FAILED" ? (
                        <Link
                          to={`/ops-mgmt/account/publisher/${item.publisherId}/update-trial-status`}
                          className={`px-2 py-1 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold
     `}
                        >
                          Update Trial Status
                        </Link>
                      ) : (
                        "-"
                      )}
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default PublishersTable;
