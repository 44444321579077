import React from "react";
import ReactTooltip from "react-tooltip";
import { FiCopy } from "react-icons/fi";
import { CopyToClipboard } from "react-copy-to-clipboard";

class ClickToCopyWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isCopied: false,
    };
  }

  render() {
    const { isCopied } = this.state;
    const { copyText } = this.props;
    return (
      <>
        <div className="flex items-center">
          <CopyToClipboard
            text={copyText}
            onCopy={() => this.setState({ isCopied: true })}
          >
            <button
              type="button"
              className="hover:text-blue-600 px-2"
              data-tip
              data-for={copyText}
              onMouseLeave={() => this.setState({ isCopied: false })}
            >
              <FiCopy></FiCopy>
            </button>
          </CopyToClipboard>
          <ReactTooltip id={copyText} type="dark" effect="solid">
            {isCopied ? "Copied!" : "Click to copy"}
          </ReactTooltip>
        </div>
      </>
    );
  }
}

export default ClickToCopyWrapper;
