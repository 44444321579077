import React from "react";
import HealthCheckItem from "./HealthCheckItem";
import TrendsRevenueSummary from "./TrendsRevenueSummary";
import RpmOverview from "./RpmOverview";

class OverviewView extends React.PureComponent {
  render() {
    const {
      // reports
      revenue,
      experimentParams,
      rpms,

      healthCheck,
      handleSelectTab,
      networkTimezone,
    } = this.props;
    return (
      <div className="py-8 px-4 bg-gray-300 min-h-screen">
        <RpmOverview rpms={rpms}></RpmOverview>

        <RevenueSummarySection
          revenue={revenue}
          experimentParams={experimentParams}
          networkTimezone={networkTimezone}
        ></RevenueSummarySection>

        <HealthCheckSection
          healthCheck={healthCheck}
          handleSelectTab={handleSelectTab}
        ></HealthCheckSection>
      </div>
    );
  }
}

class RevenueSummarySection extends React.PureComponent {
  render() {
    return (
      <div className="mb-8">
        <div className="font-semibold text-lg text-gray-700 mb-2">
          Revenue Summary:
        </div>

        <div className="bg-white rounded shadow px-4 py-6">
          <TrendsRevenueSummary
            revenue={this.props.revenue}
            experimentParams={this.props.experimentParams}
            networkTimezone={this.props.networkTimezone}
          ></TrendsRevenueSummary>
        </div>
      </div>
    );
  }
}

class HealthCheckSection extends React.PureComponent {
  render() {
    const { healthCheck, handleSelectTab } = this.props;

    return (
      <>
        <div className="font-semibold text-lg text-gray-700 mb-2">
          Health Checks:
        </div>

        <div>
          {healthCheck.map((item) => {
            return (
              <div key={item.name} className="w-full mr-2">
                <HealthCheckItem
                  item={item}
                  handleSelectTab={handleSelectTab}
                ></HealthCheckItem>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

export default OverviewView;
