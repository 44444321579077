import React from "react";
import { withRouter } from "react-router-dom";
import { getLatestObserverData, getObserverData } from "../../helpers/Api";
import LoadingUI from "../common/LoadingUI";
import ObserverOverview from "./ObserverOverview";

class ObserverViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,
      errorMsg: null,
      unitId: props.unitId,
      selectedDateRange: 3,
    };

    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleForceRefresh = this.handleForceRefresh.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unitId !== this.props.unitId) {
      this.setState({ unitId: this.props.unitId });
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    let data = null;

    try {
      if (this.props.unitId) {
        document.title = `Unit: ${this.props.unitId} | YB Observer`;
        data = await this.queryLatestData(this.state.selectedDateRange);
      } else if (this.props.resultKey) {
        document.title = `Result: ${this.props.resultKey} | YB Observer`;
        data = await this.queryResultData();
      } else {
        // No
      }

      if (data) {
        this.setState({ data });
      } else {
        this.setState({ errorMsg: "No Data" });
      }
    } catch (err) {
      this.setState({
        errorMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  async handleForceRefresh() {
    this.setState({ isLoading: true, data: null });

    try {
      const isForceRefresh = true;

      const data = await this.queryLatestData(
        this.state.selectedDateRange,
        isForceRefresh
      );

      this.setState({ data });
    } catch (err) {
      console.log("Error querying observer data", err);
    }

    this.setState({ isLoading: false });
  }

  async queryLatestData(dateRange, isForceRefresh) {
    return await getLatestObserverData(
      this.props.unitId,
      dateRange,
      isForceRefresh
    );
  }

  async queryResultData() {
    return await getObserverData(this.props.resultKey);
  }

  async handleDateRangeChange(dateRange) {
    if (dateRange === this.state.selectedDateRange) {
      return;
    }

    this.setState({ isLoading: true, data: null });
    try {
      const data = await this.queryLatestData(dateRange);
      this.setState({ selectedDateRange: dateRange });

      if (data) {
        this.setState({ data });
      } else {
        this.setState({ errorMsg: "No Data" });
      }
    } catch (err) {
      this.setState({
        errorMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { data, isLoading, errorMsg, selectedDateRange } = this.state;

    return (
      <>
        {isLoading && <LoadingUI></LoadingUI>}

        {data && (
          <>
            {this.props.resultKey && (
              <ObserverOverview
                data={data}
                // selectedDateRange={selectedDateRange}
                // onDateRangeChange={this.handleDateRangeChange}
              ></ObserverOverview>
            )}

            {!this.props.resultKey && this.state.unitId && (
              <ObserverOverview
                data={data}
                selectedDateRange={selectedDateRange}
                onDateRangeChange={this.handleDateRangeChange}
                handleForceRefresh={this.handleForceRefresh}
              ></ObserverOverview>
            )}
          </>
        )}

        {errorMsg && <div className="text-white">{errorMsg}</div>}
      </>
    );
  }
}

export default withRouter(ObserverViewer);
