import React, { useState } from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { withRouter } from "react-router-dom";
import { getExpDebugLog } from "../../helpers/Api";
import LoadingUI from "../common/LoadingUI";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import BetterPopupWrapper from "../common/BetterPopupWrapper";
import MicroExpView from "./AnatomyDebugExpView";

class AnatomyDebugViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errorMsg: null,

      data: null,
      currentAlgoVersion: null,
      currentExp: null,

      expId: props.expId,
    };

    this.onMicroExpVersionChanged = this.onMicroExpVersionChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.expId !== this.props.expId) {
      this.setState({ expId: this.props.expId });
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    let data = null;

    try {
      if (this.props.expId) {
        document.title = `EXP-${this.props.expId} | YB Observer`;
        data = await this.queryData(this.props.expId);
        console.log(data);
        // data = anatomyDebugSample;

        if (data) {
          const currentExp = data.micro_exps[0];
          const currentAlgoVersion = currentExp.algo_version;

          this.setState({
            data,
            currentAlgoVersion,
            currentExp,
          });
        } else {
          this.setState({ errorMsg: "No Data" });
        }
      } else {
        // No
      }
    } catch (err) {
      this.setState({
        errorMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  async queryData(expId) {
    return await getExpDebugLog({ expId });
  }

  onMicroExpVersionChanged(version) {
    console.log(version);
    const currentExp = _.find(this.state.data.micro_exps, {
      algo_version: version,
    });
    this.setState({ currentAlgoVersion: version, currentExp });
  }

  render() {
    const { expId } = this.props;
    const { data, currentAlgoVersion, currentExp, isLoading, errorMsg } =
      this.state;

    return (
      <>
        {isLoading && <LoadingUI></LoadingUI>}
        {errorMsg && <div className="text-red-600">{errorMsg}</div>}

        {data && (
          <div className="bg-gray-300 h-screen">
            <HeaderView
              expId={expId}
              metadata={data.metadata}
              exps={data.micro_exps}
              currentAlgoVersion={currentAlgoVersion}
              onMicroExpVersionChanged={this.onMicroExpVersionChanged}
            ></HeaderView>

            <MicroExpView exp={currentExp}></MicroExpView>
          </div>
        )}
      </>
    );
  }
}

function MicroExpTabs({ exps, currentAlgoVersion, onMicroExpVersionChanged }) {
  const titles = _.map(exps, "algo_version");
  // const titles = ["v1", "v2"];
  return (
    <div>
      {titles.map((title) => {
        const isCurrent = currentAlgoVersion == title;
        const titleClass = `inline py-1 px-2 border rounded text-sm ${
          isCurrent ? "bg-white" : "hover:bg-white cursor-pointer"
        }`;

        return (
          <div
            className={titleClass}
            key={title}
            onClick={() => onMicroExpVersionChanged(title)}
          >
            algo_version: {title}
          </div>
        );
      })}
    </div>
  );
}

function MetadataPopup({ metadata }) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <BetterPopupWrapper
      // ref={this.child}
      position={"bottom"}
      triggerType="click"
      keepInside={true}
      triggerElement={
        <button
          type="button"
          className="text-blue-400 font-semibold hover:underline"
        >
          Metadata
        </button>
      }
      contentElement={
        <div style={{ width: "300px" }} className="p-1">
          {/* this dummy input so that textarea does not auto focus */}
          <input style={{ display: "none" }}></input>
          <textarea
            // style={{ width: "100%", fontSize: "14px" }}
            className="bg-gray-300 text-sm w-full"
            rows={20}
            defaultValue={JSON.stringify(metadata, null, 4)}
            readOnly
          ></textarea>
        </div>
      }
    ></BetterPopupWrapper>
  );

  return (
    <div className="bg-gray-200 rounded">
      <div
        className="hover:bg-gray-300 cursor-pointer flex items-center justify-center"
        onClick={toggleOpen}
      >
        Metadata
        {isOpen ? <FiChevronUp></FiChevronUp> : <FiChevronDown></FiChevronDown>}
      </div>

      {isOpen && <div>{JSON.stringify(metadata, null, 4)}</div>}
    </div>
  );
}

function HeaderView({
  expId,
  metadata,
  exps,
  currentAlgoVersion,
  onMicroExpVersionChanged,
}) {
  const {
    time,
    unit_id,
    segment_id,
    autopilot,
    mode,
    maximum_supported_price,
  } = metadata;
  return (
    <div className="border-b border-gray-500 shadow mb-3 -mx-4 px-8 py-2">
      <div className="flex justify-between items-center mb-2 border-b border-gray-400 -mx-4 px-4 -my-2 py-2">
        <div className="flex gap-2 items-center">
          <div className="font-bold">EXP-{expId}</div>

          <div className="pl-2 border-l border-gray-400 text-sm text-gray-800">
            <div>Unit ID: {unit_id}</div>
            <div>
              Segment ID: {segment_id} <span>(MODE: {mode})</span>
            </div>
          </div>
        </div>

        <div>
          <MetadataPopup metadata={metadata}></MetadataPopup>
        </div>

        <div className="text-sm text-gray-800">
          <div>Autopilot: {autopilot.key}</div>
          <div>Max Price: ${maximum_supported_price}</div>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div>
          <div className="uppercase text-xs font-semibold text-gray-700 leading-none">
            Micro Exps ({exps.length}):
          </div>
          <MicroExpTabs
            exps={exps}
            currentAlgoVersion={currentAlgoVersion}
            onMicroExpVersionChanged={onMicroExpVersionChanged}
          ></MicroExpTabs>
        </div>

        <div className="text-gray-700">
          <div className="uppercase text-xs font-semibold leading-none">
            Time:
          </div>
          <div className="text-sm">
            {time} ({moment(time).fromNow()})
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AnatomyDebugViewer);
