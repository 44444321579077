import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

import { notify } from "react-notify-toast";

import {
  getNetworks,
  setNetworkAdServingCost,
  updateNetworkAdServingCost,
} from "../../../helpers/Api";
import NetworksTable from "./NetworksTable";
import LoadingUI from "../../common/LoadingUI";

class UpdateNetworkAdServingCost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      networkId: null,
      tempNetwork: null,
      errMsg: "",

      algoCostCpm: null,
      costCpm: null,
      freeQuota: null,
    };

    this.handleUpdate = this.handleUpdate.bind(this);
    this.onInputChanged = this.onInputChanged.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { networkId } = this.props.match.params;
      const queryParams = {
        networkIds: [networkId],
      };
      const items = await getNetworks(queryParams);
      console.log(items[0]);

      const item = items[0];
      const algoCostCpm = _.get(item, "algoCostCpm");
      const costCpm = _.get(item, "adServingCost.costCpm");
      const freeQuota = _.get(item, "adServingCost.freeQuota");

      this.setState({
        networkId,
        tempNetwork: item,

        algoCostCpm,
        costCpm,
        freeQuota,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  onInputChanged(value, key) {
    this.setState({
      [key]: value,
    });
  }

  async handleUpdate() {
    try {
      const { networkId, algoCostCpm, costCpm, freeQuota } = this.state;
      if (!algoCostCpm || !costCpm || !freeQuota) {
        throw "Missing 'Algo Cost CPM', 'Cost CPM', or 'Free Quota'!";
      }

      let params = {
        networkId,
        algoCostCpm,
        costCpm,
        freeQuota,
      };

      this.setState({
        isSaving: true,
      });
      console.log(params);
      const result = await updateNetworkAdServingCost(params);
      console.log(result);

      notify.show(
        "Network updated! Redirecting back to Networks...",
        "success"
      );

      this.setState({
        isSaving: false,
        errMsg: "",
      });

      setTimeout(() => {
        // window.location.reload(false);
        this.props.history.goBack();
      }, 3000);
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }
  }

  render() {
    const {
      networkId,
      tempNetwork,

      algoCostCpm,
      costCpm,
      freeQuota,

      isLoading,
      isSaving,
      errMsg,
    } = this.state;

    const inputClass =
      "bg-white flex w-full border-2 border-gray-300 rounded py-1 px-4 appearance-none leading-normal focus:outline-none focus:border-blue-400 focus:shadow-inner hover:shadow hover:border-blue-300";

    return (
      <div>
        <div className="bg-white px-12 pt-8 pb-4">
          <div className="font-extrabold text-gray-900 text-4xl">
            Update Network Ad Serving Cost
          </div>

          {tempNetwork && (
            <NetworksTable
              items={[tempNetwork]}
              hideAction={true}
            ></NetworksTable>
          )}
        </div>

        {isLoading && <LoadingUI></LoadingUI>}

        {tempNetwork && (
          <div className="bg-gray-200 px-12 py-8 min-h-screen">
            <div>
              <div className="flex justify-center">
                <div className="w-1/2 p-4 border rounded shadow">
                  <div className="py-2 font-bold text-lg">
                    {tempNetwork.networkId} - {tempNetwork.name}
                  </div>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Algo Cost CPM (in {tempNetwork.currency})
                    </label>
                    <div>
                      <input
                        type="number"
                        step="0.0001"
                        className={inputClass}
                        value={algoCostCpm}
                        onChange={(e) =>
                          this.onInputChanged(e.target.value, "algoCostCpm")
                        }
                      ></input>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Cost CPM (in {tempNetwork.currency})
                    </label>
                    <div>
                      <input
                        type="number"
                        step="0.0001"
                        className={inputClass}
                        value={costCpm}
                        onChange={(e) =>
                          this.onInputChanged(e.target.value, "costCpm")
                        }
                      ></input>
                    </div>
                  </div>

                  <hr></hr>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Free Quota
                    </label>
                    <div>
                      <input
                        type="number"
                        className={inputClass}
                        value={freeQuota}
                        min="0"
                        onChange={(e) =>
                          this.onInputChanged(e.target.value, "freeQuota")
                        }
                      ></input>
                    </div>
                  </div>
                  {/* <div>{JSON.stringify(this.state, null, 4)}</div> */}
                  <div className="flex flex-row-reverse mt-4 items-center">
                    <div>
                      <button
                        type="button"
                        disabled={isSaving}
                        className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                        onClick={() => this.handleUpdate()}
                      >
                        {isSaving ? "Saving..." : "Update Network"}
                      </button>
                    </div>
                    <div>
                      <button
                        type="button"
                        disabled={isSaving}
                        className={`px-4 py-2 text-blue-700 ${
                          isSaving ? "cursor-not-allowed" : ""
                        }`}
                        onClick={() => this.props.history.goBack()}
                      >
                        Back
                      </button>
                    </div>
                    <div className="text-red-600">{errMsg}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UpdateNetworkAdServingCost;
