import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { FiArrowDown } from "react-icons/fi";
import { Link } from "react-router-dom";
import NumberFormat from "../../common/NumberFormat";
// import PopupWrapper from "../common/PopupWrapper";
// import Popup from "reactjs-popup";

/**
 * Publisher
Invoice no
Start date
End date
currency
amount
i2w approval status
approval status
 */

// Please advise whether you have received the invoice from ABC which was said posted to us on 2/3/2018.
class InvoicesTable extends React.PureComponent {
  render() {
    const {
      items,
      isSaving,
      actions,
      columns,
      tabName,
      onHandleAdvise,
      onHandleApprove,
      onHandleIssue,
      onHandleVoid,
      onHandleBadDebt,
    } = this.props;
    const thClass =
      "px-2 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
    const tdClass = "px-2 py-2";

    return (
      <>
        <table className="min-w-full shadow divide-y">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass} style={{ width: "20%" }}>
                Publisher
              </th>
              <th className={thClass}>Invoice</th>
              <th className={thClass + " flex items-center"}>
                <div>
                  Start Date<br></br>
                  End Date
                </div>
                <FiArrowDown></FiArrowDown>
              </th>
              <th className={thClass + " text-right"}>Invoice Amount</th>

              {_.indexOf(columns, "AFTER_TAX_AMOUNT") !== -1 && (
                <th className={thClass + " text-right"}>After Tax Amount</th>
              )}

              {_.indexOf(columns, "INVOICE_STATUS") !== -1 && (
                <th className={thClass}>Invoice Status</th>
              )}

              {_.indexOf(columns, "APPROVAL_STATUS") !== -1 && (
                <th className={thClass}>Approval Status</th>
              )}

              {_.indexOf(columns, "PAYMENT_STATUS") !== -1 && (
                <th className={thClass}>Payment Status</th>
              )}

              {_.indexOf(columns, "RECEIVED_AMOUNT") !== -1 && (
                <th className={thClass + " text-right"}>Received Amount</th>
              )}

              {_.indexOf(columns, "COLLECTION_DATE") !== -1 && (
                <th className={thClass + " text-right"}>Collection Date</th>
              )}

              {_.indexOf(columns, "BADDEBT_DATE") !== -1 && (
                <th className={thClass + " text-right"}>Bad Debt Date</th>
              )}

              <th className={thClass + " text-right"}>Last updated</th>
              {actions && <th className={thClass}>Action</th>}
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-sm">
            {items.map((item) => {
              return (
                <tr key={item.invoiceId} className="hover:bg-gray-100">
                  <td className={tdClass}>
                    {item.publisher.publisherId} - {item.publisher.name}
                  </td>
                  <td className={tdClass + " font-semibold text-gray-800"}>
                    {item.invoiceNo}
                  </td>
                  <td className={tdClass}>
                    {item.startDateStr} <div>{item.endDateStr}</div>
                  </td>
                  <td className={tdClass + " text-right"}>
                    <span className="text-gray-900 font-semibold">
                      <NumberFormat
                        value={item.amount}
                        prefix="$"
                      ></NumberFormat>
                    </span>

                    <div className="text-xs text-gray-600 leading-none">
                      {item.currency}
                    </div>
                  </td>
                  {_.indexOf(columns, "AFTER_TAX_AMOUNT") !== -1 && (
                    <td className={tdClass + " text-right"}>
                      <span className="text-gray-900 font-semibold">
                        {/* <div className="text-xs text-gray-600 font-normal leading-none">
                          After tax:
                        </div> */}
                        <NumberFormat
                          value={item.afterTaxAmount}
                          prefix="$"
                        ></NumberFormat>
                      </span>

                      <div className="text-xs text-gray-600 leading-none">
                        {item.currency}
                      </div>
                    </td>
                  )}
                  {_.indexOf(columns, "INVOICE_STATUS") !== -1 && (
                    <td className={tdClass + " text-gray-700"}>
                      {item.status}
                    </td>
                  )}
                  {_.indexOf(columns, "APPROVAL_STATUS") !== -1 && (
                    <td className={tdClass}>
                      <div className="text-gray-700">
                        Intowow:
                        <span
                          className={`px-2 ml-1 text-xs font-semibold rounded ${
                            item.i2WApproved === "APPROVED"
                              ? "bg-green-200 text-green-900"
                              : "bg-red-200 text-red-900"
                          }`}
                        >
                          {item.i2WApproved}
                        </span>
                      </div>

                      <div className="text-gray-700">
                        Publisher:
                        <span
                          className={`px-2 ml-1 text-xs font-semibold rounded ${
                            item.approved === "APPROVED"
                              ? "bg-green-200 text-green-900"
                              : "bg-red-200 text-red-900"
                          }`}
                        >
                          {item.approved}
                        </span>
                      </div>
                    </td>
                  )}
                  {_.indexOf(columns, "PAYMENT_STATUS") !== -1 && (
                    <td className={tdClass + " text-gray-700 text-xs"}>
                      {item.payment ? item.payment.status : "-"}
                    </td>
                  )}
                  {_.indexOf(columns, "RECEIVED_AMOUNT") !== -1 && (
                    <td className={tdClass + " text-right"}>
                      <span className="text-gray-900 font-semibold">
                        <NumberFormat
                          value={item.payment.receivedAmt}
                          prefix="$"
                        ></NumberFormat>
                      </span>

                      <div className="text-xs text-gray-600 leading-none">
                        {item.currency}
                      </div>
                    </td>
                  )}
                  {_.indexOf(columns, "COLLECTION_DATE") !== -1 && (
                    <td className={tdClass + " text-right text-gray-700"}>
                      {item.payment
                        ? moment(item.payment.collectionDate).format(
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        : "-"}
                    </td>
                  )}
                  {_.indexOf(columns, "BADDEBT_DATE") !== -1 && (
                    <td className={tdClass + " text-right text-gray-700"}>
                      {item.payment
                        ? moment(item.payment.badDebtDate).format(
                            "YYYY/MM/DD HH:mm:ss"
                          )
                        : "-"}
                    </td>
                  )}
                  <td className={tdClass + " text-right text-gray-700"}>
                    {item.updatedAtStr}
                  </td>
                  {actions && (
                    <td className={tdClass}>
                      {actions.map((action) => {
                        if (action === "ISSUE") {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`px-2 py-1 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold
          ${
            isSaving
              ? "bg-gray-100 cursor-not-allowed"
              : "bg-white hover:bg-gray-100"
          }`}
                              onClick={() => {
                                return onHandleIssue(item);
                              }}
                            >
                              Issue
                            </button>
                          );
                        }

                        if (action === "ADVISE") {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`px-2 py-1 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold
    ${
      isSaving ? "bg-gray-100 cursor-not-allowed" : "bg-white hover:bg-gray-100"
    }`}
                              onClick={() => {
                                return onHandleAdvise(item);
                              }}
                            >
                              Advise
                            </button>
                          );
                        }

                        if (action === "APPROVE") {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`px-2 py-1 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold
    ${
      isSaving ? "bg-gray-100 cursor-not-allowed" : "bg-white hover:bg-gray-100"
    }`}
                              onClick={() => {
                                return onHandleApprove(item);
                              }}
                            >
                              Approve
                            </button>
                          );
                        }

                        if (action === "RECEIVE_PAYMENT") {
                          return (
                            <Link
                              key={action}
                              to={`/ops-mgmt/billing/payment/${item.payment.paymentId}/receive`}
                              className={`px-2 py-1 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold
                              ${
                                isSaving
                                  ? "bg-gray-100 cursor-not-allowed"
                                  : "bg-white hover:bg-gray-100"
                              }`}
                            >
                              Receive
                            </Link>
                          );
                        }

                        if (action === "VOID") {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`px-2 py-1 my-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold
  ${
    isSaving ? "bg-gray-100 cursor-not-allowed" : "bg-white hover:bg-gray-100"
  }`}
                              onClick={() => {
                                return onHandleVoid({
                                  // invoiceId: item.invoiceId,
                                  ...item,
                                  // reason: "Test void",
                                });
                              }}
                            >
                              Void
                            </button>
                          );
                        }

                        if (action === "BAD_DEBT") {
                          return (
                            <button
                              key={action}
                              type="button"
                              disabled={isSaving}
                              className={`px-2 py-1 my-1 ml-1 bg-red-400 text-xs rounded shadow hover:bg-red-600 text-white font-semibold
  ${
    isSaving ? "bg-gray-100 cursor-not-allowed" : "bg-white hover:bg-gray-100"
  }`}
                              onClick={() => {
                                return onHandleBadDebt({
                                  // invoiceId: item.invoiceId,
                                  ...item,
                                  // reason: "Test void",
                                });
                              }}
                            >
                              Bad Debt
                            </button>
                          );
                        }
                      })}
                    </td>
                  )}{" "}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default InvoicesTable;
