import React from "react";

class UnitMode extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { unit, showName } = this.props;

    switch (unit.mode) {
      case 1: {
        return (
          <>
            <span role="img" aria-label={unit.mode} title="Boosting">
              🚀
            </span>
            {showName && "Boosting"}
          </>
        );
      }
      case 2: {
        return (
          <>
            <span role="img" aria-label={unit.mode} title="Partially Boosting">
              🐌
            </span>
            {showName && "Partially Boosting"}
          </>
        );
      }
      case 3: {
        return (
          <>
            <span role="img" aria-label={unit.mode} title="Suspended">
              💤
            </span>
            {showName && "Suspended"}
          </>
        );
      }
      default: {
        return null;
      }
    }
  }
}

export default UnitMode;
