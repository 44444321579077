import React from "react";
import _ from "lodash";
import { updateAutoPilotUserHints } from "../../helpers/Api";
import AutoPilotConfigControl from "./auto-pilot/AutoPilotConfigControl";
import ModalWrapper from "../common/ModalWrapper";
import { notify } from "react-notify-toast";
import {
  getTargetingsByHintType,
  getHintTypeFromUserHint,
} from "./constants/AutoPilotConfigHints";

class UnitHintAPConfigViewer extends React.Component {
  constructor(props) {
    super(props);

    const {
      hintConfig,
      defaultHintConfig,
      updatedAt,
      unitId,
      // currentHintId,
      // userHints,
    } = props;

    this.state = {
      // isLoading: false,
      // errMsg: null,
      unitId,

      // config,
      // defaultConfig,
      hintConfig,
      defaultHintConfig,
      updatedAt,

      isReviewModalOpened: false,
      finalConfig: null,

      isSaving: false,
      saveErrMsg: null,
    };

    this.handleSaveHintConfig = this.handleSaveHintConfig.bind(this);
    this.handleConfirmSaveConfig = this.handleConfirmSaveConfig.bind(this);
    this.handleOpenReviewModal = this.handleOpenReviewModal.bind(this);
    this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this);
  }

  handleOpenReviewModal() {
    this.setState({
      isReviewModalOpened: true,
    });
  }

  handleCloseReviewModal() {
    this.setState({
      isReviewModalOpened: false,
      finalConfig: null,
      saveErrMsg: null,
    });
  }

  handleSaveHintConfig({ config }) {
    // Show review modal first!
    // save when confirmed
    const { unitId, networkId } = this.state;
    console.log("Save config", config, networkId, unitId);

    let finalConfig = _.cloneDeep(config);
    // Important! special case! Added extra outer layer of array
    if (_.has(finalConfig, "prophet.benchmark_abuse_sizes")) {
      const bas = finalConfig.prophet.benchmark_abuse_sizes;
      finalConfig.prophet.benchmark_abuse_sizes = [bas];
    }

    this.setState({ finalConfig });

    this.handleOpenReviewModal();
  }

  async handleConfirmSaveConfig() {
    if (this.state.isSaving) {
      return;
    }

    // HINT CONFIG!
    const { unitId, finalConfig } = this.state;

    this.setState({ isSaving: true, saveErrMsg: null });

    const { userHints, currentHintId } = this.props;
    // 1. get targeting
    // 2. check if this hint is already inside userHints
    //    if yes -> replace it
    //    if no -> push into it
    let finalUserHint = {
      targetings: getTargetingsByHintType(currentHintId),
      autopilot_config: finalConfig,
    };

    let isHintIncluded = false;
    let finalUserHints = [];
    _.forEach(userHints, (userHint) => {
      const hintId = getHintTypeFromUserHint(userHint);
      if (hintId === currentHintId) {
        // replace this hint
        finalUserHints.push(finalUserHint);
        isHintIncluded = true;
        return false; // break
      }
      finalUserHints.push(userHint);
    });

    if (!isHintIncluded) {
      finalUserHints.push(finalUserHint);
    }

    try {
      const resp = await updateAutoPilotUserHints({
        unitId,
        userHints: finalUserHints,
      });

      console.log("updated!!!");
      console.log(resp);
      notify.show(
        "Hint Config saved! Automatically reloading page...",
        "success"
      );

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log("uopdae failedddd!!!");
      console.log(err);
      notify.show("Failed to save", "error");

      console.log("Error saving user hints", err);
      this.setState({
        saveErrMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isSaving: false });
  }

  render() {
    const {
      // config,
      // defaultConfig,
      updatedAt,
      unitId,

      hintConfig,
      defaultHintConfig,
    } = this.state;

    if (hintConfig) {
      return (
        <>
          <AutoPilotConfigControl
            isUnitConfig={!!unitId}
            config={hintConfig}
            defaultConfig={defaultHintConfig}
            handleSaveConfig={this.handleSaveHintConfig}
            updatedAt={updatedAt}
            isHintConfig={true}
          ></AutoPilotConfigControl>

          <ModalWrapper
            isOpen={this.state.isReviewModalOpened}
            showCloseFooter={false}
            handleClose={this.handleCloseReviewModal}
            width="60%"
          >
            <div>
              <div className="font-bold text-xl text-gray-700 mb-2">
                Preview Hint Config
              </div>

              <div
                className="overflow-y-auto border"
                style={{ maxHeight: "320px" }}
              >
                <pre>{JSON.stringify(this.state.finalConfig, null, 2)}</pre>
              </div>

              <div className="flex flex-row-reverse mt-4 items-center">
                <div>
                  <button
                    type="button"
                    className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                    onClick={this.handleConfirmSaveConfig}
                  >
                    {this.state.isSaving ? "Saving..." : "Confirm save"}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={this.state.isSaving}
                    className={`px-4 py-2 text-blue-700 ${
                      this.state.isSaving ? "cursor-not-allowed" : ""
                    }`}
                    onClick={this.handleCloseReviewModal}
                  >
                    Cancel
                  </button>
                </div>
                <div className="text-red-600">{this.state.saveErrMsg}</div>
              </div>
            </div>
          </ModalWrapper>
        </>
      );
    }

    return "";
  }
}

export default UnitHintAPConfigViewer;
