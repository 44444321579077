import React from "react";
import _ from "lodash";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function getOptions({ yAxisTitle, seriesData }) {
  return {
    title: {
      text: null
    },
    chart: {
      type: "column",
      height: 250,
      width: 250,
      backgroundColor: "rgba(0,0,0,0)"
    },
    xAxis: {
      title: {
        text: "Layer"
      }
    },
    yAxis: [
      {
        title: {
          text: yAxisTitle
        }
      }
    ],
    plotOptions: {
      line: {
        animation: false
      },
      column: {
        animation: false,
        // stacking: "normal",
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    series: seriesData
  };
}
class DemandLayerHighchart extends React.PureComponent {
  render() {
    const { layerReports } = this.props;

    const demandTypes = ["ads", "adx", "eb", "sb"];

    const revData = _.map(demandTypes, type => {
      const revLayers = _.reduce(
        layerReports,
        (result, r) => {
          result.push([r.layer, r[`${type}Revenue`]]);
          return result;
        },
        []
      );

      return {
        name: `${type} revenue`,
        type: "column",
        data: revLayers
      };
    });

    const impData = _.map(demandTypes, type => {
      const revLayers = _.reduce(
        layerReports,
        (result, r) => {
          result.push([r.layer, r[`${type}Impression`]]);
          return result;
        },
        []
      );

      return {
        name: `${type} impression`,
        type: "column",
        data: revLayers
      };
    });

    const revOptions = getOptions({
      yAxisTitle: "Revenue",
      seriesData: revData
    });
    const impOptions = getOptions({
      yAxisTitle: "Impression",
      seriesData: impData
    });

    return (
      <>
        <div className="flex p-4">
          <div className="flex-1">
            <HighchartsReact
              highcharts={Highcharts}
              options={revOptions}
              allowChartUpdate={false}
            />
          </div>
          <div className="flex-1">
            <HighchartsReact
              highcharts={Highcharts}
              options={impOptions}
              allowChartUpdate={false}
            />
          </div>
        </div>
      </>
    );
  }
}
export default DemandLayerHighchart;
