import React from "react";
import _ from "lodash";
import ExperimentSection from "./ExperimentSection";
import ScrollToTop from "../common/ScrollToTop";

const NUM_PER_PAGE = 8;

class SegmentExperiments extends React.Component {
  constructor(props) {
    super(props);

    const { yieldLifts } = props;

    this.state = {
      yieldLifts,
      paginatedData: this.repaginateData(yieldLifts),
      isLoadingMoreData: false
    };

    this.scrollToExp = this.scrollToExp.bind(this);
    this.handleLoadMore = this.handleLoadMore.bind(this);
  }

  scrollToExp(expId) {
    if (!expId) return;
    const element = document.getElementById(`#expbox-${expId}`);

    if (element) {
      window.scrollTo({
        behavior: "auto",
        top: element.offsetTop - 64
      });
    } else {
      const loadmoreEle = document.getElementById("loadmoreexps");

      window.scrollTo({
        behavior: "auto",
        top: loadmoreEle.offsetTop - 64
      });
    }
  }

  repaginateData(data) {
    const nth = NUM_PER_PAGE;
    const paginatedData = _.slice(data, 0, nth);
    return paginatedData;
  }

  handleLoadMore() {
    if (this.state.isLoadingMoreData) {
      return false;
    }

    this.setState({ isLoadingMoreData: true });

    setTimeout(() => {
      const nth = this.state.paginatedData.length + NUM_PER_PAGE;
      const paginatedData = _.slice(this.state.yieldLifts, 0, nth);

      this.setState({
        paginatedData,
        isLoadingMoreData: false
      });
    });
  }

  render() {
    const {
      segmentId,
      dateRange,
      expInfo,
      expLink,
      segmentInfo,
      groupInfo
    } = this.props;

    const { paginatedData, yieldLifts, isLoadingMoreData } = this.state;

    return (
      <div className="-mt-8">
        {segmentId &&
          paginatedData &&
          paginatedData.length > 0 &&
          paginatedData.map(exp => {
            return (
              <ExperimentSection
                key={exp.expId}
                data={exp}
                expInfo={expInfo}
                segmentInfo={segmentInfo}
                groupInfo={groupInfo}
                expLink={expLink}
                dateRange={dateRange}
                scrollToExp={this.scrollToExp}
              ></ExperimentSection>
            );
          })}

        {paginatedData.length === yieldLifts.length ? (
          ""
        ) : (
          <button
            id="loadmoreexps"
            type="button"
            disabled={isLoadingMoreData}
            className="w-full p-4 font-bold bg-indigo-500 text-white hover:bg-indigo-600"
            onClick={this.handleLoadMore}
          >
            {isLoadingMoreData ? "Loading..." : "Load more"}
          </button>
        )}

        <ScrollToTop></ScrollToTop>
      </div>
    );
  }
}

export default SegmentExperiments;
