import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import HighchartChart from "../../common/HighchartChart";
// import { isWeekend } from "../../../helpers/DateTransform";

class TrendsRevenueSummary extends React.Component {
  constructor(props) {
    super(props);

    /**
     * Chart1: Raw Eligible Revenue
     * Chart2: Net Increased Revenue
     * Chart3: Managed score
     * Chart4: Max Traffic Ratio Trend
     */
    this.state = {
      optionsChart1: createChartOptions1(props.revenue),
      optionsChart2: createChartOptions2(props.revenue),
      optionsChart3: createChartOptions3(props.revenue),
      // chart 3 and 4 combined
      // optionsChart3: createChartOptions3v2(
      //   props.revenue,
      //   props.experimentParams
      // ),
      optionsChart4: createChartOptions4(
        props.experimentParams,
        props.networkTimezone
      )
    };
  }

  render() {
    const {
      optionsChart1,
      optionsChart2,
      optionsChart3,
      optionsChart4
    } = this.state;

    return (
      <>
        <div className="flex py-4">
          <div className="w-1/2">
            <div className="text-gray-800 font-semibold text-center mb-3 pl-8">
              Raw Eligible Revenue Ratio
            </div>
            <HighchartChart options={optionsChart1}></HighchartChart>
          </div>
          <div className="w-1/2">
            <div className="text-gray-800 font-semibold text-center mb-3 pl-8">
              Net Increased Revenue Lift
            </div>
            <HighchartChart options={optionsChart2}></HighchartChart>
          </div>
        </div>
        <div className="flex py-4">
          <div className="w-1/2">
            <div className="text-gray-800 font-semibold text-center mb-3 pl-8">
              Managed Score
            </div>
            <HighchartChart options={optionsChart3}></HighchartChart>
          </div>
          <div className="w-1/2">
            <div className="text-gray-800 font-semibold text-center mb-3 pl-8">
              Max Traffic Ratio
            </div>
            <HighchartChart options={optionsChart4}></HighchartChart>
          </div>
        </div>
      </>
    );
  }
}

// function createChartOptions3v2(reports, reports2) {
//   const metrics = [
//     {
//       text: "Managed Score Rev",
//       key: "managedEligibleRevenueRatio",
//       tooltipExtra: {
//         text: "Managed Eligible Rev",
//         key: "managedEligibleRevenue"
//       }
//     },
//     {
//       text: "Managed Score Imp",
//       key: "managedEligibleImpressionRatio",
//       tooltipExtra: {
//         text: "Managed Eligible Imp",
//         key: "managedEligibleImpression"
//       }
//     }
//   ];
//   let minY = 0;
//   let maxY = 100;
//   let series1 = _.map(metrics, m => {
//     return {
//       name: m.text,
//       // type: "spline",
//       data: _.map(reports, r => {
//         const y = r[m.key] * 100;
//         minY = y < minY ? y : minY;
//         maxY = y > maxY ? y : maxY;
//         return {
//           x: new Date(r.date).getTime(),
//           y,
//           tooltipExtra: {
//             name: m.tooltipExtra.text,
//             data: r[m.tooltipExtra.key]
//           }
//         };
//       })
//     };
//   });

//   const reportsBySegments = _.groupBy(reports2, "segmentId");

//   let series2 = _.map(reportsBySegments, (reports, segmentId) => {
//     return {
//       name: segmentId !== "null" ? `Segment ${segmentId}` : "No Segment",
//       step: true,

//       data: _.map(reports, r => {
//         const y = r.maxTrafficRatio;
//         return {
//           x: new Date(r.startDate).getTime(),
//           y
//         };
//       })
//     };
//   });

//   const options = {
//     title: {
//       text: null
//     },
//     chart: {
//       type: "line",
//       zoomType: "x",
//       height: 260,
//       backgroundColor: "rgba(0,0,0,0)"
//     },
//     tooltip: {
//       xDateFormat: "%Y/%m/%d %H:%M:%S %a",
//       // headerFormat:
//       //   '<div style="font-weight: bold; text-align: center;">{point.key}</div><table>',
//       // pointFormat:
//       //   '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{series.name}: </td>' +
//       //   '<td style="padding:0; text-align: right;"><b>{point.y:,.2f}</b>%</td></tr>' +
//       //   '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{point.tooltipExtra.name}: </td>' +
//       //   '<td style="padding:0; text-align: right;"><b>${point.tooltipExtra.data:,.2f}</b></td></tr>',
//       // footerFormat: "</table>",
//       valueDecimals: 2,
//       shared: true,
//       // useHTML: true,
//       crosshairs: true
//     },
//     xAxis: getXAxisConfigDateTime(),
//     yAxis: {
//       title: {
//         text: "%"
//       },
//       min: minY,
//       max: maxY
//     },
//     series: [...series1, ...series2]
//   };

//   return options;
// }

function createChartOptions4(reports, networkTimezone) {
  const reportsBySegments = _.groupBy(reports, "segmentId");

  let minY = 0;
  let maxY = 100;
  const series = _.map(reportsBySegments, (reports, segmentId) => {
    return {
      name: segmentId !== "null" ? `Segment ${segmentId}` : "No Segment",
      step: true,
      data: _.map(reports, r => {
        const y = r.maxTrafficRatio;
        minY = y < minY ? y : minY;
        maxY = y > maxY ? y : maxY;
        return {
          x: new Date(r.startDate).getTime(),
          y
        };
      })
    };
  });
  const timezoneOffset = -moment.tz(networkTimezone).utcOffset();

  const options = {
    title: {
      text: null
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)"
    },
    tooltip: {
      xDateFormat: `Start Time (${networkTimezone})<br/>%Y/%m/%d %H:%M:%S %a`,
      valueDecimals: 2,
      shared: true,
      crosshairs: true
    },
    time: {
      timezoneOffset
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: {
      title: {
        text: "%"
      },
      min: minY,
      max: maxY
    },
    series
  };

  return options;
}

function createChartOptions3(reports) {
  const metrics = [
    {
      text: "Managed Score Rev",
      key: "managedEligibleRevenueRatio",
      tooltipExtra: {
        text: "Managed Eligible Rev",
        key: "managedEligibleRevenue"
      }
    },
    {
      text: "Managed Score Imp",
      key: "managedEligibleImpressionRatio",
      tooltipExtra: {
        text: "Managed Eligible Imp",
        key: "managedEligibleImpression"
      }
    }
  ];
  let minY = 0;
  let maxY = 100;
  const series = _.map(metrics, m => {
    return {
      name: m.text,
      data: _.map(reports, r => {
        const y = r[m.key] * 100;
        minY = y < minY ? y : minY;
        maxY = y > maxY ? y : maxY;
        return {
          x: new Date(r.date).getTime(),
          y,
          tooltipExtra: {
            name: m.tooltipExtra.text,
            data: r[m.tooltipExtra.key]
          }
        };
      })
    };
  });

  const options = {
    title: {
      text: null
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)"
    },
    tooltip: {
      xDateFormat: "%Y/%m/%d %a",
      headerFormat:
        '<div style="font-weight: bold; text-align: center;">{point.key}</div><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{series.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>{point.y:,.2f}</b>%</td></tr>' +
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{point.tooltipExtra.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>${point.tooltipExtra.data:,.2f}</b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 2,
      shared: true,
      useHTML: true,
      crosshairs: true
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: {
      title: {
        text: "Lift %"
      },
      min: minY,
      max: maxY
    },
    series
  };

  return options;
}

function createChartOptions2(reports) {
  const metrics = [
    {
      text: "Net Eligible Rev Lift",
      key: "netEligibleIncreasedRevenueLift",
      // Billable total rev - original rev - cost
      tooltipExtra: {
        text: "Net Eligible Rev",
        key: "netEligibleIncreasedRevenue"
      }
    },
    {
      text: "Net All Holistic Rev Lift",
      key: "netAllHolisticIncreasedRevenueLift",
      /*
        total rev - bm rev - cost
        total rev= demand_type != 'STANDARD' 
        AND demand_type != 'SPONSORSHIP' 
        AND demand_type != 'HOUSE' 
        AND demand_type != 'SMART' 
        AND demand_type != 'PREFERRED_DEAL'
        
        bm rev = 
        WHEN eg.type = 0 
        AND demand_type != 'STANDARD' 
        AND demand_type != 'SPONSORSHIP' 
        AND demand_type != 'HOUSE' 
        AND demand_type != 'SMART' 
        AND demand_type != 'PREFERRED_DEAL'
      */

      tooltipExtra: {
        text: "Net All Holistic Rev",
        key: "netAllHolisticIncreasedRevenue"
      }
    },
    {
      text: "Net Prog Holistic Rev Lift",
      key: "netProgHolisticRevenueLift",
      /*
        total prog rev - bm prog rev - cost
        total prog rev = all adx + all ads + all pp + all eb + all sb
        bm prog rev = bm adx + bm ads + bm pp + bm eb + bm sb
      */
      tooltipExtra: {
        text: "Net Prog Holistic Rev",
        key: "netProgHolisticRevenue"
      }
    }
  ];
  let minY = 0;
  let maxY = 100;
  const series = _.map(metrics, m => {
    return {
      name: m.text,
      data: _.map(reports, r => {
        const y = r[m.key] * 100;
        minY = y < minY ? y : minY;
        maxY = y > maxY ? y : maxY;
        return {
          x: new Date(r.date).getTime(),
          y,
          tooltipExtra: {
            name: m.tooltipExtra.text,
            data: r[m.tooltipExtra.key]
          }
        };
      })
    };
  });

  const options = {
    title: {
      text: null
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)"
    },
    tooltip: {
      xDateFormat: "%Y/%m/%d %a",
      headerFormat:
        '<div style="font-weight: bold; text-align: center;">{point.key}</div><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{series.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>{point.y:,.2f}</b>%</td></tr>' +
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{point.tooltipExtra.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>${point.tooltipExtra.data:,.2f}</b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 2,
      shared: true,
      useHTML: true,
      crosshairs: true
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: {
      title: {
        text: "Lift %"
      },
      min: minY,
      max: maxY
    },
    series
  };

  return options;
}

function createChartOptions1(reports) {
  const metrics = [
    {
      text: "Raw Eligible Rev Ratio",
      key: "rawEligibleRevenueRatio",
      tooltipExtra: {
        text: "Raw Eligible Rev",
        key: "rawEligibleRevenue"
      }
    },
    {
      text: "Billable Raw Eligible Rev Ratio",
      key: "billableRevenueByRawRevenueRatio",
      tooltipExtra: {
        text: "Billable Raw Eligible Rev",
        key: "billableRevenue"
      }
    }
  ];
  let minY = 0;
  let maxY = 100;
  const series = _.map(metrics, m => {
    return {
      name: m.text,
      data: _.map(reports, r => {
        const y = r[m.key] * 100;
        minY = y < minY ? y : minY;
        maxY = y > maxY ? y : maxY;
        return {
          x: new Date(r.date).getTime(),
          y,
          tooltipExtra: {
            name: m.tooltipExtra.text,
            data: r[m.tooltipExtra.key]
          }
        };
      })
    };
  });

  const options = {
    title: {
      text: null
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 260,
      backgroundColor: "rgba(0,0,0,0)"
    },
    tooltip: {
      xDateFormat: "%Y/%m/%d %a",
      headerFormat:
        '<div style="font-size:10px; font-weight: bold; text-align: center;">{point.key}</div><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{series.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>{point.y:,.2f}</b>%</td></tr>' +
        '<tr><td style="color:{series.color};padding:0;font-weight: bold; text-align: right;padding-right: 4px;">{point.tooltipExtra.name}: </td>' +
        '<td style="padding:0; text-align: right;"><b>${point.tooltipExtra.data:,.2f}</b></td></tr>',
      footerFormat: "</table>",
      valueDecimals: 2,
      shared: true,
      useHTML: true,
      crosshairs: true
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: {
      title: {
        text: "%"
      }
      // min: minY,
      // max: maxY
    },
    series
  };

  return options;
}

function getXAxisConfigDateTime() {
  return {
    type: "datetime",
    labels: {
      formatter: function() {
        const m = moment(this.value);
        const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        // if (isWeekend(this.value)) {
        //   return `<span style="color: #dd6b20;">${label}</span>`;
        // }
        return label;
      }
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true
  };
}

export default TrendsRevenueSummary;
