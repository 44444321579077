import React from "react";
import _ from "lodash";
import { getSchemaByKey } from "../../constants/AutoPilotConfig";
import ConfigValueReadonly from "./ConfigValueReadonly";
import { FiArrowRight } from "react-icons/fi";

class ConfigDiffView extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      diffs: [],
    };
  }
  render() {
    const { originalConfig, newConfig, defaultConfig } = this.props;
    const diffs = _calculateDiff(originalConfig, newConfig, defaultConfig);

    return (
      <>
        <div className="mb-2 text-gray-600 font-semibold uppercase text-sm border-b-2 border-gray-400">
          Config Changes ({diffs.length})
        </div>
        <div className="text-sm">
          {diffs.map((diff, i) => {
            const { key, from, to, valueType } = diff;
            const title = _.startCase(key);
            return (
              <div key={i} className="mb-2 pb-2 border-b -mx-4 px-4">
                <div className="font-semibold text-blue-800">{title}</div>

                <div className="flex text-gray-700">
                  <div className="w-1/2 flex">
                    {/* <div className="text-gray-600">from</div> */}

                    <div>
                      {from.isDefault ? (
                        <DefaultValueView
                          valueType={valueType}
                          value={from.value}
                        ></DefaultValueView>
                      ) : (
                        <CustomValueView
                          valueType={valueType}
                          value={from.value}
                        ></CustomValueView>
                      )}
                    </div>
                  </div>
                  <div className="text-gray-600 px-2 self-center">
                    {/* to */}
                    <FiArrowRight></FiArrowRight>
                  </div>

                  <div className="w-1/2 flex">
                    {/* <div className="text-gray-600">to</div> */}

                    <div className="font-semibold">
                      {to.isDefault ? (
                        <DefaultValueView
                          valueType={valueType}
                          value={to.value}
                        ></DefaultValueView>
                      ) : (
                        <CustomValueView
                          valueType={valueType}
                          value={to.value}
                        ></CustomValueView>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

class CustomValueView extends React.PureComponent {
  render() {
    const { value, valueType } = this.props;

    return (
      <ConfigValueReadonly
        valueType={valueType}
        configValue={value}
      ></ConfigValueReadonly>
    );
  }
}

class DefaultValueView extends React.PureComponent {
  render() {
    const { value, valueType } = this.props;

    return (
      <div>
        Use Default:{" "}
        <ConfigValueReadonly
          valueType={valueType}
          configValue={value}
        ></ConfigValueReadonly>
      </div>
    );
  }
}

function _calculateDiff(fromConfig, toConfig, defaultConfig) {
  let diffs = [];

  const oldKeys = _.keys(fromConfig);
  const newKeys = _.keys(toConfig);
  const keys = _.uniq([...oldKeys, ...newKeys]);
  // console.log(keys);

  _.forEach(keys, (key) => {
    // no from + to
    // from + no to
    // from + to
    const hasFrom = _.has(fromConfig, key);
    const hasTo = _.has(toConfig, key);
    const fromValue = _.get(fromConfig, key);
    const toValue = _.get(toConfig, key);
    const defaultValue = _.get(defaultConfig, key);

    // console.log("from ", hasFrom, fromValue, _isObject(fromValue));
    // console.log("to ", hasTo, toValue, _isObject(toValue));

    if (_isObject(fromValue) || _isObject(toValue)) {
      Array.prototype.push.apply(
        diffs,
        _calculateDiff(fromValue, toValue, defaultValue)
      );
      return true;
    }

    if (hasFrom && hasTo && !_.isEqual(fromValue, toValue)) {
      const diff = {
        key,
        from: {
          value: fromValue,
        },
        to: {
          value: toValue,
        },
      };

      diffs.push(diff);
    }

    if (hasFrom && !hasTo) {
      const diff = {
        key,
        from: {
          value: fromValue,
        },
        to: {
          isDefault: true,
          value: defaultValue,
        },
      };

      diffs.push(diff);
    } else if (!hasFrom && hasTo) {
      const diff = {
        key,
        from: {
          isDefault: true,
          value: defaultValue,
        },
        to: {
          value: toValue,
        },
      };

      diffs.push(diff);
    }
  });

  // console.log("!!!!!!!!!!!!!!!", diffs);

  // add schema
  diffs = _.map(diffs, (d) => {
    d.valueType = _.get(getSchemaByKey(d.key), "valueType");
    return d;
  });
  return diffs;
}

function _isObject(value) {
  return _.isPlainObject(value);
  // return typeof value === "object" && value.length === "undefined";
}

export default ConfigDiffView;
