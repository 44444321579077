import _ from "lodash";

const configSchemaMap = {
  bm: {
    schema: getBmConfigSchema(),
  },
  dp: {
    schema: getDpConfigSchema(),
  },
  opt: {
    schema: getOptConfigSchema(),
  },
};

function transformValue(value) {
  return typeof value === "boolean" ? value.toString() : value;
}

function transformSchemaConfig(schema, value, key) {
  const s = schema;

  const defaultValue = s.defaultValue;
  const hasValue = !_.isUndefined(value);
  const isDiff = value !== defaultValue;
  let uiValue = hasValue ? transformValue(value) : transformValue(defaultValue);

  const description = s.description;
  let alwaysShow = s.alwaysShow ? true : isDiff;

  if (s.transformer) {
    uiValue = s.transformer(uiValue);
  }

  return {
    key,
    value,

    defaultValue,
    hasValue,
    isDiff,
    uiValue,

    description,
    alwaysShow,
  };
}

function something(schema, value, key) {
  const s = schema;
  if (s.isArray && value) {
    const values = _.map(value, (v) => {
      return _.map(v, (c, k) => {
        return something(_.get(s, ["properties", k], {}), c, k);
      });
    });
    const description = s.description;
    return {
      key,
      values,

      isArray: true,
      description,
      alwaysShow: true,
    };
  } else {
    return transformSchemaConfig(s, value, key);
  }
}

// Opt has lots of configs in arrays, might as well customize this
export const mergeOptSchemaWithConfig = ({ unitConfig }) => {
  const schema = configSchemaMap["opt"].schema;

  return _.reduce(
    schema,
    (result, s, key) => {
      const value = _.get(unitConfig, key);

      result[key] = something(s, value, key);

      return result;
    },
    {}
  );
};

export const mergeDpSchemaWithConfig = ({ unitConfig }) => {
  const schema = configSchemaMap["dp"].schema;

  return _.reduce(
    schema,
    (result, s, key) => {
      const value = _.get(unitConfig, key);
      const defaultValue = s.defaultValue;
      const hasValue = _.has(unitConfig, key);
      const isDiff = value !== defaultValue;
      let uiValue = hasValue
        ? transformValue(value)
        : transformValue(defaultValue);

      const description = s.description;
      let alwaysShow = s.alwaysShow ? true : isDiff;

      if (s.transformer) {
        uiValue = s.transformer(uiValue);
      }

      let schemaConfig = {
        key,
        value,

        defaultValue,
        hasValue,
        isDiff,
        uiValue,

        description,
        alwaysShow,
      };

      result[key] = schemaConfig;

      return result;
    },
    {}
  );
};

export const mergeBmSchemaWithConfig = ({ unitConfig }) => {
  const schema = configSchemaMap["bm"].schema;

  return _.reduce(
    schema,
    (result, s, key) => {
      const value = _.get(unitConfig, key);
      const defaultValue = s.defaultValue;
      const hasValue = _.has(unitConfig, key);
      const isDiff = value !== defaultValue;
      let uiValue = hasValue
        ? transformValue(value)
        : transformValue(defaultValue);

      const description = s.description;
      let alwaysShow = s.alwaysShow ? true : isDiff;

      if (s.transformer) {
        uiValue = s.transformer(uiValue);
      }

      let schemaConfig = {
        key,
        value,

        defaultValue,
        hasValue,
        isDiff,
        uiValue,

        description,
        alwaysShow,
      };

      result[key] = schemaConfig;

      return result;
    },
    {}
  );
};

function getBmConfigSchema() {
  return {
    bmRandomSizes: {
      // alwaysShow: true,
      description:
        "Randomly selects a group of request sizes for benchmark. If missing, see bmSizes.",
      transformer: (value) => {
        // ex.
        // [
        // layers...
        //   [
        // layer 1: randomly select one set of sizes
        //     [[160, 600], sizes...],
        //     [[160, 600]],
        //     [[160, 600]],
        //     [[152, 652]]
        //   ],
        // ]

        if (_.isArray(value)) {
          // since this is for benchmark, should only have one layer!
          const layers = _.map(value, (layerSizes) => {
            if (_.isArray(layerSizes)) {
              const sizes = _.map(layerSizes, (sizes) => {
                if (_.isArray(sizes)) {
                  const sizesSet = _.map(sizes, (size) => {
                    return _.isArray(size) ? size.join("x") : size;
                  });
                  return `[${sizesSet.join(", ")}]`;
                }
                return sizes;
              });

              return `(${sizes.length}) ${sizes.join(", ")}`;
            }
            return layerSizes;
          });

          return layers;
        }

        return value;
      },
    },
    bmSizes: {
      defaultValue: "Random single size",
      alwaysShow: true,
      description:
        "Controls how to request ad for benchmark group and requested ad sizes if given. If missing, we will use random single size for benchmark group!!",
      transformer: (value) => {
        // ex. [[300,250], [336, 280]]
        if (_.isArray(value)) {
          const sizes = _.map(value, (size) => {
            return _.isArray(size) ? size.join("x") : size;
          });
          return `(${sizes.length}) ${sizes.join(", ")}`;
        }

        return value;
      },
    },
    bmUpdateCorrelator: {
      defaultValue: false,
      alwaysShow: true,
      description:
        "Whether to update correlator for benchmark group. Default: false.",
    },
    bmWeight: {
      defaultValue: 5,
      alwaysShow: true,
      description: "Benchmark traffic ratio",
    },
  };
}

function getDpConfigSchema() {
  return {
    maxTrafficRatio: {
      defaultValue: 100,
      alwaysShow: true,
      description: "Maximum Traffic Ratio",
    },
    dispatcherValueCpmUSD: {
      defaultValue: 350,
      alwaysShow: true,
      description: "Price of dispatcher line item in USD. Default: $350",
      transformer: (value) => {
        return `$${value}`;
      },
    },
    dispatcherSizes: {
      defaultValue: "Onboarded sizes",
      alwaysShow: true,
      description:
        "Sizes for dispatcher line item and its associated creatives",
      transformer: (value) => {
        // ex. [[300,250], [336, 280]]
        if (_.isArray(value)) {
          const sizes = _.map(value, (size) => {
            return _.isArray(size) ? size.join("x") : size;
          });
          return `(${sizes.length}) ${sizes.join(", ")}`;
        }

        return value;
      },
    },
    dispatcherCreativeDuplication: {
      defaultValue: 3,
      alwaysShow: true,
      description: "Number of creative duplication for dispatcher. Default: 3",
    },
    urlTrackingRatio: {
      defaultValue: 0,
      alwaysShow: true,
      description: "Url tracking if value > 0",
    },
  };
}

function getOptConfigSchema() {
  return {
    autoUpdate: {
      defaultValue: true,
      alwaysShow: true,
      description:
        "Whether this target is managed by robot (system default or auto pilot). Default: true.",
    },
    autoPilot: {
      defaultValue: false,
      alwaysShow: true,
      description:
        "Whether this target is managed by auto pilot. Default: false.",
    },
    disableDefaultCandidates: {
      defaultValue: false,
      alwaysShow: false,
      description:
        "Disable candidate generating process in prophet. Default: false.",
    },
    optSizes: {
      defaultValue: "Random single size",
      alwaysShow: false,
      description:
        "sizes in experiment group for default Opt and default candidates.",
      transformer: (value) => {
        // ex. [[300,250], [336, 280]]
        if (_.isArray(value)) {
          const sizes = _.map(value, (size) => {
            return _.isArray(size) ? size.join("x") : size;
          });
          return `(${sizes.length}) ${sizes.join(", ")}`;
        }

        return value;
      },
    },
    customGroups: {
      // defaultValue: null,
      alwaysShow: false,
      description: "Custom groups",
      isArray: true, // ?
      properties: {
        name: {
          // isRequired: true, // not sure what to do with this now
          // defaultValue: null,
          alwaysShow: false,
          description: "Name of custom group",
        },
        promotable: {
          defaultValue: true,
          alwaysShow: false,
          description:
            "Whether this custom group can participate optimization group election process. Default: true.",
        },
        weight: {
          defaultValue: 2,
          alwaysShow: true,
          description: "Override traffic weighting assigned by prophet.",
        },
        sizes: {
          defaultValue: "Random single size",
          alwaysShow: false,
          description: "Sizes of custom groups.",
        },
        layers: {
          alwaysShow: false,
          description: "Layers",
          isArray: true,
          properties: {
            id: {
              // defaultValue: null,
              alwaysShow: false,
              description: "ID of layer",
            },
            tags: {
              alwaysShow: false,
              description: "Tags",
              isArray: true,
              properties: {
                tagType: {
                  alwaysShow: false,
                  description: "ADX, ADSENSE or SMART",
                },
                price: {
                  alwaysShow: false,
                  description: "Required only when tagType=SMART",
                },
              },
            },
          },
        },
        layerSizes: {
          // defaultValue: null,
          alwaysShow: false, // since this has no default value, if config has value than it will show in ui
          description: "Layer sizes of custom group.",
          transformer: (value) => {
            // ex. [[[300,250], [336, 280]]]
            if (_.isArray(value)) {
              const layers = _.map(value, (layerSizes) => {
                if (_.isArray(layerSizes)) {
                  const sizes = _.map(layerSizes, (size) => {
                    return _.isArray(size) ? size.join("x") : size;
                  });

                  return `(${sizes.length}) ${sizes.join(", ")}`;
                }
              });

              return layers.join("\n");
            }

            return value;
          },
        },
        layerNativeSwitches: {
          // defaultValue: null,
          alwaysShow: false,
          description:
            "0 for display only; 1 for display and native mixed; 2 for native only.",
          transformer: (value) => {
            switch (value) {
              case 0:
                return "Display only";
              case 1:
                return "Display and native mixed";
              case 2:
                return "Native only";
              default:
                return `Invalid value: ${value}`;
            }
          },
        },
        layerOptUPRs: {
          // defaultValue: null,
          alwaysShow: false,
          description: "0 for using publisher upr; 1 for using intowow upr.",
          transformer: (value) => {
            if (_.isArray(value)) {
              const layers = _.map(value, (v) => {
                switch (v) {
                  case 0:
                    return "Using publisher UPR";
                  case 1:
                    return "Using Intowow UPR";
                  default:
                    return `Invalid value: ${v}`;
                }
              });

              return layers.join("\n");
            }
          },
        },
        requestMethod: {
          defaultValue: "passback_api",
          alwaysShow: true,
          description: "Request method",
        },
        urlTruncDispatcher: {
          defaultValue: false,
          alwaysShow: false,
          description:
            "Truncate page url to include only domain part for dispatcher layer. It only works when requestMethod is passback_api! Default: false.",
        },
        urlTruncSmartTag: {
          defaultValue: false,
          alwaysShow: false,
          description:
            "Truncate page url to include only domain part for smart tag layers. Default: false.",
        },
        updateCorrelator: {
          defaultValue: false,
          alwaysShow: true,
          description:
            "Update correlator when we call refresh api. Default: false. Only when requestMethod='client_refresh'.",
        },
        dynamicFloor: {
          defaultValue: false,
          alwaysShow: false,
          description:
            "Enable dynamic floor price based on header bidding price or not. Default: false.",
        },
        dynamicFloorDiscount: {
          defaultValue: 0,
          alwaysShow: false,
          description:
            "Discount apply to hb_pb. If discount = 0.2, we will set price of smart tag to max(current_smart_price, hb_pb * (1-0.2)). Default: 0. Only if dynamicValue=true",
        },

        // (sr = Smart Request "Feature" - delays dispatcher request)
        srPollingInterval: {
          defaultValue: 200,
          alwaysShow: false,
          description: "Viewability check interval in ms. Default: 200 ms.",
        },
        srVisibleRatio: {
          defaultValue: [-1000, 1000],
          alwaysShow: false,
          description:
            "(viewport_y_top - ad_y_bottom)/ad_height if ad is above viewport; (ad_y_top - viewport_y_bottom)/ad_height if ad is below viewport. 0 if ad is intersect with viewport.",
        },
        srMaxPollingTime: {
          defaultValue: 2000,
          alwaysShow: false,
          description:
            "Maximum polling time in ms for visibility detector. Default: 2000 (ms).",
        },
        adPods: {
          alwaysShow: false,
          description: "Ad Pods",
          isArray: true,
          properties: {
            sizes: {
              // defaultValue: null,
              alwaysShow: false,
              description: "Sizes of ad pods",
            },
            layer_sizes: {
              // defaultValue: null,
              alwaysShow: false,
              description: "Layer sizes of ad pods",
            },
            request_method: {
              // defaultValue: null,
              alwaysShow: false,
            },
          },
        },
        adPodsType: {
          // defaultValue: null,
          alwaysShow: false,
          description: "H for horizontal; V for vertical; L for layered.",
        },
        adPodsGutter: {
          defaultValue: 5,
          alwaysShow: false,
          description: "The space (in pixels) in between ads",
          transformer: (value) => {
            return `${value} px`;
          },
        },
      },
    },
  };
}
