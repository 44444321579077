import React from "react";
import moment from "moment-timezone";
import { FiExternalLink } from "react-icons/fi";
import { getQuicksightDashboardUrl } from "../../helpers/Api";
import ReactTooltip from "react-tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";

class QsDashboardButton extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingQsDashboardUrl: false,
      filterString: this.addQsFilter(),
      copied: false
    };

    this.handleGoToQuicksightDashboard = this.handleGoToQuicksightDashboard.bind(
      this
    );
  }

  async handleGoToQuicksightDashboard() {
    this.setState({ isLoadingQsDashboardUrl: true });
    try {
      const qsUrl = await getQuicksightDashboardUrl();
      const { filterString } = this.state;
      const url = `${qsUrl}${filterString}`;
      console.log(url);
      window.open(url);
    } catch (err) {
      window.alert(
        "Failed to get quicksight dashboard",
        typeof err === "object" ? err.toString() : err
      );
    }
    this.setState({ isLoadingQsDashboardUrl: false });
  }

  addQsFilter() {
    const { data, type } = this.props;

    const startDate = moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD HH:mm");
    const endDate = moment().format("YYYY-MM-DD HH:mm");
    let qsFilters = [
      `p.startDate=${encodeURIComponent(
        startDate
      )}&p.endDate=${encodeURIComponent(endDate)}`
    ];

    switch (type) {
      case "UNIT": {
        const extGamUnitId1 = data.extUnitId;
        const extGamUnitId2 = data.ext_gam_unit_id;
        const k = extGamUnitId1 ? extGamUnitId1 : extGamUnitId2;
        const encodedString = encodeURIComponent(k);
        // Also need network filter to work...
        const encodedNetworkName = encodeURIComponent(this.props.networkName);
        qsFilters.push(
          `p.extUnitId=${encodedString}&p.network=${encodedNetworkName}`
        );
        break;
      }

      case "NETWORK": {
        const encodedString = encodeURIComponent(data.name);
        qsFilters.push(`p.network=${encodedString}`);
        break;
      }

      default: {
        // do nothing
      }
    }

    return "#" + qsFilters.join("&");
  }

  render() {
    const { isLoadingQsDashboardUrl, filterString } = this.state;

    return (
      <div className="flex">
        <button
          type="button"
          className="text-xs border border-blue-300 rounded px-1 hover:shadow flex items-center text-blue-500"
          onClick={this.handleGoToQuicksightDashboard}
        >
          Quicksight
          {isLoadingQsDashboardUrl ? (
            <span> (Loading...)</span>
          ) : (
            <span className="ml-1">
              <FiExternalLink></FiExternalLink>
            </span>
          )}
        </button>

        <CopyToClipboard
          text={filterString}
          onCopy={() => this.setState({ copied: true })}
        >
          <button
            type="button"
            className="ml-2 text-xs border border-gray-400 rounded px-1 hover:shadow flex items-center text-gray-600"
            data-tip
            data-for={filterString}
            onMouseLeave={() => this.setState({ copied: false })}
          >
            Copy QS filter
          </button>
        </CopyToClipboard>

        <ReactTooltip id={filterString} type="dark" effect="solid" place="top">
          {this.state.copied ? (
            "Copied!"
          ) : (
            <>
              <p>1. Click to copy the filter parameter string for Quicksight</p>
              <p>2. Paste at the end of the Quicksight url to apply filter</p>
            </>
          )}
        </ReactTooltip>
      </div>
    );
  }
}

export default QsDashboardButton;
