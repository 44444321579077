import React from "react";
import _ from "lodash";
import Select from "react-select";

import { getNetworks } from "../../../helpers/Api";

class NetworksSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const items = await getNetworks();
    console.log(items);

    let options = _.map(items, (r) => {
      return {
        value: r.networkId,
        // label: `${r.networkId} - ${r.name} (${r.status})`,
        label: `${r.networkId} - ${r.name}`,
      };
    });

    options = _.orderBy(options, ["value"], ["desc"]);

    this.setState({
      items,
      options,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    const item = _.find(this.state.items, { networkId: option.value });
    this.props.handleOnChange(item);
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
        />
      </>
    );
  }
}

export default NetworksSelector;
