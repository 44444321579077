import React, { Suspense } from "react";
import _ from "lodash";
import { IoMdRefresh } from "react-icons/io";
import ReactTooltip from "react-tooltip";

import { getUnitInsights } from "../../helpers/Api";
import LoadingUI from "../common/LoadingUI";
import UnitInsightHeader from "./UnitInsightHeader";
import TimeOfDayProgressBar from "../common/TimeOfDayProgressBar";
import UnitInsightTabs from "./UnitInsightTabs";
import BenchmarkValidationView from "./benchmark-validation/BenchmarkValidationView";
import InventoryView from "./inventory/InventoryView";
import OverviewView from "./overview/OverviewView";
import UprValidationView from "./upr-validation/UprValidationView";
import ConfigView from "./exp-config/ConfigView";
import DateRangeSelector from "../common/DateRangeSelector";

const DEFAULT_DATE_RANGE = 7;

class UnitInsightViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,

      // selectedTab: "INVENTORY",
      // selectedTab: "BENCHMARK_VALIDATION",
      selectedTab: "OVERVIEW",
      // selectedTab: "EXPERIMENT_CONFIG",
      // selectedTab: "UPR_VALIDATION",
      // selectedTab: "AUTO_PILOT_CONFIG",

      unitId: _.get(props, "match.params.unitId"),
      selectedDateRange: DEFAULT_DATE_RANGE,
    };

    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleDateRangeChange = this.handleDateRangeChange.bind(this);
    this.handleForceRefresh = this.handleForceRefresh.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const unitId = _.get(this.props, "match.params.unitId");
    document.title = `${unitId} Unit Insight | YB Observer`;

    try {
      const data = await getUnitInsights(unitId, this.state.dateRange);
      this.setState({ data, unitId });
    } catch (err) {
      console.log("Error querying snapshot detail", err);
    }

    this.setState({ isLoading: false });
  }

  async handleForceRefresh() {
    this.setState({ isLoading: true, data: null });

    try {
      const isForceRefresh = true;
      const data = await getUnitInsights(
        this.state.unitId,
        this.state.dateRange,
        isForceRefresh
      );
      this.setState({ data });
    } catch (err) {
      console.log("Error querying snapshot detail", err);
    }

    this.setState({ isLoading: false });
  }

  handleSelectTab(tabKey) {
    if (tabKey === "AUTO_PILOT_CONFIG") {
      const unitId = this.state.unitId;

      window.open(
        `${window.location.origin}/#/control-center/unit/${unitId}/auto-pilot/config`
      );

      return;
    }

    this.setState({
      selectedTab: tabKey,
    });
  }

  async handleDateRangeChange(dateRange) {
    if (dateRange === this.state.selectedDateRange) {
      return;
    }

    if (dateRange === 30) {
      const msg =
        "Be warned! Calculating 30 days worth of data could take minutes or hours of querying and could potentially damage the system, are you sure you would like to proceed?";
      const isConfirmed = window.confirm(msg);

      if (!isConfirmed) {
        return;
      }
    }

    this.setState({ isLoading: true, data: null });
    try {
      const data = await getUnitInsights(this.state.unitId, dateRange);
      this.setState({ selectedDateRange: dateRange });

      if (data) {
        this.setState({ data });
      } else {
        this.setState({ errorMsg: "No Data" });
      }
    } catch (err) {
      this.setState({
        errorMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const { unitId, data, selectedTab, selectedDateRange } = this.state;

    return (
      <>
        {this.state.isLoading && (
          <div className="text-white">
            Querying unit reports to create insight...
          </div>
        )}

        <Suspense fallback={<LoadingUI></LoadingUI>}>
          {data && (
            <>
              <UnitInsightHeaderAndTabs
                data={data}
                selectedTab={selectedTab}
                handleSelectTab={this.handleSelectTab}
              ></UnitInsightHeaderAndTabs>

              <div className="flex items-center justify-between">
                {selectedTab !== "EXPERIMENT_CONFIG" &&
                  selectedTab !== "AUTO_PILOT_CONFIG" &&
                  selectedTab !== "UPR_VALIDATION" && (
                    <div className="m-4 text-sm text-white">
                      <DateRangeSelector
                        dateRanges={[7, 14, 21, 30]}
                        selectedDateRange={selectedDateRange}
                        onDateRangeChange={this.handleDateRangeChange}
                      ></DateRangeSelector>
                    </div>
                  )}

                <div>
                  <button
                    type="button"
                    className="px-2 py-1 mr-4 bg-gray-400 rounded font-medium flex items-center hover:bg-white"
                    data-tip
                    data-for="unit-insight-force-update-tooltip"
                    onClick={this.handleForceRefresh}
                  >
                    <IoMdRefresh></IoMdRefresh>
                    <span className="text-sm pl-1">Force Update</span>
                  </button>

                  <ReactTooltip
                    id="unit-insight-force-update-tooltip"
                    type="dark"
                    effect="solid"
                  >
                    Click to update data immediately
                  </ReactTooltip>
                </div>
              </div>
              <TabView
                selectedTab={selectedTab}
                data={data}
                unitId={unitId}
                handleSelectTab={this.handleSelectTab}
              ></TabView>
            </>
          )}
        </Suspense>
      </>
    );
  }
}

class TabView extends React.PureComponent {
  render() {
    const {
      selectedTab,
      data,
      //unitId,
      handleSelectTab,
    } = this.props;
    return (
      <>
        {selectedTab === "OVERVIEW" && (
          <OverviewView
            revenue={data.reports.revenue}
            experimentParams={data.reports.experimentParams}
            rpms={data.reports.rpms}
            healthCheck={data.healthCheck}
            handleSelectTab={handleSelectTab}
            networkTimezone={data.props.gamNetwork.timezone}
          ></OverviewView>
        )}

        {selectedTab === "BENCHMARK_VALIDATION" && (
          <BenchmarkValidationView
            reports={data.reports.benchmarkValidation}
            iedReports={data.reports.iedReports}
          ></BenchmarkValidationView>
        )}

        {selectedTab === "INVENTORY" && (
          <InventoryView reports={data.reports.inventory}></InventoryView>
        )}

        {selectedTab === "UPR_VALIDATION" && (
          <UprValidationView
            reports={data.reports.uprValidation}
            reportsByRules={data.reports.pricingRuleReport}
          ></UprValidationView>
        )}

        {selectedTab === "EXPERIMENT_CONFIG" && (
          <ConfigView
            optimizationSettings={data.optimizationSettings}
          ></ConfigView>
        )}
      </>
    );
  }
}

class UnitInsightHeaderAndTabs extends React.PureComponent {
  render() {
    const { data, selectedTab, handleSelectTab } = this.props;

    return (
      <div className="sticky w-full top-0 bg-gray-100 shadow-md z-50">
        <UnitInsightHeader
          unit={data.props}
          segments={data.segments}
          updatedAt={data.updatedAt}
        ></UnitInsightHeader>

        <div className="flex justify-between items-center pb-1">
          <UnitInsightTabs
            data={data}
            selectedTab={selectedTab}
            handleSelectTab={handleSelectTab}
          ></UnitInsightTabs>

          <div className="flex items-center px-4 font-semibold">
            <div className="flex items-center">
              <div className="mr-2">
                {data.props.gamNetwork.timezone.replace("_", " ")}
              </div>
              <div className="text-xs" style={{ marginTop: "2px" }}>
                <TimeOfDayProgressBar
                  timezone={data.props.gamNetwork.timezone}
                ></TimeOfDayProgressBar>
              </div>
            </div>
            <span className="mx-2 font-hairline text-gray-500">|</span>
            {data.props.gamNetwork.currency}
          </div>
        </div>
      </div>
    );
  }
}

// class ConfigSideBar extends React.PureComponent {
//   render() {
//     const {
//       defaultConfig,
//       isConfigSidebarExpanded,
//       toggleIsConfigSidebarExpanded
//     } = this.props;

//     if (!isConfigSidebarExpanded) {
//       return (
//         <div
//           className="bg-white shadow right-0 top-0 h-screen fixed border-l border-gray-400 cursor-pointer text-gray-700 hover:text-gray-900 hover:bg-blue-100 hover:border-l-2"
//           style={{ width: "45px" }}
//           onClick={toggleIsConfigSidebarExpanded}
//         >
//           <div style={{ marginTop: "146px" }}>
//             <div className="flex items-center">
//               <div className="text-xl font-bold mx-auto my-2">
//                 <FiInfo></FiInfo>
//               </div>
//             </div>
//           </div>
//         </div>
//       );
//     } else {
//       return (
//         <div
//           className="bg-white right-0 top-0 h-full fixed border-l border-gray-400 shadow-md"
//           style={{ width: "260px" }}
//         >
//           <div style={{ marginTop: "146px" }}>
//             <div className="border-b p-2 flex items-center justify-between text-gray-800">
//               <div className="font-bold">Default Config</div>
//               <button
//                 type="button"
//                 className="text-xl font-bold p-2"
//                 onClick={toggleIsConfigSidebarExpanded}
//               >
//                 <MdClose></MdClose>
//               </button>
//             </div>
//             <div className="flex items-center">
//               <ConfigView defaultConfig={defaultConfig}></ConfigView>
//             </div>
//           </div>
//         </div>
//       );
//     }
//   }
// }

export default UnitInsightViewer;
