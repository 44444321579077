import React from "react";
// import MetricTooltip from "../../common/MetricTooltip";
import NumberFormat from "../../common/NumberFormat";
import FadeInWrapper from "./FadeInWrapper";
import InfoDescriptionTooltip from "./InfoDescriptionTooltip";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

function AlgoCard(props) {
  const { title, tooltipMetricKey, value, footerElement } = props;
  return (
    <div className="flex flex-col justify-around p-4 border rounded shadow">
      <div className="flex flex-col items-center">
        <div className="font-semibold text-gray-700 flex items-center">
          {title}
          <InfoDescriptionTooltip
            metricKey={tooltipMetricKey}
            place="top"
          ></InfoDescriptionTooltip>
        </div>
        <div className="text-3xl mb-2">
          <FadeInWrapper key={value}>
            <NumberFormat value={value} format="0,0"></NumberFormat>
          </FadeInWrapper>
        </div>
      </div>
      <div className="text-gray-700 text-center text-sm bg-gray-200 py-2 -mb-4 -mx-4">
        {footerElement}
      </div>
    </div>
  );
}

// v2 only
class YbMockupHomeAlgorithmSection extends React.Component {
  render() {
    const {
      inventoryData,
      //  selectedYieldSetId
    } = this.props;
    // const isYieldSetFilter = selectedYieldSetId !== -1;

    return (
      <div style={fixedWidthStyle}>
        <div className="flex justify-between items-center mb-4">
          <div className="text-2xl font-bold">Protection Activities</div>
        </div>

        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-4">
            <AlgoCard
              title="Current Segments"
              tooltipMetricKey="CURRENT_SEGMENTS"
              value={inventoryData.currentSegments}
              footerElement={
                <div>
                  Evolved over{" "}
                  <span className="font-bold">
                    <NumberFormat
                      value={inventoryData.totalSegmentBatches}
                      format="0,0"
                    ></NumberFormat>
                  </span>{" "}
                  generations
                </div>
              }
            ></AlgoCard>
          </div>

          <div className="col-span-4">
            <AlgoCard
              title="Floor Strategy Simulations"
              tooltipMetricKey="FLOOR_STRATEGY_SIMULATIONS"
              value={inventoryData.totalExperiments}
              footerElement={
                <div>
                  Over{" "}
                  <span className="font-bold">
                    <NumberFormat
                      value={inventoryData.averageExperimentsPerDay}
                      format="0,0"
                    ></NumberFormat>
                  </span>{" "}
                  simulations per day
                </div>
              }
            ></AlgoCard>
          </div>

          <div className="col-span-4">
            <AlgoCard
              title="Pricing Detections"
              tooltipMetricKey="PRICING_DETECTIONS"
              value={inventoryData.totalExperimentGroups}
              footerElement={
                <div>
                  Over{" "}
                  <span className="font-bold">
                    <NumberFormat
                      value={inventoryData.averageExperimentGroupsPerDay}
                      format="0,0"
                    ></NumberFormat>
                  </span>{" "}
                  price detections per day
                </div>
              }
            ></AlgoCard>
          </div>
        </div>
      </div>
    );
  }
}

export default YbMockupHomeAlgorithmSection;
