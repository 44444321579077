import React from "react";
// import _ from "lodash";
import SetOfAdSizesFormatter from "./SetOfAdSizesFormatter";
import NewSetOfAdSizesControl from "./NewSetOfAdSizesControl";

const CLIENT_REQUEST_SIZES = "client_request_sizes";

class SetsOfAdSizesControl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      setsOfAdSizes: this.props.inputValue,
      currentSet: [],

      isEditCardOpen: false,
    };

    this.handleAddNewSet = this.handleAddNewSet.bind(this);
    this.handleEditSet = this.handleEditSet.bind(this);
    this.handleRemoveSet = this.handleRemoveSet.bind(this);

    this.handleOpenEditCard = this.handleOpenEditCard.bind(this);
    this.handleCloseEditCard = this.handleCloseEditCard.bind(this);
    this.handleAddClientRequestSizes = this.handleAddClientRequestSizes.bind(
      this
    );
  }

  handleOpenEditCard() {
    this.setState({
      isEditCardOpen: true,
    });
  }

  handleCloseEditCard() {
    this.setState({
      isEditCardOpen: false,
    });
  }

  handleAddClientRequestSizes() {
    const newSet = CLIENT_REQUEST_SIZES;
    const setsOfAdSizes = [...this.state.setsOfAdSizes, newSet];

    this.setState({
      setsOfAdSizes,
    });

    this.props.handleNewValueChanged(setsOfAdSizes);
  }

  handleAddNewSet(newSet) {
    console.log("Add new SEt", newSet);

    const setsOfAdSizes = [...this.state.setsOfAdSizes, newSet];

    this.setState({
      currentSet: [],
      setsOfAdSizes,
      isEditCardOpen: false,
    });

    this.props.handleNewValueChanged(setsOfAdSizes);
  }

  handleEditSet(index) {
    console.log("edit set", index);
    this.setState({
      currentSet: this.state.setsOfAdSizes[index],
      isEditCardOpen: true,
    });

    // remove from sets of ad sizes
    this.handleRemoveSet(index, true);
  }

  handleRemoveSet(index, isEdit) {
    console.log("removeing set", index);
    const sets = [...this.state.setsOfAdSizes];
    sets.splice(index, 1);
    this.setState({
      setsOfAdSizes: sets,
    });

    if (!isEdit) {
      this.props.handleNewValueChanged(sets);
    }
  }

  render() {
    const { setsOfAdSizes, currentSet, isEditCardOpen } = this.state;

    return (
      <div className="ml-4 text-sm">
        <div className="mb-4">
          <div className="text-gray-800">
            Sets of Ad Sizes ({setsOfAdSizes.length}):
          </div>
          {setsOfAdSizes.length === 0 ? (
            "None"
          ) : (
            <SetOfAdSizesFormatter
              setsOfAdSizes={setsOfAdSizes}
              showActionButtons={true}
              handleRemoveSet={this.handleRemoveSet}
              handleEditSet={this.handleEditSet}
            ></SetOfAdSizesFormatter>
          )}

          <div>
            <button
              type="button"
              className="px-2 py-1 text-blue-600 bg-blue-100 hover:bg-blue-200 rounded shadow mr-1"
              onClick={this.handleAddClientRequestSizes}
            >
              Add <b>client_request_sizes</b>
            </button>

            <button
              type="button"
              className="px-2 py-1 text-blue-600 bg-blue-100 hover:bg-blue-200 rounded shadow"
              onClick={this.handleOpenEditCard}
            >
              Create new set
            </button>
          </div>
        </div>

        {isEditCardOpen && (
          <NewSetOfAdSizesControl
            key={currentSet}
            currentSet={currentSet}
            handleAddNewSet={this.handleAddNewSet}
          ></NewSetOfAdSizesControl>
        )}
      </div>
    );
  }
}

export default SetsOfAdSizesControl;
