import _ from "lodash";

export const demandTypePrefixes = [
  "sponsorship",
  "standard",
  "preferredDeal",
  "adx",
  "ads",
  "openBidding",
  "eb",
  "pricePriority",
  "bulk",
  "network",
  "mediation",
  "house"
];

export const demandTypeIndex = _.reduce(
  demandTypePrefixes,
  (result, dt, index) => {
    result[dt] = index;
    return result;
  },
  {}
);
