import React from "react";
import _ from "lodash";
import moment from "moment-timezone";

import DatePicker from "react-datepicker";
import { notify } from "react-notify-toast";

import {
  getPublishersWithTrialStatus,
  updatePublisherTrialStatus,
} from "../../../helpers/Api";
import PublishersTable from "./PublishersTable";
import LoadingUI from "../../common/LoadingUI";

class UpdatePublisherTrialStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      publisherId: null,
      tempPublisher: null,
      errMsg: "",

      expireDate: null,
      billableStartDate: null,
    };

    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    try {
      const { pubId } = this.props.match.params;
      const queryParams = {
        publisherId: pubId,
      };
      const pubs = await getPublishersWithTrialStatus(queryParams);
      console.log(pubs[0]);

      const pub = pubs[0];
      const expireDate = _.get(pub, "accountStatus.trialStatus.expDate");
      const billableStartDate = _.get(
        pub,
        "accountStatus.trialStatus.billableStartDate"
      );

      this.setState({
        publisherId: pubId,
        tempPublisher: pub,

        expireDate,
        billableStartDate,

        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isLoading: false,
        errMsg: err.toString(),
      });
    }
  }

  handleDateSelect(date, key) {
    this.setState({
      [key]: date,
    });
  }

  async handleUpdate() {
    try {
      const { publisherId, expireDate, billableStartDate } = this.state;
      if (!expireDate && !billableStartDate) {
        throw "Missing 'Expire Date', or 'Billable Start Date'!";
      }

      let params = {
        publisherId,
      };

      // "2019-04-15T00:00:00.000Z" <-- should end in T00:00:000Z
      if (expireDate) {
        const d = moment(expireDate).format("YYYY-MM-DD");
        const dd = new Date(d).toISOString().substring(0, 10);
        params.expireDate = `${dd}T00:00:00.000Z`;
      }

      if (billableStartDate) {
        const d = moment(billableStartDate).format("YYYY-MM-DD");
        const dd = new Date(d).toISOString().substring(0, 10);
        params.billableStartDate = `${dd}T00:00:00.000Z`;
      } else {
        params.billableStartDate = null;
      }

      this.setState({
        isSaving: true,
      });
      console.log(params);
      const result = await updatePublisherTrialStatus(params);
      console.log(result);

      notify.show(
        "Trial status updated! Redirecting back to Publishers...",
        "success"
      );

      this.setState({
        isSaving: false,
        errMsg: "",
      });

      setTimeout(() => {
        // window.location.reload(false);
        this.props.history.goBack();
      }, 3000);
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }
  }

  render() {
    const {
      publisherId,
      tempPublisher,
      expireDate,
      billableStartDate,
      isLoading,
      isSaving,
      errMsg,
    } = this.state;

    return (
      <div>
        <div className="bg-white px-12 pt-8 pb-4">
          <div className="font-extrabold text-gray-900 text-4xl">
            Update Publisher Trial Status
          </div>

          {tempPublisher && (
            <PublishersTable
              items={[tempPublisher]}
              hideAction={true}
            ></PublishersTable>
          )}
        </div>

        {isLoading && <LoadingUI></LoadingUI>}

        {tempPublisher && (
          <div className="bg-gray-200 px-12 py-8 min-h-screen">
            <div>
              <div className="flex justify-center">
                <div className="w-1/2 p-4 border rounded shadow">
                  <div className="py-2 font-bold text-lg">
                    {tempPublisher.publisherId} - {tempPublisher.name}
                  </div>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Trial Expire Date:
                    </label>
                    <div>
                      <DatePicker
                        selected={expireDate && new Date(expireDate)}
                        dateFormat="yyyy-MM-dd"
                        className="border p-2 rounded w-64"
                        placeholderText="Click to select a date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onSelect={(date) =>
                          this.handleDateSelect(date, "expireDate")
                        }
                      />
                    </div>
                  </div>

                  <hr></hr>

                  <div className="my-4">
                    <label className="font-semibold text-gray-800">
                      Billable Start Date:
                    </label>
                    <div>
                      <DatePicker
                        selected={
                          billableStartDate && new Date(billableStartDate)
                        }
                        isClearable
                        dateFormat="yyyy-MM-dd"
                        className="border p-2 rounded w-64"
                        placeholderText="Click to select a date"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        onChange={(update) => {
                          return this.handleDateSelect(
                            update,
                            "billableStartDate"
                          );
                        }}
                        // onSelect={(date) => {
                        //   console.log(date);
                        //   return this.handleDateSelect(
                        //     date,
                        //     "billableStartDate"
                        //   );
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row-reverse mt-4 items-center">
              <div>
                <button
                  type="button"
                  className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                  onClick={() => this.handleUpdate()}
                >
                  {isSaving ? "Saving..." : "Update"}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  disabled={isSaving}
                  className={`px-4 py-2 text-blue-700 ${
                    isSaving ? "cursor-not-allowed" : ""
                  }`}
                  onClick={() => this.props.history.goBack()}
                >
                  Back
                </button>
              </div>
              <div className="text-red-600">{errMsg}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default UpdatePublisherTrialStatus;
