import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { getNetworkReportingStatuses } from "../../helpers/Api";
import LoadingUI from "../common/LoadingUI";
import NetworkStatusIndicator from "../common/NetworkStatusIndicator";

class ReportingStatusViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      updatedAt: null,
      delayedReports: [],
      missingReports: [],
    };
  }

  async componentDidMount() {
    document.title = `Health Check - Network Reporting Status`;

    this.setState({ isLoading: true });
    await this.queryNetworkReportingStatuses();
    this.setState({ isLoading: false });
  }

  async queryNetworkReportingStatuses() {
    try {
      const data = await getNetworkReportingStatuses();

      if (_.isEmpty(data)) {
        this.setState({ updatedAt: null, errMsg: "No data found" });
      }

      const { updatedAt, delayedReports, missingReports } = data;

      this.setState({
        updatedAt: moment(updatedAt).format("YYYY/MM/DD HH:mm:ss"),
        delayedReports: _.orderBy(delayedReports, ["delayedTime"], ["desc"]),
        missingReports: _.orderBy(
          missingReports,
          ["networkStatus", "networkId", "reportType"],
          ["asc", "desc", "asc"]
        ),
      });
    } catch (err) {
      console.log("Error querying network reporting status", err);
    }
  }

  render() {
    const {
      isLoading,
      errMsg,
      delayedReports,
      missingReports,
      updatedAt,
    } = this.state;

    return (
      <>
        {isLoading ? (
          <LoadingUI></LoadingUI>
        ) : (
          <>
            {errMsg ? (
              <div>{errMsg}</div>
            ) : (
              <div className="bg-white px-12">
                <h1 className="font-extrabold text-gray-900 text-4xl pt-8 pb-2">
                  <div className="text-base leading-none font-bold text-gray-600">
                    Health Check
                  </div>
                  Network Reporting Status
                </h1>

                <div className="text-sm font-semibold text-gray-700 pb-2">
                  Updated at: {updatedAt}
                </div>
              </div>
            )}

            <div className="bg-gray-200 px-12 py-8 min-h-full">
              <>
                <div className="text-lg font-semibold text-blue-900 mb-2">
                  Delayed Reports ({delayedReports.length}):
                </div>

                <div className="p-4 bg-white mb-4">
                  {delayedReports.length > 0 ? (
                    <table className="table-auto w-full">
                      <thead className="text-sm text-gray-800 align-bottom">
                        <tr>
                          <th className="px-4 py-2 text-left">Network</th>
                          <th className="px-4 py-2 text-left">Report Type</th>
                          <th className="px-4 py-2 text-right">Last Updated</th>
                          <th className="px-4 py-2 text-right">Delayed Time</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-900">
                        {delayedReports.map((d) => {
                          const lastUpdated = moment(d.lastUpdated).format(
                            "YYYY-MM-DD HH:mm:ss"
                          );
                          const delayedTime = _calculateDelayedTime(
                            d.delayedTime
                          );
                          return (
                            <tr
                              key={`${d.networkId}|${d.reportType}`}
                              className="hover:bg-gray-100"
                            >
                              <td className="border px-4 py-2 flex items-center">
                                <NetworkStatusIndicator
                                  status={d.networkStatus}
                                ></NetworkStatusIndicator>
                                <span className="pl-2">
                                  [{d.networkId}] {d.networkName}
                                </span>
                              </td>

                              <td className="border px-4 py-2">
                                {d.reportType}
                              </td>

                              <td className="border px-4 py-2 text-right">
                                {lastUpdated}
                              </td>

                              <td className="border px-4 py-2 text-right">
                                {delayedTime}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div>
                      All reports have successfully run in the past 60 minutes.
                    </div>
                  )}
                </div>
              </>
              <>
                <div className="text-lg font-semibold text-blue-900 mb-2">
                  Missing Reports ({missingReports.length}):
                </div>

                <div className="p-4 bg-white mb-4">
                  {missingReports.length > 0 ? (
                    <table className="table-auto w-full">
                      <thead className="text-sm text-gray-800 align-bottom">
                        <tr>
                          <th className="px-4 py-2 text-left">Network</th>
                          <th className="px-4 py-2 text-left">Report Type</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-900">
                        {missingReports.map((d) => {
                          return (
                            <tr
                              key={`${d.networkId}|${d.reportType}`}
                              className="hover:bg-gray-100"
                            >
                              <td className="border px-4 py-2 flex items-center">
                                <NetworkStatusIndicator
                                  status={d.networkStatus}
                                ></NetworkStatusIndicator>
                                <span className="pl-2">
                                  [{d.networkId}] {d.networkName}
                                </span>
                              </td>

                              <td className="border px-4 py-2">
                                {d.reportType}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div>No networks are missing reports.</div>
                  )}
                </div>
              </>
            </div>
          </>
        )}
      </>
    );
  }
}

function _calculateDelayedTime(time) {
  return moment.duration(time).humanize();
}

export default ReportingStatusViewer;
