import React from "react";
import _ from "lodash";
import YbMockupHomeTopFilterHeader from "./TopFilterHeader";
import { DATE_RANGE_TYPE, getDateRangeByType } from "./DateHelper";
import {
  transformHomeDashboardData,
  calculateInventoryData,
  calculateSummaryData,
  calculateDistributionData,
  calculateTrendData,
} from "./ReportsHelper";
import HomeDashboardBody from "./HomeDashboardBody";

function _convertDateToYMD(date) {
  const dd = new Date(date);
  let d = dd.getDate();
  let m = dd.getMonth() + 1; // Month from 0 to 11
  let y = dd.getFullYear();
  let finalString = `${y}-${m <= 9 ? "0" + m : m}-${d <= 9 ? "0" + d : d}`;
  return finalString;
}

class HomeDashboardWrapperV1 extends React.Component {
  constructor(props) {
    super(props);

    // default to "this month"
    const { startDate, endDate } = getDateRangeByType(DATE_RANGE_TYPE.DEFAULT);

    const { resultData, isReseller, pubId, version } = this.props;
    const homeDashboardData = transformHomeDashboardData(resultData);

    const { publishers, gamNetworks } = homeDashboardData;

    // Preselect the only publisher, otherwise "All"
    const selectedPubId =
      publishers.length === 1 ? _.get(publishers, [0, "id"], -1) : -1;

    // For Reseller
    let filteredNetworks = gamNetworks;
    if (selectedPubId !== -1) {
      filteredNetworks = _.filter(gamNetworks, { pubId: selectedPubId });
    }

    // Preselect the only network, otherwise "All"
    const selectedNetworkId =
      gamNetworks.length === 1 ? _.get(gamNetworks, [0, "id"], -1) : -1;

    // const selectedPubId = -1; // Default: All publishers
    // const selectedNetworkId = -1; // Default: All networks
    const selectedYieldSetId = -1; // All YieldSets
    const selectedCurrency = "USD";

    const currencies = _.keys(homeDashboardData.exchangeRates);
    const exchangeRates = homeDashboardData.exchangeRates[selectedCurrency];

    this.state = {
      // filter
      selectedPubId,
      filteredNetworks,
      currencies,

      selectedNetworkId,
      selectedYieldSetId,
      selectedCurrency,

      startDate,
      endDate,

      // data
      homeDashboardData,
      exchangeRates,

      isReseller,
      publishers,

      inventoryData: calculateInventoryData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
      }),
      summaryData: calculateSummaryData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
    };

    this.handlePublisherChanged = this.handlePublisherChanged.bind(this);
    this.handleInventoryFilterChanged =
      this.handleInventoryFilterChanged.bind(this);
    this.handleDateRangeFilterChanged =
      this.handleDateRangeFilterChanged.bind(this);
    this.handleCurrencyChanged = this.handleCurrencyChanged.bind(this);
  }

  handlePublisherChanged(pubId) {
    this.setState({ selectedPubId: pubId });
    console.log("Publisher changed: ", pubId);
  }

  handleInventoryFilterChanged({
    selectedPubId,
    selectedNetworkId,
    selectedYieldSetId,
  }) {
    console.log(
      "Inventory Filter changed: ",
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId
    );
    this.setState({
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId,
      inventoryData: calculateInventoryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
      }),
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleDateRangeFilterChanged({ startDate, endDate }) {
    // Important! Convert a date object (with timezone) to just YYYY-MM-DD format
    var sd = _convertDateToYMD(startDate);
    var ed = _convertDateToYMD(endDate);

    console.log("Date Range Filter changed: ", sd, ed);
    this.setState({
      startDate: sd,
      endDate: ed,
      summaryData: calculateSummaryData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData: this.state.homeDashboardData,
        selectedPubId: this.state.selectedPubId,
        selectedNetworkId: this.state.selectedNetworkId,
        selectedYieldSetId: this.state.selectedYieldSetId,
        startDate: sd,
        endDate: ed,
        exchangeRates: this.state.exchangeRates,
      }),
    });
  }

  handleCurrencyChanged(currency) {
    console.log("Currency changed: ", currency);
    const {
      homeDashboardData,
      startDate,
      endDate,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId,
    } = this.state;
    const exchangeRates = homeDashboardData.exchangeRates[currency];
    this.setState({
      selectedCurrency: currency,
      exchangeRates,
      startDate,
      endDate,
      summaryData: calculateSummaryData({
        homeDashboardData: homeDashboardData,
        selectedPubId,
        selectedNetworkId: selectedNetworkId,
        selectedYieldSetId: selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
      distributionData: calculateDistributionData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
      trendData: calculateTrendData({
        homeDashboardData,
        selectedPubId,
        selectedNetworkId,
        selectedYieldSetId,
        startDate,
        endDate,
        exchangeRates,
      }),
    });
  }

  render() {
    const {
      homeDashboardData,

      isReseller,
      selectedPubId,
      publishers,
      filteredNetworks,
      selectedNetworkId,
      selectedYieldSetId,
      inventoryData,
      summaryData,
      startDate,
      endDate,
      distributionData,
      trendData,
      currencies,
      selectedCurrency,
    } = this.state;

    if (!homeDashboardData) {
      return "No Data";
    }

    return (
      <>
        <div className="sticky top-0 z-40 shadow">
          <YbMockupHomeTopFilterHeader
            isReseller={isReseller}
            selectedPubId={selectedPubId}
            publishers={publishers}
            networks={filteredNetworks}
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetId={selectedYieldSetId}
            yieldSets={homeDashboardData.yieldSets}
            handleInventoryFilterChanged={this.handleInventoryFilterChanged}
            currencies={currencies}
            selectedCurrency={selectedCurrency}
            handleCurrencyChanged={this.handleCurrencyChanged}
            startDate={startDate}
            endDate={endDate}
            handleDateRangeFilterChanged={this.handleDateRangeFilterChanged}
          ></YbMockupHomeTopFilterHeader>
        </div>

        <div className="bg-white px-12 py-6">
          <HomeDashboardBody
            updatedTime={homeDashboardData.updatedTime}
            selectedPubId={selectedPubId}
            selectedNetworkId={selectedNetworkId}
            selectedYieldSetId={selectedYieldSetId}
            inventoryData={inventoryData}
            summaryData={summaryData}
            startDate={startDate}
            endDate={endDate}
            distributionData={distributionData}
            trendData={trendData}
            selectedCurrency={selectedCurrency}
          ></HomeDashboardBody>
        </div>
      </>
    );
  }
}

export default HomeDashboardWrapperV1;
