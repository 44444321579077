import React from "react";

class LoadingUI extends React.PureComponent {
  render() {
    return (
      <>
        <div className="flex justify-center p-8">
          <div className="loader"></div>
        </div>
        <div className="text-center text-gray-200">Loading data...</div>
      </>
    );
  }
}

export default LoadingUI;
