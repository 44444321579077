import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { IoMdRefresh } from "react-icons/io";
import ReactTooltip from "react-tooltip";

import ObserverHeader from "./ObserverHeader";
import SegmentOverview from "./SegmentOverview";
import SegmentData from "./SegmentData";
import SegmentExperiments from "./SegmentExperiments";
import SegmentList from "./SegmentList";
import { getItem, setItem } from "../../helpers/LocalStorage";
import DateRangeSelector from "../common/DateRangeSelector";

const mode = {
  OLD: "OLD_TABLE",
  NEW: "NEW_TABLE",
};

const modeStorageKey = "OBSERVER_MODE";

class ObserverOverview extends React.Component {
  constructor(props) {
    super(props);

    const preferenceMode = getItem(modeStorageKey);
    this.state = {
      mode: preferenceMode || mode.OLD,
      segmentId: null,

      // filter data once segment is selected
      segmentData: null,
    };

    this.handleSegmentSelect = this.handleSegmentSelect.bind(this);
    this.handleViewModeChange = this.handleViewModeChange.bind(this);
  }

  async componentDidMount() {
    const { data } = this.props;
    // auto show reports since there is no segments
    if (_.get(data, "segmentInfo[0].id") === -1) {
      this.handleSegmentSelect(-1, false);
    } else if (_.get(data, "segmentInfo", []).length === 1) {
      this.handleSegmentSelect(data.segmentInfo[0].id, false);
    }
  }

  filterSegmentData(segmentId) {
    const originalData = this.props.data;

    const yieldLifts = _.filter(originalData.yieldLifts, { segmentId });
    const segmentInfo = _.find(originalData.segmentInfo, { id: segmentId });

    const expInfo = _(originalData.expInfo)
      .values()
      .filter({ segmentId })
      .mapKeys("id")
      .value();

    const segmentExpGroupIds = _.reduce(
      yieldLifts,
      (result, r) => {
        _.forEach(r.reports, (expReport) => {
          result.push(expReport.expGroupId);
        });
        return result;
      },
      []
    );

    const groupInfo = _.reduce(
      originalData.groupInfo,
      (result, group, expGroupId) => {
        if (_.indexOf(segmentExpGroupIds, _.parseInt(expGroupId)) !== -1) {
          result[expGroupId] = group;
        }
        return result;
      },
      {}
    );

    // Build segment family
    const expLink = _(originalData.expInfo)
      .groupBy(({ segmentId }) => `${segmentId || "unit"}`)
      .mapValues((exps) => {
        // Order by expId desc
        exps.sort((l, r) => r.id - l.id);

        return _.map(exps, ({ id }, index, c) => {
          const next = index === 0 ? undefined : c[index - 1].id;
          const prev = index === c.length - 1 ? undefined : c[index + 1].id;
          return { id, next, prev };
        });
      })
      .values()
      .flatten()
      .keyBy("id")
      .value();

    return {
      yieldLifts,
      segmentInfo,
      expInfo,
      groupInfo,
      expLink,
    };
  }

  handleSegmentSelect(segmentId, shouldScroll = true) {
    // clear data first!!
    this.setState({ segmentId: null });

    const segmentData = this.filterSegmentData(segmentId);

    this.setState({ segmentId, segmentData });

    if (shouldScroll) {
      setTimeout(() => {
        const element = document.getElementById("top-of-segment-experiences");
        if (element) {
          window.scrollTo({
            behavior: "smooth",
            top: element.offsetTop,
          });
        }
      }, 500);
    }
  }

  handleViewModeChange(mode) {
    if (this.state.mode === mode) {
      return;
    }

    var eleId = "old-table-mode";
    if (mode === mode.NEW) {
      eleId = "new-table-mode";
    }

    const element = document.getElementById(eleId);
    const originalWording = element.innerHTML;
    element.innerHTML = "Loading...";

    setItem(modeStorageKey, mode);

    setTimeout(() => {
      this.setState({ mode });
      element.innerHTML = originalWording;
    }, 500);
  }

  render() {
    const { data, selectedDateRange, onDateRangeChange, handleForceRefresh } =
      this.props;
    const { segmentData } = this.state;

    const { dateRange } = data;
    const numOfExperiments = _.keys(_.get(data, "expInfo"), {}).length;
    const numOfSegments = _.get(data, "segmentInfo", []).length;

    return (
      <>
        <ObserverHeader data={data}></ObserverHeader>

        {/* Offset from top of the screen */}
        <div style={{ height: "20px" }}></div>

        <div className="px-4 mb-4 text-gray-200">
          <div className="my-2 flex items-center justify-between">
            {selectedDateRange && (
              <DateRangeSelector
                selectedDateRange={selectedDateRange}
                onDateRangeChange={onDateRangeChange}
              ></DateRangeSelector>
            )}

            <div>
              <button
                type="button"
                className="px-2 py-1 bg-gray-400 text-gray-800 rounded font-medium flex items-center hover:bg-white"
                data-tip
                data-for="observer-force-update-tooltip"
                onClick={handleForceRefresh}
              >
                <IoMdRefresh></IoMdRefresh>
                <span className="text-sm pl-1">Force Update</span>
              </button>

              <ReactTooltip
                id="observer-force-update-tooltip"
                type="dark"
                effect="solid"
              >
                Click to update data immediately
              </ReactTooltip>
            </div>
          </div>

          <div>
            Timezone: {data.networkSetting.timezone}, Currency:{" "}
            {data.networkSetting.currency}
          </div>

          <div>
            Data ranging from{" "}
            <b>
              {moment(data.dateRange.startDate)
                .tz("Asia/Taipei")
                .format("YYYY-MM-DD HH:mm:ss")}{" "}
              GMT+8
            </b>{" "}
            to{" "}
            <b>
              {moment(data.dateRange.endDate)
                .tz("Asia/Taipei")
                .format("YYYY-MM-DD HH:mm:ss")}{" "}
              GMT+8
            </b>
            <span className="float-right">
              Updated at {data.reportCreateTime}{" "}
            </span>
          </div>
        </div>

        {numOfExperiments > 0 && (
          <>
            {numOfSegments > 0 && (
              <SegmentOverview
                data={data}
                handleSegmentSelect={this.handleSegmentSelect}
              ></SegmentOverview>
            )}

            <div
              id="top-of-segment-experiences"
              className="py-4 px-4 flex items-center justify-between bg-blue-900"
            >
              {this.state.segmentId ? (
                <div className="text-xl text-gray-100">
                  {this.state.segmentId === -1 ? (
                    "No Segment"
                  ) : (
                    <div className="flex items-center">
                      <div className="whitespace-no-wrap">
                        Segment: {this.state.segmentId}{" "}
                      </div>
                      <SegmentData
                        segmentId={this.state.segmentId}
                        data={data}
                      ></SegmentData>
                    </div>
                  )}
                </div>
              ) : (
                <div className="text-xl text-gray-200">
                  Select a segment from the table above to view experiments.
                </div>
              )}

              {this.state.segmentId && (
                <ViewModeSelector
                  selectedMode={this.state.mode}
                  handleViewModeChange={this.handleViewModeChange}
                ></ViewModeSelector>
              )}
            </div>

            {this.state.segmentId && segmentData ? (
              <div className="bg-gray-800">
                {this.state.mode === mode.NEW && (
                  <SegmentList
                    data={data}
                    segmentId={this.state.segmentId}
                  ></SegmentList>
                )}
                {this.state.mode === mode.OLD && (
                  <SegmentExperiments
                    segmentId={this.state.segmentId}
                    dateRange={dateRange}
                    yieldLifts={segmentData.yieldLifts}
                    segmentInfo={segmentData.segmentInfo}
                    expInfo={segmentData.expInfo}
                    groupInfo={segmentData.groupInfo}
                    expLink={segmentData.expLink}
                  ></SegmentExperiments>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </>
    );
  }
}

class ViewModeSelector extends React.Component {
  render() {
    const { selectedMode, handleViewModeChange } = this.props;

    return (
      <div>
        <button
          type="button"
          onClick={(e) => handleViewModeChange(mode.OLD)}
          className={`font-bold py-2 px-4 rounded-l shadow text-sm ${
            selectedMode === mode.OLD
              ? "bg-white text-blue-900 cursor-default"
              : "text-gray-300 bg-gray-600 hover:bg-gray-500 hover:text-gray-100"
          }`}
        >
          {selectedMode === mode.OLD ? <span>&#10003; </span> : ""}
          <span id="old-table-mode">Table View</span>
        </button>

        <button
          type="button"
          onClick={() => handleViewModeChange(mode.NEW)}
          className={`font-bold py-2 px-4 rounded-r shadow text-sm ${
            selectedMode === mode.NEW
              ? "bg-white text-blue-900 cursor-default"
              : "text-gray-300 bg-gray-600 hover:bg-gray-500 hover:text-gray-100"
          }`}
        >
          {selectedMode === mode.NEW ? <span>&#10003; </span> : ""}
          <span id="new-table-mode">New Table View</span>
        </button>
      </div>
    );
  }
}

export default ObserverOverview;
