import React from "react";
import ReactModal from "react-modal";

class ModalWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal() {
    this.props.handleClose();
  }

  render() {
    const { isOpen, width } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            width: width ? width : "60%",
          },
        }}
        onAfterOpen={() => {
          // to prevent background scrolling
          document.body.style.overflow = "hidden";
        }}
        onAfterClose={() => {
          // Important! make background scrollable again
          document.body.style.overflow = "auto";
        }}
      >
        {this.props.children}

        {this.props.showCloseFooter && (
          <div className="flex justify-end py-1 border-t px-6 -mx-6 -mb-4">
            <button
              type="button"
              className="flex-shrink-0 font-semibold border-transparent border-4 text-blue-500 hover:text-blue-800 text-sm py-1 px-2 rounded"
              onClick={this.handleCloseModal}
            >
              Close
            </button>
          </div>
        )}
      </ReactModal>
    );
  }
}

export default ModalWrapper;
