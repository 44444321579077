import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import ExperimentGroups from "./ExperimentGroups";
import SegmentDetail from "./SegmentDetail";
import ReactTooltip from "react-tooltip";
import NumberFormat from "../common/NumberFormat";

class ExperimentSection extends React.PureComponent {
  goToDebugPage({ expId }) {
    window.open(`${window.location.origin}/#/anatomy/debug/${expId}`);
  }

  render() {
    const {
      data,
      segmentInfo,
      groupInfo,
      expInfo,
      expLink,
      dateRange,
      scrollToExp,
    } = this.props;

    const reports = data.reports;
    const segmentData = _.find(segmentInfo, { id: data.segmentId }) || {
      id: data.segmentId,
    };

    const expStartDate = moment(data.startDate).tz("Asia/Taipei");
    const dateRangeStartDate = moment(dateRange.startDate);
    const isDataIncomplete = expStartDate.isBefore(dateRangeStartDate);

    return (
      expInfo[data.expId] && (
        <div
          id={`#expbox-${data.expId}`}
          className="bg-gray-200 mx-2 my-8 rounded shadow"
        >
          {/* Experiment Title */}
          <div className="bg-gray-400 p-4 rounded-t">
            <div className="font-mono">
              <button
                type="button"
                className="mr-2 text-blue-700 hover:underline"
                onClick={() => this.goToDebugPage({ expId: data.expId })}
              >
                EXP-{data.expId}{" "}
              </button>

              <span
                className="border-b border-dashed border-gray-600"
                data-tip
                data-for={`parameters-${data.expId}`}
              >
                {expInfo[data.expId].name}
              </span>
              <ReactTooltip
                id={`parameters-${data.expId}`}
                type="dark"
                effect="solid"
              >
                <div>
                  <pre>
                    {JSON.stringify(expInfo[data.expId].parameters, null, 2)}
                  </pre>
                </div>
              </ReactTooltip>
            </div>

            <div className="flex justify-between">
              <div className="text-3xl font-bold text-gray-900">
                <span className="mr-2">
                  {moment(data.startDate)
                    .tz("Asia/Taipei")
                    .format("YYYY-MM-DD HH:mm")}{" "}
                  GMT+8
                </span>
                <span className="mx-2 text-gray-600">·</span>
                <span className="mx-2">
                  {segmentData.id === -1 ? (
                    "No segment"
                  ) : (
                    <>
                      <span
                        className="border-b border-dashed border-gray-600"
                        data-tip
                        data-for={`segment-${segmentData.id}`}
                      >
                        Segment: {segmentData.id}
                      </span>
                      <ReactTooltip
                        id={`segment-${segmentData.id}`}
                        type="dark"
                        effect="solid"
                      >
                        <SegmentDetail
                          segmentData={segmentData}
                        ></SegmentDetail>
                      </ReactTooltip>
                    </>
                  )}
                </span>
                <span className="mx-2 text-gray-600">·</span>
                <span className="mx-2">Mode: {expInfo[data.expId].mode}</span>

                <div>
                  <span>
                    GrossLift:{" "}
                    <span
                      className={`${data.grossLift < 0 ? "text-red-600" : ""}`}
                    >
                      {data.grossLift}%
                    </span>
                  </span>
                  <span className="mx-2 text-gray-600">·</span>
                  <span>
                    NetLift:{" "}
                    <span
                      className={`${data.netLift < 0 ? "text-red-600" : ""}`}
                    >
                      {data.netLift}%
                    </span>
                  </span>
                  <span className="mx-2 text-gray-600">·</span>
                  <span>
                    AdX Viewability:{" "}
                    <NumberFormat
                      value={data.adxViewability}
                      postfix="%"
                    ></NumberFormat>
                  </span>
                </div>
              </div>
              <div className="inline-flex">
                <button
                  type="button"
                  onClick={() => scrollToExp(expLink[data.expId].prev)}
                  className={`text-white font-bold py-2 px-4 rounded ${
                    expLink[data.expId].prev
                      ? "bg-blue-500 hover:bg-blue-700"
                      : "bg-gray-500"
                  }`}
                >
                  Previous
                </button>

                <button
                  type="button"
                  onClick={() => scrollToExp(expLink[data.expId].next)}
                  className={`text-white font-bold py-2 px-4 rounded ml-1 ${
                    expLink[data.expId].next
                      ? "bg-blue-500 hover:bg-blue-700"
                      : "bg-gray-500"
                  }`}
                >
                  Next
                </button>
              </div>
            </div>
          </div>

          {isDataIncomplete && (
            <div className="m-2 py-2 px-4 bg-red-200 border border-red-400 text-red-800 rounded text-sm">
              Data is incomplete. Report is queried from{" "}
              {dateRangeStartDate.format("YYYY-MM-DD HH:mm")}, where this
              experiment starts from {expStartDate.format("YYYY-MM-DD HH:mm")}.
              <p className="font-bold">
                Try a larger date range to show the full report for this
                experiment.
              </p>
            </div>
          )}

          {/* Experiment Groups */}
          <div className="p-4">
            <ExperimentGroups
              reports={reports}
              groupInfo={groupInfo}
              expId={data.expId}
            ></ExperimentGroups>
          </div>
        </div>
      )
    );
  }
}

export default ExperimentSection;
