import React from "react";
import _ from "lodash";

const SegmentTargeting = ({ target, targetKey }) => {
  if (!target) {
    return "-";
  }
  const hasIncludeString =
    _.has(target, "includes") || _.has(target, "excludes");
  let includeString = "";
  let targetValues = [];

  if (hasIncludeString) {
    includeString = target.includes ? "Includes" : "Excludes";
    targetValues = target.includes ? target.includes : target.excludes;
  } else {
    if (targetKey === "sizes") {
      // sizes -> [[970, 90], ...]
      includeString = "Includes";
      targetValues = _.map(target, (sizeSet) => {
        return sizeSet.join("x");
      });
    } else {
      includeString = "Includes";
      targetValues = target;
    }
  }

  return (
    <div>
      <span className={target.includes ? `text-green-600` : `text-red-600`}>
        {includeString}
      </span>
      <div>
        {targetValues.map((value, i) => {
          return (
            <React.Fragment key={value}>
              <span className="p-1 bg-gray-300 text-sm mr-2 whitespace-no-wrap">
                {value}
              </span>
              {i % 2 !== 0 && <div className="mb-2"></div>}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default SegmentTargeting;
