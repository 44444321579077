import React from "react";
import _ from "lodash";
import AdUnitList from "./AdUnitList";
// import { getSnapshotDetail } from "../../helpers/Api";
import { withRouter } from "react-router-dom";
const sampleNetworks = require("../../sample-data/snapshot-detail");

class SnapshotDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null
    };

    this.handleBackToNetworkList = this.handleBackToNetworkList.bind(this);
  }

  async componentDidMount() {
    try {
      // const detailKey = _.get(this.props, "match.params.detailKey");
      // const data = await getSnapshotDetail(detailKey);
      const data = sampleNetworks[0];
      console.log(data);
      this.setState({ data });
    } catch (err) {
      console.log("Error querying snapshot detail", err);
    }
  }

  handleBackToNetworkList() {
    const snapshotKey = _.get(this.props, "match.params.snapshotKey");
    this.props.history.push(`/snapshot/${snapshotKey}`);
  }

  render() {
    const { data } = this.state;
    return (
      <>
        {data && (
          <AdUnitList
            network={this.state.data}
            handleBackToNetworkList={this.handleBackToNetworkList}
          ></AdUnitList>
        )}
      </>
    );
  }
}

export default withRouter(SnapshotDetail);
