import React from "react";
// import _ from "lodash";
// import FinalDesignTrend from "./FinalDesignTrend";
// import TrendCombinedChart from "./TrendCombinedChart";
// import TrendCombinedChartSynched_1 from "./TrendCombinedChartSynched_1";
import TrendCombinedChart from "./TrendCombinedChartFinal";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

class TrendSection extends React.Component {
  render() {
    const {
      reports,
      selectedPubId,
      selectedNetworkId,
      selectedYieldSetId,
      startDate,
      endDate,
      selectedCurrency,
      summaryData,
    } = this.props;

    return (
      <div style={fixedWidthStyle}>
        <div className="flex items-start justify-between">
          <div className="text-2xl font-bold mb-4 ">Performance Trend</div>
        </div>

        <TrendCombinedChart
          reports={reports}
          selectedPubId={selectedPubId}
          selectedNetworkId={selectedNetworkId}
          selectedYieldSetId={selectedYieldSetId}
          startDate={startDate}
          endDate={endDate}
          selectedCurrency={selectedCurrency}
          summaryData={summaryData}
        ></TrendCombinedChart>
      </div>
      // </div>
    );
  }
}

export default TrendSection;
