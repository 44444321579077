import React from "react";
import ReactTooltip from "react-tooltip";

class MetricTooltip extends React.PureComponent {
  render() {
    const { tooltipKey, tooltipMessage, place } = this.props;

    return (
      <>
        <span
          data-tip
          data-for={tooltipKey}
          className="border-b border-dashed border-gray-600"
        >
          {this.props.children}
        </span>

        <ReactTooltip
          id={tooltipKey}
          type="dark"
          effect="solid"
          place={place || "top"}
        >
          {tooltipMessage}
        </ReactTooltip>
      </>
    );
  }
}

export default MetricTooltip;
