import React from "react";
import _ from "lodash";
import { DateRange } from "react-date-range";
import { DATE_RANGES, MIN_DATE, getDateRangeByType } from "./DateHelper";

class DateRangePopover extends React.PureComponent {
  constructor(props) {
    super(props);

    const { startDate, endDate } = this.props;

    this.state = {
      startDate,
      endDate,
    };

    this.onDateRangeChange = this.onDateRangeChange.bind(this);
    this.handleSelectDateRange = this.handleSelectDateRange.bind(this);
    this.handleApplyDateRangeFilter = this.handleApplyDateRangeFilter.bind(
      this
    );
    this.handleCancel = this.handleCancel.bind(this);
  }

  onDateRangeChange(ranges) {
    const { startDate, endDate } = ranges.dateRange;

    this.setState({
      startDate,
      endDate,
    });
  }

  handleSelectDateRange(dateRangeType) {
    const { startDate, endDate } = getDateRangeByType(dateRangeType);

    this.setState({
      startDate,
      endDate,
    });
  }

  handleApplyDateRangeFilter() {
    const { startDate, endDate } = this.state;
    this.props.handleDateRangeFilterChanged({ startDate, endDate });
    this.props.handleClosePopover();
  }

  handleCancel() {
    // close and nothing happened
    this.props.handleClosePopover();
  }

  render() {
    const { startDate, endDate } = this.state;

    return (
      <>
        <div className="p-4 shadow-2xl">
          <div className="flex">
            <div>
              {DATE_RANGES.map((dateRangeType) => {
                const dateRangeTitle = _.capitalize(_.lowerCase(dateRangeType));
                return (
                  <div key={dateRangeType}>
                    <button
                      type="button"
                      onClick={() => this.handleSelectDateRange(dateRangeType)}
                      className="inline-flex border rounded cursor-pointer px-3 py-1 text-sm mr-3 mb-2 bg-white text-gray-700 hover:border-blue-400"
                    >
                      {dateRangeTitle}
                    </button>
                  </div>
                );
              })}
            </div>

            <div>
              <DateRange
                // editableDateInputs={true}
                onChange={this.onDateRangeChange}
                moveRangeOnFirstSelection={false}
                months={2}
                dateDisplayFormat={"yyyy/MM/dd"}
                direction="horizontal"
                scroll={{ enabled: true }}
                maxDate={new Date()}
                minDate={MIN_DATE}
                ranges={[
                  {
                    startDate: new Date(startDate),
                    endDate: new Date(endDate),
                    key: "dateRange",
                  },
                ]}
              />
            </div>
          </div>

          <div className="flex justify-end border-t pt-4">
            <button
              type="button"
              className="text-blue-500 hover:text-blue-600 px-6"
              onClick={this.handleCancel}
            >
              Cancel
            </button>

            <button
              type="button"
              className="inline-flex justify-center py-2 px-10 shadow-sm text-sm rounded text-white bg-blue-500 hover:bg-blue-600"
              onClick={this.handleApplyDateRangeFilter}
            >
              Apply
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default DateRangePopover;
