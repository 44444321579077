import React from "react";
import _ from "lodash";
import NumberFormat from "../common/NumberFormat";

class DemandReportTable extends React.PureComponent {
  render() {
    const { report } = this.props;
    const demandTypes = ["ads", "adx", "eb", "sb"];

    let demandReports = demandTypes.map(type => {
      const revenue = report[`${type}Revenue`];
      const impression = report[`${type}Impression`];

      const ecpm = _.round(
        impression > 0 ? (1000 * revenue) / impression : 0,
        2
      );

      return {
        type: _.capitalize(type),
        revenue,
        impression,
        ecpm,
        revRatio: (revenue / report.revenue) * 100,
        impRatio: (impression / report.impression) * 100
      };
    });

    return (
      <>
        <table className="default-table">
          <thead>
            <tr>
              <th>Demand</th>
              <th>eCPM</th>
              <th>Revenue</th>
              <th>Impression</th>
            </tr>
          </thead>
          <tbody>
            {demandReports.map(d => {
              return (
                <tr key={d.type}>
                  <td className="text-center">{d.type}</td>
                  <td>
                    {d.ecpm > 0 ? (
                      <NumberFormat value={d.ecpm} prefix="$"></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right">
                    <div className="flex">
                      <div className="flex-1">
                        {d.revenue > 0 && (
                          <NumberFormat
                            value={d.revenue}
                            prefix="$"
                          ></NumberFormat>
                        )}
                      </div>
                      <div className="flex-1 pl-1 text-gray-600">
                        {d.revenue > 0 ? (
                          <span>
                            (
                            <NumberFormat
                              value={d.revRatio}
                              format={`0,0`}
                              postfix="%"
                            ></NumberFormat>
                            )
                          </span>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="text-right">
                    <div className="flex">
                      <div className="flex-1">
                        {d.impression > 0 && (
                          <NumberFormat
                            value={d.impression}
                            format={`0,0`}
                          ></NumberFormat>
                        )}
                      </div>
                      <div className="flex-1 pl-1 text-gray-600">
                        {d.impression > 0 ? (
                          <span>
                            (
                            <NumberFormat
                              value={d.impRatio}
                              format={`0,0`}
                              postfix="%"
                            ></NumberFormat>
                            )
                          </span>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default DemandReportTable;
