import React from "react";
import _ from "lodash";
import { notify } from "react-notify-toast";
import ModalWrapper from "../common/ModalWrapper";
import {
  getAutoPilotConfig,
  updateAutoPilotUserHints,
  forceIterateAutoPilotUnit,
} from "../../helpers/Api";
import AutoPilotViewHeader from "./auto-pilot/AutoPilotViewHeader";
import LoadingUI from "../common/LoadingUI";
import DateTimeFormatter from "../common/DateTimeFormatter";
import {
  calculateAvailableHints,
  convertUserHintsForUI,
  getHintTypeFromUserHint,
  resolveHintDefaultConfig,
} from "./constants/AutoPilotConfigHints";
import UnitAPConfigViewer from "./UnitAPConfigViewer";
import UnitHintAPConfigViewer from "./UnitHintAPConfigViewer";

const PAGE_ID = {
  OVERVIEW: "OVERVIEW",
  BASE_CONFIG: "BASE_CONFIG",
  HINT_CONFIG: "HINT_CONFIG",
};

const HINT_NAMES = {
  DC_DESKTOP: "Desktop",
  DC_SMARTPHONE: "Smartphone",
  DC_TABLET: "Tablet",
};

class AutoPilotViewerForUnitWithHints extends React.Component {
  constructor(props) {
    super(props);

    const unitId = _.get(props, "match.params.unitId");

    this.state = {
      currentPage: PAGE_ID.OVERVIEW,

      unitId,

      isLoading: false,
      errMsg: null,

      config: null,
      defaultConfig: null,
      updatedAt: null,

      userHints: [],
      userHintsForUI: null,
      availableHints: [],
      currentHintId: null,
      hintConfig: null, // current hint config

      unitInfo: null,
      networkInfo: null,

      isSaving: false,
      saveErrMsg: null,
    };

    this.onAddHint = this.onAddHint.bind(this);
    this.onEditBaseConfig = this.onEditBaseConfig.bind(this);
    this.onBackToOverview = this.onBackToOverview.bind(this);
    this.onEditHintConfig = this.onEditHintConfig.bind(this);
    this.onRemoveHint = this.onRemoveHint.bind(this);
  }

  async componentDidMount() {
    const { unitId } = this.state;

    this.setState({ isLoading: true });

    try {
      if (unitId) {
        document.title = `${unitId} Unit Auto Pilot Config | YB Control Center`;
        const {
          config,
          defaultConfig,
          userHints = [],
          unitInfo,
          networkInfo,
          updatedAt,
        } = await getAutoPilotConfig({
          unitId,
        });

        let availableHints = calculateAvailableHints({
          userHints,
        });

        const userHintsForUI = convertUserHintsForUI({
          userHints,
        });

        this.setState({
          config,
          defaultConfig,

          userHints,
          userHintsForUI,
          availableHints,

          unitInfo,
          networkInfo,
          updatedAt,
        });
      } else {
        document.title = "404 | YB Control Center";
      }
    } catch (err) {
      console.log("Error querying auto pilot config", err);
      this.setState({ errMsg: typeof err === "object" ? err.toString() : err });
    }

    this.setState({ isLoading: false });
  }

  onAddHint(hintId) {
    // get from availableHints
    const { config: hintConfig } = _.find(this.state.availableHints, {
      id: hintId,
    });

    this.setState({
      currentPage: PAGE_ID.HINT_CONFIG,
      currentHintId: hintId,
      hintConfig,
    });
  }

  onEditHintConfig(hintId) {
    // get from userHintsForUI
    const { config: hintConfig } = _.find(this.state.userHintsForUI, {
      id: hintId,
    });

    this.setState({
      currentPage: PAGE_ID.HINT_CONFIG,
      currentHintId: hintId,
      hintConfig,
    });
  }

  onEditBaseConfig() {
    this.setState({
      currentPage: PAGE_ID.BASE_CONFIG,
    });
  }

  onBackToOverview() {
    // Clear some stuff!
    this.setState({
      currentPage: PAGE_ID.OVERVIEW,
      currentHintId: null,
      hintConfig: null,
    });
  }

  async onRemoveHint(currentHintId) {
    if (this.state.isSaving) {
      return;
    }

    this.setState({ isSaving: true, saveErrMsg: null });

    const { userHints, unitId } = this.state;

    let finalUserHints = [];
    _.forEach(userHints, (userHint) => {
      const hintId = getHintTypeFromUserHint(userHint);
      if (hintId !== currentHintId) {
        finalUserHints.push(userHint);
      }
    });

    try {
      const resp = await updateAutoPilotUserHints({
        unitId,
        userHints: finalUserHints,
      });
      console.log(resp);

      notify.show("Hint removed! Automatically reloading page...", "success");

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log("uopdae failedddd!!!");
      console.log(err);
      notify.show("Failed to save", "error");

      console.log("Error saving user hints", err);
      this.setState({
        saveErrMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isSaving: false });
  }

  render() {
    const {
      currentPage,

      config,
      defaultConfig,
      userHints,
      userHintsForUI,
      availableHints,
      currentHintId,
      hintConfig,

      networkInfo,
      unitInfo,
      updatedAt,
      unitId,

      isSaving,
      saveErrMsg,
    } = this.state;

    if (this.state.isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (this.state.errMsg) {
      return <div className="text-red-600">{this.state.errMsg}</div>;
    }

    if (config) {
      return (
        <>
          <AutoPilotViewHeader
            networkInfo={networkInfo}
            unitInfo={unitInfo}
            onBackToOverview={this.onBackToOverview}
            currentPage={currentPage}
            currentHintId={currentHintId}
          ></AutoPilotViewHeader>

          <AutoPilotViewBody
            unitId={unitId}
            currentPage={currentPage}
            updatedAt={updatedAt}
            config={config}
            defaultConfig={defaultConfig}
            userHints={userHints}
            userHintsForUI={userHintsForUI}
            availableHints={availableHints}
            hintConfig={hintConfig}
            currentHintId={currentHintId}
            onAddHint={this.onAddHint}
            onEditHintConfig={this.onEditHintConfig}
            onEditBaseConfig={this.onEditBaseConfig}
            onRemoveHint={this.onRemoveHint}
            isSaving={isSaving}
            saveErrMsg={saveErrMsg}
          ></AutoPilotViewBody>
        </>
      );
    }

    return "Config does not exist";
  }
}

class AutoPilotViewBody extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSavingCommand: false,
    };

    this.handleCommandForceIterate = this.handleCommandForceIterate.bind(this);
  }

  async handleCommandForceIterate() {
    if (this.state.isSavingCommand) {
      return;
    }

    this.setState({ isSavingCommand: true });

    const { unitId } = this.props;
    try {
      const resp = await forceIterateAutoPilotUnit({
        unitId,
      });
      console.log(resp);

      notify.show("Force Iterate command received!", "success");
    } catch (err) {
      console.log("command failedddd!!!");
      console.log(err);
      notify.show("Failed to command", "error");

      console.log("Error commanding force iterate", err);
      this.setState({
        saveErrMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isSavingCommand: false });
  }

  render() {
    const {
      unitId,
      updatedAt,
      currentPage,

      config,
      defaultConfig,

      userHints,
      userHintsForUI,
      availableHints,
      hintConfig,
      currentHintId,

      onAddHint,
      onEditHintConfig,
      onEditBaseConfig,
      onRemoveHint,

      isSaving,
      saveErrMsg,
    } = this.props;

    switch (currentPage) {
      case PAGE_ID.OVERVIEW: {
        return (
          <div className="flex mt-4">
            <div className="w-2/3 pl-4 pr-6">
              <APCOverviewPage
                unitId={unitId}
                config={config}
                defaultConfig={defaultConfig}
                userHints={userHints}
                userHintsForUI={userHintsForUI}
                availableHints={availableHints}
                onAddHint={onAddHint}
                onEditHintConfig={onEditHintConfig}
                onEditBaseConfig={onEditBaseConfig}
                onRemoveHint={onRemoveHint}
                isSaving={isSaving}
                saveErrMsg={saveErrMsg}
              ></APCOverviewPage>
            </div>

            <div className="w-1/3">
              {updatedAt && (
                <div className="text-gray-600 text-sm">
                  Last update time:
                  <br />
                  <DateTimeFormatter datetime={updatedAt}></DateTimeFormatter>
                </div>
              )}

              <div className="mt-8">
                <div className="text-gray-600 font-semibold border-b">
                  COMMANDS:
                </div>
                <div className="p-4 border-b">
                  <div className="text-gray-600">
                    Force Auto Pilot config to iterate ASAP.
                  </div>
                  <button
                    type="button"
                    disabled={this.state.isSavingCommand}
                    className={`rounded shadow px-4 py-2 text-gray-800
                    ${
                      this.state.isSavingCommand
                        ? "bg-gray-100 cursor-not-allowed"
                        : "bg-white hover:bg-gray-100"
                    }`}
                    onClick={this.handleCommandForceIterate}
                  >
                    {this.state.isSavingCommand
                      ? "Commanding..."
                      : "Force Iterate"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }

      case PAGE_ID.BASE_CONFIG: {
        return (
          <UnitAPConfigViewer
            unitId={unitId}
            config={config}
            defaultConfig={defaultConfig}
            updatedAt={updatedAt}
          ></UnitAPConfigViewer>
        );
      }

      case PAGE_ID.HINT_CONFIG: {
        // Important! hint config should be resolved by combining unit config with default config
        let defaultHintConfig = resolveHintDefaultConfig({
          unitConfig: config,
          defaultConfig,
        });

        return (
          <UnitHintAPConfigViewer
            currentPage={currentPage}
            unitId={unitId}
            hintConfig={hintConfig}
            defaultHintConfig={defaultHintConfig}
            currentHintId={currentHintId}
            userHints={userHints}
            // config={config}
            // defaultConfig={defaultConfig}
            updatedAt={updatedAt}
          ></UnitHintAPConfigViewer>
        );
      }

      default: {
        // nothing
        return "404";
      }
    }
  }
}

class APCOverviewPage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmModalOpened: false,
      confirmingHint: null,
    };

    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.handleCloseConfirmModal = this.handleCloseConfirmModal.bind(this);
  }

  openConfirmModal(hint) {
    this.setState({
      isConfirmModalOpened: true,
      confirmingHint: hint,
    });
  }

  handleCloseConfirmModal() {
    this.setState({
      isConfirmModalOpened: false,
      confirmingHint: null,
    });
  }

  render() {
    const {
      isSaving,
      saveErrMsg,

      userHints,
      userHintsForUI,
      availableHints,

      onAddHint,
      onEditHintConfig,
      onEditBaseConfig,
      onRemoveHint,
    } = this.props;

    return (
      <>
        <div>
          <div className="bg-gray-300 border border-gray-400 rounded w-full px-8 py-6 flex items-center justify-between">
            <div className="text-blue-900 font-bold uppercase">Base Config</div>
            <button
              type="button"
              className="rounded shadow px-4 py-2 bg-white hover:bg-gray-200 text-gray-800"
              onClick={onEditBaseConfig}
            >
              Edit
            </button>
          </div>
        </div>

        {userHints.length > 0 ? (
          <>
            <div className="text-gray-600 font-semibold text-lg mt-8 mb-2">
              Current user hints:
            </div>
            {userHintsForUI.map((hint) => {
              return (
                <div
                  key={hint.id}
                  className="bg-gray-300 border border-gray-400 rounded w-full px-8 py-4 flex items-center justify-between mb-2"
                >
                  <div className="text-blue-900 font-bold uppercase">
                    {hint.name}
                  </div>

                  <div>
                    <button
                      type="button"
                      className="rounded shadow px-4 py-2 bg-red-300 hover:bg-red-400 text-red-900 mr-4"
                      onClick={() => this.openConfirmModal(hint)}
                    >
                      Remove this hint
                    </button>

                    <button
                      type="button"
                      className="rounded shadow px-4 py-2 bg-white hover:bg-gray-100 text-gray-800"
                      onClick={() => onEditHintConfig(hint.id)}
                    >
                      Edit
                    </button>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          ""
        )}

        {availableHints.length > 0 && (
          <>
            <div className="text-gray-600 font-semibold text-lg mt-8 mb-2">
              Add new user hints:
            </div>
            {availableHints.map((hint) => {
              return (
                <div
                  key={hint.id}
                  className="bg-gray-100 border border-gray-300 rounded w-full px-8 py-4 flex items-center justify-between mb-2"
                >
                  <div className="text-gray-900 font-bold uppercase">
                    {hint.name}
                  </div>
                  <button
                    type="button"
                    className="rounded shadow px-4 py-2 bg-white hover:bg-gray-200 text-gray-800"
                    onClick={() => onAddHint(hint.id)}
                  >
                    Add
                  </button>
                </div>
              );
            })}
          </>
        )}

        {this.state.confirmingHint && (
          <ModalWrapper
            isOpen={this.state.isConfirmModalOpened}
            showCloseFooter={false}
            handleClose={this.handleCloseConfirmModal}
            width="60%"
          >
            <div>
              <div className="font-bold text-xl text-gray-700 mb-2">
                Are you sure you want to remove user hint:{" "}
                {HINT_NAMES[this.state.confirmingHint.id]}?
              </div>

              <div
                className="overflow-y-auto border"
                style={{ maxHeight: "320px" }}
              >
                <pre>
                  {JSON.stringify(this.state.confirmingHint.config, null, 2)}
                </pre>
              </div>

              <div className="flex flex-row-reverse mt-4 items-center">
                <div>
                  <button
                    type="button"
                    className="px-4 py-2 bg-red-500 rounded shadow hover:bg-red-700 text-white font-semibold"
                    onClick={() => onRemoveHint(this.state.confirmingHint.id)}
                  >
                    {isSaving ? "Removing..." : "Yes, remove this hint"}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={isSaving}
                    className={`px-4 py-2 text-blue-700 ${
                      isSaving ? "cursor-not-allowed" : ""
                    }`}
                    onClick={this.handleCloseConfirmModal}
                  >
                    Cancel
                  </button>
                </div>
                <div className="text-red-600">{saveErrMsg}</div>
              </div>
            </div>
          </ModalWrapper>
        )}
      </>
    );
  }
}

export default AutoPilotViewerForUnitWithHints;
