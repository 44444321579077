import React from "react";
import _ from "lodash";
import AdUnitBasicCard from "./AdUnitBasicCard";
import ReactModal from "react-modal";
import { FiSearch } from "react-icons/fi";
import NetworkStatusIndicator from "../common/NetworkStatusIndicator";
import { FiTrendingUp } from "react-icons/fi";
import NavigateToOtherPages from "./NavigateToOtherPages";

class QuickNavigateHeader extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      isShowingPopover: false,
      isModalOpen: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleGoToBizOverview = this.handleGoToBizOverview.bind(this);
    this.handleGoToBatchUpdateTool = this.handleGoToBatchUpdateTool.bind(this);
    this.handleGoToNetworkReportingStatus =
      this.handleGoToNetworkReportingStatus.bind(this);
  }

  handleGoToBatchUpdateTool() {
    window.open(
      `${window.location.origin}/#/tools/batch-update-auto-pilot-config`
    );
  }

  handleGoToNetworkReportingStatus() {
    window.open(`${window.location.origin}/#/healthcheck/reporting-status`);
  }

  handleGoToBizOverview() {
    // window.open(`${window.location.origin}/#/biz/overview`);
    window.open(`${window.location.origin}/#/business/revenue`);
  }

  handleOpenModal() {
    const header = document.getElementById("snapshot-header");
    header.classList.remove("sticky");
    this.setState({
      isModalOpen: true,
    });
  }

  handleCloseModal() {
    const header = document.getElementById("snapshot-header");
    header.classList.add("sticky");
    this.setState({
      isModalOpen: false,
    });
  }

  render() {
    return (
      <>
        <div className="z-10 py-2 bg-blue-900">
          <div className="flex w-full items-center justify-between text-gray-100">
            <div className="mx-2">
              <button
                type="button"
                className="flex items-center text-sm px-4 text-gray-300 border border-blue-600 shadow bg-blue-900 hover:bg-blue-800 rounded"
                onClick={this.handleGoToBizOverview}
              >
                <div className="mr-2">
                  <FiTrendingUp></FiTrendingUp>
                </div>
                Intowow Revenue
              </button>
            </div>

            <button
              type="button"
              className="flex items-center justify-center text-sm text-gray-400 rounded border border-blue-600 w-1/3 text-center hover:shadow hover:border-blue-400 hover:text-gray-200"
              onClick={this.handleOpenModal}
            >
              <div className="mr-2">
                <FiSearch></FiSearch>
              </div>
              <div>Quick Search Unit</div>
            </button>

            <div className="mx-2 flex">
              {/* <button
                type="button"
                className="flex mx-2 items-center text-sm px-4 text-gray-300 border border-blue-600 shadow bg-blue-900 hover:bg-blue-800 rounded"
                onClick={this.handleGoToNetworkReportingStatus}
              >
                <div className="mr-2">
                  <IoIosPulse></IoIosPulse>
                </div>
                Reporting Status
              </button> */}
              {/* 
              <button
                type="button"
                className="flex items-center text-sm px-4 text-gray-300 border border-blue-600 shadow bg-blue-900 hover:bg-blue-800 rounded"
                onClick={this.handleGoToBatchUpdateTool}
              >
                <div className="mr-2">
                  <FaTools></FaTools>
                </div>
                Batch Update
              </button> */}

              {/* Other Tools */}
              <NavigateToOtherPages></NavigateToOtherPages>
            </div>
          </div>
        </div>

        <SearchUnitModal
          isOpen={this.state.isModalOpen}
          showCloseFooter={false}
          handleClose={this.handleCloseModal}
        >
          <QuickNavigatePopover data={this.props.data}></QuickNavigatePopover>
        </SearchUnitModal>
      </>
    );
  }
}

class QuickNavigatePopover extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: "",
      searchResults: null,
      isSearching: false,

      searchableData: _transformData(props.data),
      filteredData: [],
      paginatedData: [],
      hasMoreData: false,
      isSearchByExtGamUnitId: false,
      selectedFilterNetworkId: null,
    };

    this.clearInput = this.clearInput.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.toggleIsSearchByExtGamUnitId =
      this.toggleIsSearchByExtGamUnitId.bind(this);
    this.showMore = this.showMore.bind(this);
    this.filterNetwork = this.filterNetwork.bind(this);
  }

  componentDidMount() {
    this.filterUnits = _.debounce(this.filterUnits, 300);
  }

  handleOnChange(event) {
    event.persist();

    this.setState({
      inputValue: event.target.value,
      isSearching: true,
      filteredData: [],
      paginatedData: [],
      hasMoreData: false,
      selectedFilterNetworkId: null,
    });
    this.filterUnits(event.target.value);
  }

  clearInput() {
    this.setState({
      inputValue: "",
      paginatedData: [],
      hasMoreData: false,
      selectedFilterNetworkId: null,
    });
    this.filterUnits("");
  }

  filterUnits(keyword) {
    // console.log("filter by...", keyword, keyword.length);
    if (!keyword) {
      // console.log("heyy");
      this.setState({
        filteredData: [],
      });
      return;
    }
    let filteredData = [];
    const { searchableData } = this.state;

    let filteredUnits = _searchUnitByInput(
      searchableData,
      keyword,
      this.state.isSearchByExtGamUnitId
    );
    // exact matched should be up front
    filteredData = _.reduce(
      _.groupBy(filteredUnits, "gamNetworkId"),
      (result, units) => {
        const { gamNetworkId, networkName, networkStatus } = units[0];
        const r = {
          gamNetworkId,
          networkName,
          networkStatus,
          units: units,
          hasExactMatch: _.some(units, "isExactMatch"),
        };
        result.push(r);
        return result;
      },
      []
    );
    filteredData = _.sortBy(filteredData, (d) => {
      return !d.hasExactMatch || d.networkStatus;
    });

    // Paginate data to optimize performance
    const paginatedData = _paginateUnits(filteredData);
    const totalFilteredUnits = _.sumBy(filteredData, (d) => d.units.length);
    const totalPaginatedUnits = _.sumBy(paginatedData, (d) => d.units.length);

    this.setState({
      filteredData,
      paginatedData,
      hasMoreData: totalFilteredUnits > totalPaginatedUnits,
      isSearching: false,
    });
  }

  showMore() {
    this.setState({
      paginatedData: this.state.filteredData,
    });
  }

  toggleIsSearchByExtGamUnitId() {
    this.setState({
      isSearchByExtGamUnitId: !this.state.isSearchByExtGamUnitId,
      isSearching: true,
      filteredData: [],
      paginatedData: [],
      hasMoreData: false,
      selectedFilterNetworkId: null,
    });
    this.filterUnits(this.state.inputValue);
  }

  filterNetwork(gamNetworkId) {
    if (this.state.selectedFilterNetworkId === gamNetworkId) {
      this.filterUnits(this.state.inputValue);
      this.setState({
        selectedFilterNetworkId: null,
        // paginatedData: [],
        hasMoreData: false,
      });
      return;
    }

    // get all data of this network from filteredData
    const filteredNetworkData = _.find(this.state.filteredData, {
      gamNetworkId,
    });
    this.setState({
      selectedFilterNetworkId: gamNetworkId,
      paginatedData: [filteredNetworkData],
      hasMoreData: false,
    });
  }

  render() {
    const unitsMatched = _.reduce(
      this.state.filteredData,
      (sum, d) => (sum += d.units.length),
      0
    );
    return (
      <div>
        <div className="flex p-4">
          <input
            type="text"
            className={`w-full h-8 rounded 
              py-1 px-4 leading-normal appearance-none
              border
              focus:border-blue-400
              focus:outline-none focus:shadow-inner 
            `}
            placeholder="Search gam unit id, unit name, ext gam unit id"
            onChange={this.handleOnChange}
            value={this.state.inputValue}
            autoFocus={true}
          ></input>

          {this.state.inputValue && (
            <button
              type="button"
              className="ml-4 text-blue-600 hover:text-blue-800"
              onClick={this.clearInput}
            >
              Clear
            </button>
          )}
        </div>

        {this.state.inputValue && (
          <>
            {this.state.isSearching ? (
              <div className="py-4 text-sm px-4 -mt-4">Searching...</div>
            ) : (
              <>
                <div className="pt-4 text-sm px-4 -mt-4 flex justify-between">
                  <div>
                    Overall <b>{unitsMatched}</b> units matching "
                    <b>{this.state.inputValue}</b>"
                  </div>
                  <div>
                    <button
                      type="button"
                      className="text-blue-600 hover:text-blue-800"
                      onClick={this.toggleIsSearchByExtGamUnitId}
                    >
                      {this.state.isSearchByExtGamUnitId ? (
                        <span>
                          Search by <i>Unit Name</i> and <i>Gam Unit ID</i>
                        </span>
                      ) : (
                        <span>
                          Search by <i>Ext Gam Unit ID</i>
                        </span>
                      )}
                    </button>
                  </div>
                </div>

                <div
                  className="px-4 py-2 border-b-2 border-gray-400"
                  style={{ maxHeight: "80px", overflowY: "auto" }}
                >
                  {this.state.filteredData.map((d) => {
                    return (
                      <div
                        key={d.gamNetworkId}
                        className={`px-2 mr-1 text-xs text-gray-600 inline-block 
                        border rounded cursor-pointer 
                        ${
                          this.state.selectedFilterNetworkId === d.gamNetworkId
                            ? "border-blue-400 text-blue-600 hover:border-blue-200"
                            : "hover:border-blue-400"
                        }`}
                        onClick={() => this.filterNetwork(d.gamNetworkId)}
                      >
                        {d.networkName} ({d.units.length})
                      </div>
                    );
                  })}
                </div>
              </>
            )}
          </>
        )}

        <div
          id="quick-search-result-body"
          style={{ maxHeight: "400px", overflowY: "auto" }}
          className="bg-gray-300"
        >
          <>
            {this.state.filteredData && this.state.paginatedData && (
              <>
                {this.state.paginatedData.map((d) => {
                  return (
                    <div id={`anchor-${d.gamNetworkId}`} key={d.gamNetworkId}>
                      <div className="flex items-center px-4 pt-8 bg-white text-gray-800">
                        <div className="pr-2">
                          <NetworkStatusIndicator
                            status={d.networkStatus}
                          ></NetworkStatusIndicator>
                        </div>
                        <div className="text-xl font-bold">
                          {d.gamNetworkId} {d.networkName}{" "}
                          <span className="font-normal text-lg">
                            ({d.units.length})
                          </span>
                        </div>
                      </div>

                      <div className="p-4">
                        {d.units.map((unit) => {
                          return (
                            <div
                              key={`${unit.gamUnitId}_${unit.gamNetworkId}_${unit.extGamUnitId}`}
                              className="py-4"
                            >
                              <AdUnitBasicCard
                                unit={unit.originalUnitObject}
                                networkName={unit.networkName}
                                type={unit.type}
                                showFullWidthCard={true}
                                isCompactMode={true}
                              ></AdUnitBasicCard>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </>
          <>
            {this.state.hasMoreData && (
              <div className="flex items-center pb-4">
                <button
                  type="button"
                  className="px-8 text-sm rounded mx-auto bg-blue-200 py-2 text-blue-800 font-bold hover:shadow hover:text-blue-600"
                  onClick={this.showMore}
                >
                  Show All
                </button>
              </div>
            )}
          </>
        </div>
      </div>
    );
  }
}

class SearchUnitModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleCloseModal() {
    // Important! make background scrollable again
    document.body.style.overflow = "auto";
    this.props.handleClose();
  }

  render() {
    const { isOpen } = this.props;

    return (
      isOpen && (
        <ReactModal
          isOpen={isOpen}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
            content: {
              left: "20%",
              right: "20%",
              bottom: "none",
              padding: 0,
            },
          }}
          shouldCloseOnOverlayClick={true}
          // shouldFocusAfterRender
          onAfterOpen={() => {
            // to prevent background scrolling
            document.body.style.overflow = "hidden";
          }}
          // if allow esc or overlay click hide modal
          onRequestClose={() => {
            document.body.style.overflow = "auto";
            this.handleCloseModal();
          }}
        >
          {this.props.children}

          {this.props.showCloseFooter && (
            <div className="flex justify-end py-1 border-t px-6 -mx-6 -mb-4">
              <button
                type="button"
                className="flex-shrink-0 font-semibold border-transparent border-4 text-blue-500 hover:text-blue-800 text-sm py-1 px-2 rounded"
                onClick={this.handleCloseModal}
              >
                Close
              </button>
            </div>
          )}
        </ReactModal>
      )
    );
  }
}

const kpiConstants = {
  REVENUE_DRIVERS: "REVENUE_DRIVERS",
  SUSPENDED_UNITS: "SUSPENDED_UNITS",
  PAUSED_UNITS: "PAUSED_UNITS",
  NOT_ONBOARDED_UNITS: "NOT_ONBOARDED_UNITS",
};

// Transform snapshot raw data into searchable data schema
function _transformData(data) {
  const searchableData = _.reduce(
    data,
    (result, network) => {
      let units = [
        ..._transformUnits(network.hero_units, kpiConstants.REVENUE_DRIVERS),
        ..._transformUnits(
          network.suspended_units,
          kpiConstants.SUSPENDED_UNITS
        ),
        ..._transformUnits(network.paused_units, kpiConstants.PAUSED_UNITS),
        ..._transformUnits(
          network.not_onboarded_units,
          kpiConstants.NOT_ONBOARDED_UNITS
        ),
      ];
      units = _.uniqBy(units, (u) => {
        return `${u.gam_network_id}_${u.gam_unit_id}_${u.ext_gam_unit_id}`;
      });

      _.forEach(units, (unit) => {
        result.push({
          gamNetworkId: network.gam_network_id,
          networkName: network.name,
          networkStatus: network.status,
          gamUnitId: unit.gam_unit_id,
          extGamUnitId: unit.ext_gam_unit_id,
          unitName: unit.name,
          originalUnitObject: unit,
          type: unit.type,
        });
      });
      return result;
    },
    []
  );
  return searchableData;
  // gam_network_id
  // name
  // suspended_units
  // paused_units
  // not_onboarded_units
  // hero_units
  // &
  // properties needed for AdUnitBasicCard
  // -- unit props --
  // gam_unit_id
  // ext_gam_unit_id
  // name
}

function _transformUnits(units, type) {
  return _.map(units, (u) => {
    u.type = type;
    return u;
  });
}

export function _searchUnitByInput(
  data,
  inputValue,
  isSearchByExtGamUnitId = false
) {
  const filterKeys = isSearchByExtGamUnitId
    ? ["extGamUnitId"]
    : ["gamUnitId", "unitName"];
  // Find exact match first!
  const data1 = _.map(data, (d) => {
    d.isMatch = false;
    d.isExactMatch = false;

    _.forEach(filterKeys, (fKey) => {
      if (d[fKey] && _.toLower(d[fKey]) === _.toLower(inputValue)) {
        d.isExactMatch = true;
        d.isMatch = true;
        return false;
      }

      if (d[fKey] && _.toLower(d[fKey]).includes(_.toLower(inputValue))) {
        d.isMatch = true;
      }
    });

    return d;
  });
  let fData = _.concat(
    _.remove(data1, { isExactMatch: true }),
    _.remove(data1, { isMatch: true })
  );

  return fData;
}

function _paginateUnits(filteredData) {
  const limit = 10;
  let countdown = limit;
  let paginatedData = [];

  _.forEach(filteredData, (d) => {
    let paginatedUnits = [];
    _.forEach(d.units, (unit) => {
      countdown--;
      paginatedUnits.push(unit);

      if (countdown === 0) {
        return false;
      }
    });
    paginatedData.push({ ...d, units: paginatedUnits });

    if (countdown === 0) {
      return false;
    }
  });

  return paginatedData;
}

export default QuickNavigateHeader;
