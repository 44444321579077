import React from "react";
import _ from "lodash";
import NumberFormat from "../common/NumberFormat";
// import ReactTooltip from "react-tooltip";

class AdUnitList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedUnit: null
    };

    this.handleUnitSelect = this.handleUnitSelect.bind(this);
  }

  componentDidMount() {
    const { network } = this.props;
    const units = _.get(network, "data.revenue_drivers");
    const topUnit = _.first(_.orderBy(units, ["revenue_percentage"], ["desc"]));

    this.setState({ selectedUnit: topUnit });
  }

  handleUnitSelect(unit) {
    if (this.state.selectedUnit && unit.id === this.state.selectedUnit.id) {
      return;
    }
    this.setState({ selectedUnit: unit });
  }

  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { date, network, handleBackToNetworkList } = this.props;
    let units = _.get(network, "data.revenue_drivers");
    units = _.orderBy(units, ["revenue_percentage"], ["desc"]);

    return (
      <>
        <div className="flex">
          <AdUnitListSideBar
            date={date}
            network={network}
            units={units}
            selectedUnitId={_.get(this.state, "selectedUnit.id")}
            handleUnitSelect={this.handleUnitSelect}
            handleBackToNetworkList={handleBackToNetworkList}
          ></AdUnitListSideBar>
          {this.state.selectedUnit && (
            <AdUnitSection unit={this.state.selectedUnit}></AdUnitSection>
          )}
        </div>
      </>
    );
  }
}

class AdUnitListSideBar extends React.Component {
  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const {
      date,
      network,
      units,
      handleUnitSelect,
      handleBackToNetworkList,
      selectedUnitId
    } = this.props;

    return (
      <div
        className="xl:w-1/6 w-1/5 top-0 left-0 h-screen overflow-y-auto sticky"
        style={{ backgroundColor: "#294469" }}
      >
        <div className="p-2 bg-blue-800 text-white top-0 sticky shadow-md">
          <button
            type="button"
            className="w-full bg-blue-700 rounded shadow py-1 text-gray-100 mb-1 mt-2"
            onClick={handleBackToNetworkList}
          >
            {"<"} Back
          </button>
          <div className="py-3">
            <div>{network.gam_network_id}</div>
            <div className="font-bold tracking-wide text-lg">
              {network.name}
            </div>
          </div>
          <div>
            <span className="mr-4">{network.timezone}</span>
            <span className="">{network.currency}</span>
          </div>
          <div className="text-gray-200 text-sm my-1">{date}</div>
        </div>

        <div className="text-gray-300 font-semibold text-sm text-center p-2 -mb-2 uppercase">
          {units.length} units
        </div>
        <div className="px-2">
          {units.map(unit => {
            return (
              <div
                key={unit.id}
                className={`text-sm p-2 my-2 ${
                  selectedUnitId === unit.id
                    ? "bg-white text-gray-900 border-8 border-teal-400"
                    : // ? "bg-gray-600 text-white shadow-lg border-2 border-gray-400"
                      "bg-white text-gray-900 cursor-pointer border-4 hover:shadow-md hover:border-4 hover:border-teal-400"
                }`}
                onClick={() => handleUnitSelect(unit)}
              >
                <div
                  className={`flex justify-between -ml-2 -mr-2 -mt-2 p-2 ${
                    selectedUnitId === unit.id
                      ? "bg-gray-100 text-teal-900"
                      : // ? "bg-gray-700 text-gray-100"
                        "bg-gray-100 text-gray-700"
                  }`}
                >
                  <div className="w-1/5">
                    <div className="font-mono mr-2">{unit.id}</div>
                  </div>
                  <div className="w-3/5">
                    <div className="font-bold truncate">{unit.name}</div>
                  </div>
                  <div className="flex text-sm w-1/5">
                    <div className="px-1">
                      <UnitStatus unit={unit}></UnitStatus>
                    </div>
                    <div className="px-1">
                      <UnitMode unit={unit}></UnitMode>
                    </div>
                  </div>
                </div>

                <div className="flex justify-between content-end mt-3">
                  <div className="font-bold w-1/3">
                    <div className="text-xs font-light leading-none">
                      Rev Ratio
                    </div>
                    <div className="text-lg">
                      <NumberFormat
                        value={unit.revenue_percentage * 100}
                        postfix="%"
                      ></NumberFormat>
                    </div>
                  </div>

                  <div className="w-2/3 flex text-right text-xs font-mono">
                    <div className="w-1/2">
                      <div className="text-gray-500">Gross </div>
                      <div className="text-gray-500">Net </div>
                    </div>
                    <div className="w-1/2">
                      <div
                        className={
                          unit.gross_revenue_lift < 0 ? "text-red-400" : ""
                        }
                      >
                        <NumberFormat
                          value={unit.gross_revenue_lift * 100}
                          postfix="%"
                        ></NumberFormat>
                      </div>
                      <div
                        className={
                          unit.net_revenue_lift < 0 ? "text-red-400" : ""
                        }
                      >
                        <NumberFormat
                          value={unit.net_revenue_lift * 100}
                          postfix="%"
                        ></NumberFormat>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="h-12"></div>
      </div>
    );
  }
}

class UnitStatus extends React.Component {
  render() {
    const { unit } = this.props;

    switch (unit.status) {
      case "Running": {
        return (
          <span role="img" aria-label={unit.status}>
            ▶️
          </span>
        );
      }
      case "Paused": {
        return (
          <span role="img" aria-label={unit.status}>
            ⏸
          </span>
        );
      }
      default: {
        return null;
      }
    }
  }
}

class UnitMode extends React.Component {
  render() {
    const { unit } = this.props;

    switch (unit.mode) {
      case "Boosting": {
        return (
          <span role="img" aria-label={unit.mode}>
            🚀
          </span>
        );
      }
      case "Partially Boosting": {
        return (
          <span role="img" aria-label={unit.mode}>
            🐌
          </span>
        );
      }
      case "Suspended": {
        return (
          <span role="img" aria-label={unit.mode}>
            💤
          </span>
        );
      }
      default: {
        return null;
      }
    }
  }
}

// class TooltipWrapper extends React.Component {
//   shouldComponentUpdate() {
//     return false;
//   }

//   render() {
//     const { tooltipKey, message } = this.props;
//     return (
//       <>
//         <span data-tip data-for={tooltipKey}>
//           {this.props.children}
//         </span>

//         <ReactTooltip id={tooltipKey} type="dark" effect="solid">
//           {message}
//         </ReactTooltip>
//       </>
//     );
//   }
// }

class AdUnitSection extends React.Component {
  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { unit } = this.props;

    return (
      <div className="xl:w-5/6 w-4/5" style={{ backgroundColor: "#f9f9f9" }}>
        <div className="rounded shadow text-gray-900">
          <div className="flex justify-between items-center px-4 py-3 sticky top-0 shadow border-b border-gray-500 bg-gray-200">
            <div className="flex">
              <div className="pr-4 mr-4 text-center border-r border-gray-400">
                <div className="text-blue-700 text-xs">Raw Revenue Ratio</div>
                <div className="text-2xl font-bold">
                  <NumberFormat
                    value={unit.revenue_percentage * 100}
                    postfix="%"
                  ></NumberFormat>
                </div>
              </div>

              <div>
                <div className="font-black text-lg">
                  <span className="text-gray-700 mr-2 font-mono font-normal">
                    {unit.id}
                  </span>{" "}
                  {unit.name}
                </div>
                <div className="text-xs font-bold my-2 -mb-1">
                  <span className="mr-4">
                    <UnitStatus unit={unit}></UnitStatus>
                    <span className="ml-2">{unit.status}</span>
                  </span>
                  <UnitMode unit={unit}></UnitMode> {unit.mode}
                </div>
              </div>
            </div>
            <div className="flex items-center text-sm">
              <button
                type="button"
                onClick={() => window.open(unit.observer_url)}
                className="font-bold py-2 px-4 rounded bg-blue-600 shadow text-sm hover:bg-blue-700 text-white"
              >
                See in Observer
              </button>
            </div>
          </div>

          <div className="p-4">
            <div className="flex items-center mb-4">
              {/* <RevenueRatioCard unit={unit}></RevenueRatioCard> */}
              <div className="mr-2">
                <IncreasedRevenueCard unit={unit}></IncreasedRevenueCard>
              </div>
              <div className="flex-grow">
                <HealthCheckCard unit={unit}></HealthCheckCard>
              </div>
            </div>

            <div className="mb-4">
              <PricingRulesCard unit={unit}></PricingRulesCard>
            </div>

            <div className="mb-4">
              <RequestSizesCard unit={unit}></RequestSizesCard>
            </div>

            <div className="mb-4">
              <GroupComparisonCard unit={unit}></GroupComparisonCard>
            </div>

            <div className="mb-4">
              <DemandChannelCard unit={unit}></DemandChannelCard>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class PricingRulesCard extends React.Component {
  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { unit } = this.props;
    const pr = _.orderBy(_.get(unit, "pricing_rules"), ["ratio"], ["desc"]);

    return (
      <>
        <div className="py-2 font-bold text-gray-700">Pricing Rules</div>
        <table className="w-full table text-sm border shadow">
          <thead className="bg-gray-200 text-blue-800 uppercase text-xs border-b">
            <tr>
              <th className="w-1/6 text-right py-2 px-4">Ratio</th>
              <th className="w-1/6 text-right py-2 px-4">Impression</th>
              <th className="w-1/2 text-left py-2 px-4">Rule</th>
            </tr>
          </thead>
          <tbody className="text-gray-900 bg-white">
            {pr.map((r, i) => {
              return (
                <tr key={i} className="border-b hover:bg-gray-100">
                  <td className="text-right py-2 px-4 font-bold">
                    {r.ratio ? (
                      <NumberFormat
                        value={r.ratio * 100}
                        postfix="%"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right py-2 px-4">
                    {r.impression ? (
                      <NumberFormat
                        value={r.impression}
                        format="0,0"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-left py-2 px-4 font-semibold text-blue-900">
                    {r.rule}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

class RequestSizesCard extends React.Component {
  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { unit } = this.props;
    const rs = _.orderBy(_.get(unit, "requested_sizes"), ["ratio"], ["desc"]);

    return (
      <>
        <div className="py-2 font-bold text-gray-700">Request Sizes</div>
        <table className="w-full table text-sm border shadow">
          <thead className="bg-gray-200 text-blue-800 uppercase text-xs border-b">
            <tr>
              <th className="w-1/6 text-right py-2 px-4">Ratio</th>
              <th className="w-1/6 text-right py-2 px-4">Request</th>
              <th className="w-1/2 text-left py-2 px-4">Sizes</th>
            </tr>
          </thead>
          <tbody className="text-gray-900 bg-white">
            {rs.map((r, i) => {
              return (
                <tr key={i} className="border-b hover:bg-gray-100">
                  <td className="text-right py-2 px-4 font-bold">
                    {r.ratio ? (
                      <NumberFormat
                        value={r.ratio * 100}
                        postfix="%"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right py-2 px-4">
                    {r.request ? (
                      <NumberFormat
                        value={r.request}
                        format="0,0"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-left py-2 px-4 font-semibold text-blue-900">
                    {r.sizes}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

class DemandChannelCard extends React.Component {
  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { unit } = this.props;
    const dc = _.get(unit, "demand_channel");
    const types = [
      // "Sponsorship",
      // "Standard",

      "Ad Exchange",
      "EBDA",
      "SmartPostBid",
      // "Network",
      // "Bulk",
      "Price Priority",
      // "House",

      "AdSense",

      "Standard",
      "Preferred Deal"
    ];

    const dd = _.get(unit, "demand_distribution");

    const benchmark = _.get(dd, "benchmark");
    const optimization = _.get(dd, "optimization");

    return (
      <>
        <div className="py-2 font-bold text-gray-700">Demand Distribution</div>
        <table className="table w-full border text-sm shadow">
          <thead className="bg-gray-200 text-blue-800 uppercase text-xs">
            <tr>
              <th rowSpan="2" className="text-right p-2 border">
                Demand
              </th>
              <th
                colSpan="2"
                className="text-center p-2 border-b bg-gray-300 border-gray-200 text-indigo-700"
              >
                Total
              </th>
              <th colSpan="2" className="text-center p-2 border">
                Benchmark
              </th>
              <th colSpan="2" className="text-center p-2 border">
                Optimization
              </th>
            </tr>
            <tr>
              <th className="text-right p-2 border-r bg-gray-300 border-gray-200 text-indigo-700">
                Rev Lift
              </th>
              <th className="text-right p-2 border-l bg-gray-300 border-gray-200 text-indigo-700">
                Rev Ratio
              </th>
              <th className="text-right p-2 border">eCPM</th>
              <th className="text-right p-2 border">Imp Ratio</th>
              <th className="text-right p-2 border">eCPM</th>
              <th className="text-right p-2 border">Imp Ratio</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {types.map(type => {
              const lift = _.get(_.find(dc, { type: type }), "revenue_lift");
              const ratio = _.get(_.find(dc, { type: type }), "revenue_ratio");

              const b = _.find(benchmark, {
                type: type === "EBDA" ? "Exchange Bidding" : type
              });
              const bEcpm = _.get(b, "ecpm");
              const bImpRatio = _.get(b, "impression_ratio");

              const o = _.find(optimization, {
                type: type === "EBDA" ? "Exchange Bidding" : type
              });
              const oEcpm = _.get(o, "ecpm");
              const oImpRatio = _.get(o, "impression_ratio");

              return (
                <tr key={type} className="border-b hover:bg-gray-100">
                  <td className="text-right p-2 border">{type}</td>
                  <td className="text-right p-2 border font-bold border-r-0">
                    {lift ? (
                      <NumberFormat
                        value={lift * 100}
                        postfix="%"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right p-2 border font-bold border-l-0">
                    {ratio ? (
                      <NumberFormat
                        value={ratio * 100}
                        postfix="%"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right p-2 border border-r-0">
                    {bEcpm ? (
                      <NumberFormat value={bEcpm} prefix="$"></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right p-2 border border-l-0">
                    {bImpRatio ? (
                      <NumberFormat
                        value={bImpRatio * 100}
                        postfix="%"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right p-2 border border-r-0">
                    {oEcpm ? (
                      <NumberFormat value={oEcpm} prefix="$"></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className="text-right p-2 border border-l-0">
                    {oImpRatio ? (
                      <NumberFormat
                        value={oImpRatio * 100}
                        postfix="%"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

class HealthCheckCard extends React.Component {
  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { unit } = this.props;
    const hc = _.get(unit, "healthcheck");
    const checks = [
      {
        title: "Manage Score",
        value: hc.eligible_ratio
      },
      {
        title: "Rev Managed",
        value: hc.revenue_managed_ratio
      },
      {
        title: "Imp Managed",
        value: hc.impression_managed_ratio
      },
      {
        title: "Layer Loss",
        value: hc.layer_loss_ratio
      }
    ];
    return (
      <>
        <div className="py-2 font-bold text-gray-700">Health Check</div>
        <div className="bg-blue-100 p-2 rounded text-center flex shadow">
          {checks.map((check, i) => {
            return (
              <div key={i} className="flex-grow">
                <div className="text-blue-700 text-xs font-semibold">
                  {check.title}
                </div>
                <div className="text-xl font-semibold text-blue-900">
                  <NumberFormat
                    value={check.value * 100}
                    postfix="%"
                  ></NumberFormat>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  }
}

class GroupComparisonCard extends React.Component {
  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { unit } = this.props;
    const gc = _.get(unit, "group_comparison");
    const unmanaged = _.get(gc, "unmanaged");
    const benchmark = _.get(gc, "benchmark");
    const total = _.get(gc, "total");

    return (
      <>
        <div className="py-2 font-bold text-gray-700">Group Comparison</div>
        <table className="table w-full text-sm border shadow">
          <thead className="bg-gray-200 text-blue-800 uppercase text-xs border-b">
            <tr>
              <th className="text-right p-2 ">Group</th>
              <th className="text-right p-2 ">eCPM</th>
              <th className="text-right p-2 ">STR</th>
              <th className="text-right p-2 ">RPM</th>
              <th className="text-right p-2 ">CPM</th>
              <th className="text-right p-2 ">Traffic Ratio</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr className="border-b hover:bg-gray-100">
              <td className="text-right p-2 ">Unmanaged</td>
              <td className="text-right p-2 ">
                <NumberFormat value={unmanaged.ecpm} prefix="$"></NumberFormat>
              </td>
              <td className="text-right p-2 ">-</td>
              <td className="text-right p-2 ">-</td>
              <td className="text-right p-2 ">-</td>
              <td className="text-right p-2 ">
                <NumberFormat
                  value={unmanaged.ratio * 100}
                  postfix="%"
                ></NumberFormat>
              </td>
            </tr>
            <tr className="border-b hover:bg-gray-100">
              <td className="text-right p-2">Benchmark</td>
              <td className="text-right p-2">
                <NumberFormat value={benchmark.ecpm} prefix="$"></NumberFormat>
              </td>
              <td className="text-right p-2">
                <NumberFormat
                  value={benchmark.st_rate * 100}
                  postfix="%"
                ></NumberFormat>
              </td>
              <td className="text-right p-2">
                <NumberFormat value={benchmark.rpm}></NumberFormat>
              </td>
              <td className="text-right p-2">
                <NumberFormat value={benchmark.cpm} prefix="$"></NumberFormat>
              </td>
              <td className="text-right p-2">
                <NumberFormat
                  value={benchmark.ratio * 100}
                  postfix="%"
                ></NumberFormat>
              </td>
            </tr>
            <tr className="font-bold hover:bg-gray-100">
              <td className="text-right p-2">Total</td>
              <td className="text-right p-2">
                <NumberFormat value={total.ecpm} prefix="$"></NumberFormat>
              </td>
              <td className="text-right p-2">
                <NumberFormat
                  value={total.st_rate * 100}
                  postfix="%"
                ></NumberFormat>
              </td>
              <td className="text-right p-2">
                <NumberFormat value={total.rpm}></NumberFormat>
              </td>
              <td className="text-right p-2">
                <NumberFormat value={total.cpm} prefix="$"></NumberFormat>
              </td>
              <td className="text-right p-2">
                <NumberFormat
                  value={total.ratio * 100}
                  postfix="%"
                ></NumberFormat>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    );
  }
}

class IncreasedRevenueCard extends React.Component {
  // shouldComponentUpdate() {
  //   return false;
  // }

  render() {
    const { unit } = this.props;

    return (
      <>
        <div className="flex">
          <div>
            <div className="py-2 font-bold text-gray-700 text-center">
              <span className="font-normal">Gross Lift </span>
              <span
                className={`font-black ${
                  unit.gross_revenue_lift < 0
                    ? "text-red-600"
                    : "text-green-600"
                }`}
              >
                <NumberFormat
                  value={unit.gross_revenue_lift * 100}
                  postfix="%"
                ></NumberFormat>
              </span>
            </div>

            <div className="bg-gray-200 py-2 px-8 rounded text-center mr-2 shadow">
              <div className="text-blue-700 text-xs font-semibold">
                Gross Increased Revenue
              </div>
              <div className="text-xl font-semibold">
                <NumberFormat
                  value={unit.gross_increased_revenue}
                  prefix="$"
                ></NumberFormat>
                {/* <div className="text-gray-800 font-bold text-base ml-2">
              <span className="font-normal">Lift </span>
              <span className="font-bold">
                <NumberFormat
                  value={unit.gross_revenue_lift * 100}
                  postfix="%"
                ></NumberFormat>
              </span>
            </div> */}
              </div>
            </div>
          </div>
          <div>
            <div className="py-2 font-bold text-gray-700 text-center">
              <span className="font-normal">Net Lift </span>
              <span
                className={`font-black ${
                  unit.net_revenue_lift < 0 ? "text-red-600" : "text-green-600"
                }`}
              >
                <NumberFormat
                  value={unit.net_revenue_lift * 100}
                  postfix="%"
                ></NumberFormat>
              </span>
            </div>

            <div className="bg-gray-200 py-2 px-8 rounded text-center shadow">
              <div className="text-blue-700 text-xs font-semibold">
                Net Increased Revenue
              </div>
              <div className="text-xl font-semibold">
                <NumberFormat
                  value={unit.net_increased_revenue}
                  prefix="$"
                ></NumberFormat>
                {/* <div className="text-gray-800 text-base ml-2">
              <span className="font-normal">Lift </span>
              <span className="font-bold">
                <NumberFormat
                  value={unit.net_revenue_lift * 100}
                  postfix="%"
                ></NumberFormat>
              </span>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AdUnitList;
