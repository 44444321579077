import React from "react";

class MetricView extends React.PureComponent {
  render() {
    const {
      mTitle,
      mValue,
      prefix,
      postfix,
      whitespaceNormal,
      shouldFormatNumber
    } = this.props;

    // format number
    function _f(n, d = 2) {
      return n.toLocaleString(undefined, { maximumFractionDigits: d });
    }

    return (
      <>
        <span
          className={`${
            whitespaceNormal ? "whitespace-normal" : "whitespace-no-wrap"
          } pl-2`}
        >
          {mTitle ? <span className="text-gray-600">{mTitle}: </span> : null}

          {prefix ? prefix : null}
          {shouldFormatNumber ? (
            <span>{_f(mValue)}</span>
          ) : (
            <span>{mValue}</span>
          )}
          {postfix ? postfix : null}
        </span>
      </>
    );
  }
}

export default MetricView;
