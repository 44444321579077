import React from "react";
import _ from "lodash";
import { getAutoPilotConfig, updateAutoPilotConfig } from "../../helpers/Api";
import AutoPilotViewHeader from "./auto-pilot/AutoPilotViewHeader";
import AutoPilotConfigControl from "./auto-pilot/AutoPilotConfigControl";
import ModalWrapper from "../common/ModalWrapper";
import { notify } from "react-notify-toast";
import LoadingUI from "../common/LoadingUI";
import Checkbox from "../common/Checkbox";

class ControlCenterAutoPilotViewer extends React.Component {
  constructor(props) {
    super(props);

    const unitId = _.get(props, "match.params.unitId");
    const networkId = _.get(props, "match.params.networkId");

    this.state = {
      unitId,
      networkId,

      isLoading: false,
      errMsg: null,

      config: null,
      defaultConfig: null,
      updatedAt: null,

      networkInfo: null,
      unitInfo: null,

      isReviewModalOpened: false,
      finalConfig: null,

      // A checkbox in preview modal to control whether autopilot should iterate immediately
      // only open up to unit level for now
      shouldForceIterate: false,
      // A checkbox in preview modal to control whether autopilot should force resegmentation
      // only open up to unit level for now
      shouldForceResegment: false,

      isSaving: false,
      saveErrMsg: null,
    };

    this.handleSaveConfig = this.handleSaveConfig.bind(this);
    this.handleConfirmSaveConfig = this.handleConfirmSaveConfig.bind(this);
    this.handleOpenReviewModal = this.handleOpenReviewModal.bind(this);
    this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this);
    this.toggleShouldForceIterate = this.toggleShouldForceIterate.bind(this);
    this.toggleShouldForceResegment =
      this.toggleShouldForceResegment.bind(this);
  }

  async componentDidMount() {
    const { unitId, networkId } = this.state;

    this.setState({ isLoading: true });

    try {
      if (unitId) {
        document.title = `${unitId} Unit Auto Pilot Config | YB Control Center`;
        const { config, defaultConfig, unitInfo, networkInfo, updatedAt } =
          await getAutoPilotConfig({
            unitId,
          });
        this.setState({
          config,
          defaultConfig,
          unitInfo,
          networkInfo,
          updatedAt,
        });
      } else if (networkId) {
        document.title = `${networkId} Network Auto Pilot Config | YB Control Center`;
        const { config, defaultConfig, networkInfo, updatedAt } =
          await getAutoPilotConfig({
            networkId,
          });
        this.setState({ config, defaultConfig, networkInfo, updatedAt });
      } else {
        document.title = "404 | YB Control Center";
      }
    } catch (err) {
      console.log("Error querying auto pilot config", err);
      this.setState({ errMsg: typeof err === "object" ? err.toString() : err });
    }

    this.setState({ isLoading: false });
  }

  handleOpenReviewModal() {
    this.setState({
      isReviewModalOpened: true,
    });
  }

  handleCloseReviewModal() {
    this.setState({
      isReviewModalOpened: false,
      finalConfig: null,
      saveErrMsg: null,
    });
  }

  handleSaveConfig({ config }) {
    // Show review modal first!
    // save when confirmed
    const { unitId, networkId } = this.state;
    console.log("Save config", config, networkId, unitId);

    let finalConfig = _.cloneDeep(config);
    // Important! special case! Added extra outer layer of array
    if (_.has(finalConfig, "prophet.benchmark_abuse_sizes")) {
      const bas = finalConfig.prophet.benchmark_abuse_sizes;
      finalConfig.prophet.benchmark_abuse_sizes = [bas];
    }

    this.setState({ finalConfig });

    this.handleOpenReviewModal();
  }

  async handleConfirmSaveConfig() {
    if (this.state.isSaving) {
      return;
    }

    const { unitId, networkId, finalConfig } = this.state;

    this.setState({ isSaving: true, saveErrMsg: null });

    try {
      const resp = await updateAutoPilotConfig({
        unitId,
        networkId,
        config: finalConfig,
        commandOptions: {
          shouldForceIterate: this.state.shouldForceIterate,
          shouldForceResegment: this.state.shouldForceResegment,
        },
      });

      console.log("updated!!!");
      console.log(resp);
      notify.show("Config saved! Automatically reloading page...", "success");

      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
    } catch (err) {
      console.log("uopdae failedddd!!!");
      console.log(err);
      notify.show("Failed to save", "error");

      console.log("Error saving auto pilot config", err);
      this.setState({
        saveErrMsg: typeof err === "object" ? err.toString() : err,
      });
    }

    this.setState({ isSaving: false });
  }

  toggleShouldForceIterate(shouldForceIterate) {
    console.log("toggle shouldForceIterate", shouldForceIterate);

    this.setState({
      shouldForceIterate,
    });
  }

  toggleShouldForceResegment(shouldForceResegment) {
    console.log("toggle shouldForceResegment", shouldForceResegment);

    this.setState({
      shouldForceResegment,
    });
  }

  render() {
    const {
      config,
      defaultConfig,
      networkInfo,
      unitInfo,
      updatedAt,
      shouldForceIterate,
      shouldForceResegment,
      unitId,
    } = this.state;

    if (this.state.isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (this.state.errMsg) {
      return <div className="text-red-600">{this.state.errMsg}</div>;
    }

    if (config) {
      return (
        <>
          <AutoPilotViewHeader
            networkInfo={networkInfo}
            unitInfo={unitInfo}
          ></AutoPilotViewHeader>

          <AutoPilotConfigControl
            isUnitConfig={!!unitId}
            config={config}
            defaultConfig={defaultConfig}
            handleSaveConfig={this.handleSaveConfig}
            updatedAt={updatedAt}
          ></AutoPilotConfigControl>

          <ModalWrapper
            isOpen={this.state.isReviewModalOpened}
            showCloseFooter={false}
            handleClose={this.handleCloseReviewModal}
            width="60%"
          >
            <div>
              <div className="font-bold text-xl text-gray-700 mb-2">
                Preview Config
                {/* Review Auto Pilot Config Changes */}
              </div>

              <div
                className="overflow-y-auto border"
                style={{ maxHeight: "320px" }}
              >
                <pre>{JSON.stringify(this.state.finalConfig, null, 2)}</pre>
              </div>

              {this.state.unitId && (
                <div className="leading-none mt-4 bg-orange-200 py-3 px-2 rounded">
                  <div className="text-sm text-orange-800 mb-2">
                    If there are urgent changes in the config that needs to be
                    affected immediately, check the box below:
                  </div>
                  <label
                    className={
                      this.state.isSaving
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }
                  >
                    <Checkbox
                      isChecked={shouldForceIterate}
                      isDisabled={this.state.isSaving}
                      onChange={this.toggleShouldForceIterate}
                    ></Checkbox>

                    <span className="ml-2 align-middle text-gray-700 hover:text-gray-800 font-semibold text-sm">
                      Force AutoPilot to iterate ASAP
                    </span>
                  </label>
                </div>
              )}

              {this.state.unitId && (
                <div className="leading-none mt-4 bg-red-200 py-3 px-2 rounded">
                  <div className="text-sm text-red-800 mb-2">
                    To force resegmentation{" "}
                    <i>(beware of performance implications)</i>:
                  </div>
                  <label
                    className={
                      this.state.isSaving
                        ? "cursor-not-allowed"
                        : "cursor-pointer"
                    }
                  >
                    <Checkbox
                      isChecked={shouldForceResegment}
                      isDisabled={this.state.isSaving}
                      onChange={this.toggleShouldForceResegment}
                    ></Checkbox>

                    <span className="ml-2 align-middle text-gray-700 hover:text-gray-800 font-semibold text-sm">
                      Force resegment
                    </span>
                  </label>
                </div>
              )}

              <div className="flex flex-row-reverse mt-4 items-center">
                <div>
                  <button
                    type="button"
                    className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                    onClick={this.handleConfirmSaveConfig}
                  >
                    {this.state.isSaving ? "Saving..." : "Confirm save"}
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={this.state.isSaving}
                    className={`px-4 py-2 text-blue-700 ${
                      this.state.isSaving ? "cursor-not-allowed" : ""
                    }`}
                    onClick={this.handleCloseReviewModal}
                  >
                    Cancel
                  </button>
                </div>
                <div className="text-red-600">{this.state.saveErrMsg}</div>
              </div>
            </div>
          </ModalWrapper>
        </>
      );
    }

    return "";
  }
}

export default ControlCenterAutoPilotViewer;
