import React from "react";

class BasicDataTable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.renderHeadingRow = this.renderHeadingRow.bind(this);
    this.renderRow = this.renderRow.bind(this);
  }

  renderHeadingRow(_cell, cellIndex) {
    const { headings } = this.props;

    return (
      <Cell
        key={`heading-${cellIndex}`}
        content={headings[cellIndex]}
        header={true}
      ></Cell>
    );
  }

  renderRow(_row, rowIndex) {
    const { rows } = this.props;

    return (
      <tr key={`row-${rowIndex}`}>
        {rows[rowIndex].map((_cell, cellIndex) => {
          return (
            <Cell
              key={`cell-${rowIndex}-${cellIndex}`}
              content={rows[rowIndex][cellIndex]}
            ></Cell>
          );
        })}
      </tr>
    );
  }

  render() {
    const { headings, rows } = this.props;

    const theadMarkup = <tr>{headings.map(this.renderHeadingRow)}</tr>;

    const tbodyMarkup = rows.map(this.renderRow);

    return (
      <table className="table text-sm border shadow">
        <thead className="bg-gray-200 text-blue-800 text-xs border-b">
          {theadMarkup}
        </thead>
        <tbody className="text-gray-900 font-mono bg-white">
          {tbodyMarkup}
        </tbody>
      </table>
    );
  }
}

class Cell extends React.PureComponent {
  render() {
    const { content, header } = this.props;

    const cellMarkup = header ? (
      <th className="border text-right py-1 px-4">{content}</th>
    ) : (
      <td className="text-right py-1 px-4 border whitespace-no-wrap">
        {content}
      </td>
    );

    return cellMarkup;
  }
}

export default BasicDataTable;
