import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { FiInfo } from "react-icons/fi";
import {
  mergeBmSchemaWithConfig,
  mergeDpSchemaWithConfig,
  mergeOptSchemaWithConfig
} from "../../../constants/ExperimentConfig";

class ConfigCard extends React.PureComponent {
  render() {
    const { config } = this.props;

    return (
      config && (
        <>
          <div className="px-2 text-sm rounded bg-white shadow">
            <div className="pb-1">
              <div className="uppercase py-1 text-gray-600 font-semibold text-sm bg-gray-200 -mx-2 px-2 rounded">
                Benchmark
              </div>

              <BmConfigView unitConfig={config}></BmConfigView>
            </div>

            <div className="pb-1">
              <div className="uppercase py-1 text-gray-600 font-semibold text-sm bg-gray-200 -mx-2 px-2">
                Dispatcher
              </div>

              <DpConfigView unitConfig={config}></DpConfigView>
            </div>

            <div className="pb-1">
              <div className="uppercase py-1 text-gray-600 font-semibold text-sm bg-gray-200 -mx-2 px-2">
                Optimization
              </div>

              <OptConfigView unitConfig={config}></OptConfigView>
            </div>
          </div>
        </>
      )
    );
  }
}

// class ConfigSectionView extends React.PureComponent {

// }

class OptConfigView extends React.PureComponent {
  render() {
    const { unitConfig } = this.props;

    const optConfigWithSchema = mergeOptSchemaWithConfig({
      unitConfig
    });

    const configs = _.groupBy(optConfigWithSchema, s => {
      return "show";
      // TODO: some config might not be important so no need to show at first
      // return s.alwaysShow ? "show" : "hide";
    });

    return (
      <>
        {configs["show"].map(s => {
          return <ConfigItem key={s.key} setting={s}></ConfigItem>;
        })}

        {configs["hide"] && configs["hide"].length > 0 && (
          <div>Show {configs["hide"].length} more...</div>
        )}
      </>
    );
  }
}

class DpConfigView extends React.PureComponent {
  render() {
    const { unitConfig } = this.props;

    const dpConfigWithSchema = mergeDpSchemaWithConfig({
      unitConfig
    });

    const configs = _.groupBy(dpConfigWithSchema, s => {
      return "show";
      // TODO: some config might not be important so no need to show at first
      // return s.alwaysShow ? "show" : "hide";
    });

    return (
      <>
        {configs["show"].map(s => {
          return <ConfigItem key={s.key} setting={s}></ConfigItem>;
        })}

        {configs["hide"] && configs["hide"].length > 0 && (
          <div>Show {configs["hide"].length} more...</div>
        )}
      </>
    );
  }
}

class BmConfigView extends React.PureComponent {
  render() {
    const { unitConfig } = this.props;

    const bmConfigWithSchema = mergeBmSchemaWithConfig({
      unitConfig
    });

    const configs = _.groupBy(bmConfigWithSchema, s => {
      return "show";
      // TODO: some config might not be important so no need to show at first
      // return s.alwaysShow ? "show" : "hide";
    });

    return (
      <>
        {configs["show"].map(s => {
          return <ConfigItem key={s.key} setting={s}></ConfigItem>;
        })}

        {configs["hide"] && configs["hide"].length > 0 && (
          <div>Show {configs["hide"].length} more...</div>
        )}
      </>
    );
  }
}

class ConfigItem extends React.PureComponent {
  renderItem(setting) {
    const uiDefaultValue =
      typeof setting.defaultValue === "boolean"
        ? setting.defaultValue.toString()
        : setting.defaultValue;

    if (setting.alwaysShow) {
      return (
        <div key={setting.key} className="py-2">
          <div
            className="font-semibold text-blue-700 flex items-center"
            // className={`font-semibold text-blue-700 flex items-center justify-between ${
            //   setting.isArray
            //     ? `${setting.key === "customGroups" &&
            //         // ? `bg-indigo-300 ${setting.key === "customGroups" &&
            //         " sticky top-0"}`
            //     : ""
            // }`}
          >
            {setting.key} {setting.isArray && `(${setting.values.length})`}
            <span
              className="ml-1 text-xs text-gray-500"
              data-tip
              data-for={`config-key-${setting.key}`}
            >
              <FiInfo></FiInfo>
            </span>
          </div>

          {setting.isArray ? (
            <div
              className={`${
                setting.key === "customGroups" ? "" : "p-1 border rounded m-1"
              }`}
            >
              {setting.values.map((v, k) => {
                return (
                  <div
                    key={k}
                    className={`${
                      setting.key === "customGroups"
                        ? // ? "p-1 -m-1 mb-2 shadow-md"
                          "border border-gray-300 rounded p-2 mb-4 shadow-md"
                        : ""
                    }`}
                  >
                    {setting.key === "customGroups" && (
                      <div className="bg-indigo-200 text-blue-700 -mx-2 px-2 -mt-2 py-1 rounded-t">
                        customGroup
                      </div>
                    )}
                    {v.map(vv => {
                      return this.renderItem(vv);
                    })}
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              className={`font-bold ${
                setting.hasValue ? "text-gray-800" : "text-gray-600"
              }`}
            >
              <span data-tip data-for={`config-is-diff-${setting.key}`}>
                {setting.hasValue && setting.isDiff && (
                  <div className="h-1 w-1 mr-1 rounded bg-orange-500 inline-block align-middle"></div>
                )}
                {setting.uiValue}
              </span>
            </div>
          )}

          <ReactTooltip
            id={`config-key-${setting.key}`}
            type="dark"
            effect="solid"
          >
            {setting.description}
          </ReactTooltip>

          {setting.hasValue && setting.isDiff && uiDefaultValue && (
            <ReactTooltip
              id={`config-is-diff-${setting.key}`}
              type="dark"
              effect="solid"
            >
              Default: {uiDefaultValue}
            </ReactTooltip>
          )}
        </div>
      );
    } else {
      return "";
    }
  }

  render() {
    const { setting } = this.props;
    if (setting) {
      return this.renderItem(setting);
    }
    return "";
  }
}

export default ConfigCard;
