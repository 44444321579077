import React from "react";
import _ from "lodash";
import Select from "react-select";

import { getYieldSets } from "../../../helpers/Api";

class YieldSetsSelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const queryParams = {
      networkId: this.props.networkId,
    };
    const items = await getYieldSets(queryParams);
    console.log(items);

    let options = _.map(items, (r) => {
      return {
        value: r.yieldSetId,
        label: r.yieldSetId + " - " + r.name,
      };
    });

    this.setState({
      items,
      options,
    });
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.networkId === this.props.networkId) {
      return;
    }

    if (prevProps.selectedYieldSet !== this.props.selectedYieldSet) {
      if (this.props.selectedYieldSet === null) {
        this.setState({
          items: [],
          options: [],
          selectedValue: null,
        });
      }
    }

    const queryParams = {
      networkId: this.props.networkId,
    };
    const items = await getYieldSets(queryParams);
    console.log(items);

    let options = _.map(items, (r) => {
      return {
        value: r.yieldSetId,
        label: r.yieldSetId + " - " + r.name,
      };
    });

    this.setState({
      items,
      options,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    const item = _.find(this.state.items, { yieldSetId: option.value });
    this.props.handleOnChange(item);
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
        />
      </>
    );
  }
}

export default YieldSetsSelector;
