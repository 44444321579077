import React from "react";
import { Switch, Route, Link } from "react-router-dom";
import Publishers from "./Publishers";
import Networks from "./Networks";
import UpdatePublisherTrialStatus from "./UpdatePublisherTrialStatus";
import OnboardAdUnits from "./OnboardAdUnits";
import CreateReseller from "./CreateReseller";
import CreatePublisher from "./CreatePublisher";
import UpdateNetworkAdServingCost from "./UpdateNetworkAdServingCost";

class AccountViewer extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <div>
        <div className="bg-blue-900 px-2">
          <div className="text-blue-100 text-sm font-bold">
            Account Services:
            <span className="mx-2 text-blue-100">
              <Link to="/ops-mgmt/account/publishers">Publishers</Link>
            </span>
            <span className="mx-2 text-blue-100">
              <Link to="/ops-mgmt/account/networks">Networks</Link>
            </span>
            <span className="mx-2 text-blue-100">
              <Link to="/ops-mgmt/account/onboard-ad-units">
                Batch Onboard Ad Units
              </Link>
            </span>
          </div>
        </div>

        <>
          <Switch>
            <Route
              path="/ops-mgmt/account/publishers"
              component={Publishers}
            ></Route>

            <Route
              path="/ops-mgmt/account/networks"
              component={Networks}
            ></Route>

            <Route
              path="/ops-mgmt/account/network/:networkId/update-ad-serving-cost"
              component={UpdateNetworkAdServingCost}
            ></Route>

            <Route
              path="/ops-mgmt/account/publisher/:pubId/update-trial-status"
              component={UpdatePublisherTrialStatus}
            ></Route>

            <Route
              path="/ops-mgmt/account/onboard-ad-units"
              component={OnboardAdUnits}
            ></Route>

            <Route
              path="/ops-mgmt/account/create-reseller"
              component={CreateReseller}
            ></Route>

            <Route
              path="/ops-mgmt/account/create-publisher"
              component={CreatePublisher}
            ></Route>
          </Switch>
        </>
      </div>
    );
  }
}

export default AccountViewer;
