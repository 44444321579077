import _ from "lodash";

export function transformUnitInsightReports(resultData) {
  const { reports, props, optimizationSettings } = resultData;

  const sizesArray = props.onboardedSizes && props.onboardedSizes.split(",");

  resultData.props = {
    ...props,
    onboardedSizesArray: _.map(sizesArray, (size) => {
      const s = size.split("x");
      return {
        w: s[0],
        h: s[1],
      };
    }),
  };

  resultData.segments = _.map(optimizationSettings.segments, (segment) => {
    return _.omit(segment, "config");
  });

  // transform reports to json format
  resultData.reports = _.mapValues(reports, ({ columns, rows }) => {
    return _.reduce(
      rows,
      (result, row) => {
        let r = {};
        _.forEach(columns, (column, index) => {
          r[column] = row[index];
        });

        result.push(r);
        return result;
      },
      []
    );
  });

  // Sort reports
  // console.log(resultData.reports);
  resultData.reports.rpms = _.sortBy(resultData.reports.rpms, "date");
  resultData.reports.iedReports = _.sortBy(
    resultData.reports.iedReports,
    "date"
  );

  const demandTypePrefixes = [
    "sponsorship",
    "standard",
    "preferredDeal",
    "adx",
    "ads",
    "openBidding",
    "eb",
    "pricePriority",
    "bulk",
    "network",
    "mediation",
    "house",
  ];
  const eligibleDemandTypes = ["adx", "openBidding", "eb", "ads"];
  resultData.reports.benchmarkValidation = _.map(
    resultData.reports.benchmarkValidation,
    (r) => {
      r.groupType = transformGroupTypeName(r.groupType);

      r.str = r.request > 0 ? r.impression / r.request : 0;
      r.ecpm = r.impression > 0 ? (r.revenue * 1000) / r.impression : 0;
      r.rpm = r.request > 0 ? (r.revenue * 1000) / r.request : 0;

      // Calculate Eligible Metrics
      // Eligible: Adx, AdSense, OpenBidding, SmartBidding
      r.eligibleImpression = _.sumBy(eligibleDemandTypes, (type) => {
        return r[`${type}Impression`];
      });
      r.eligibleStr = r.request > 0 ? r.eligibleImpression / r.request : 0;
      r.eligibleRevenue = _.sumBy(eligibleDemandTypes, (type) => {
        return r[`${type}Revenue`];
      });
      r.eligibleEcpm =
        r.eligibleImpression > 0
          ? (r.eligibleRevenue * 1000) / r.eligibleImpression
          : 0;
      r.eligibleRpm =
        r.request > 0 ? (r.eligibleRevenue * 1000) / r.request : 0;

      r.adxViewability =
        r.adxMeasurableImpression > 0
          ? r.adxViewableImpression / r.adxMeasurableImpression
          : 0;

      // { $demandType: $report }
      r.demandTypes = _.reduce(
        _.keys(r),
        (result, key) => {
          if (r[key] > 0) {
            _.forEach(demandTypePrefixes, (demandType) => {
              switch (key) {
                case `${demandType}Impression`: {
                  const name = getDemandTypeName(demandType);
                  _.set(result, [name, "impression"], r[key]);
                  break;
                }

                case `${demandType}Revenue`: {
                  const name = getDemandTypeName(demandType);
                  _.set(result, [name, "revenue"], r[key]);
                  break;
                }

                default: {
                  // nothing
                }
              }
            });
          }

          return result;
        },
        {}
      );
      const totalImp = _.sumBy(_.toArray(r.demandTypes), "impression");
      r.demandTypes = _.mapValues(r.demandTypes, (dtReport) => {
        dtReport.ecpm =
          dtReport.impression > 0
            ? (dtReport.revenue * 1000) / dtReport.impression
            : 0;
        dtReport.str = r.request > 0 ? dtReport.impression / r.request : 0;
        dtReport.rpm =
          r.request > 0 ? (dtReport.revenue * 1000) / r.request : 0;
        dtReport.impressionRatio = _.round(dtReport.impression / totalImp, 2);

        return dtReport;
      });

      return r;
    }
  );

  resultData.reports.inventory = _.map(resultData.reports.inventory, (r) => {
    r.requestType = transformRequestTypeName(r.requestType);
    r.str = r.request > 0 ? r.impression / r.request : 0;

    return r;
  });

  resultData.reports.uprValidation = _.map(
    resultData.reports.uprValidation,
    (r) => {
      r.impressionRatio = _.round(
        r.intowowRuleAdxImpression / r.optimizationAdxImpression,
        2
      );

      return r;
    }
  );

  return resultData;
}

function transformGroupTypeName(groupType) {
  switch (groupType) {
    case "(not applicable)": {
      return "unmanaged";
    }

    case "benchmark": {
      return groupType;
    }

    default: {
      return groupType;
    }
  }
}

function transformRequestTypeName(requestType) {
  switch (requestType) {
    case "Google Publisher Tag": {
      return "GPT";
    }

    case "AMP Ad Tag": {
      return "AMP";
    }

    default: {
      return requestType;
    }
  }
}

function getDemandTypeName(demandType) {
  return _(demandType).snakeCase().split("_").map(_.capitalize).join(" ");
}
