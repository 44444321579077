import React from "react";
import { Switch, Route, NavLink } from "react-router-dom";
import IntowowRevenueViewer from "./revenue/IntowowRevenueViewer";
import IntowowBillingViewer from "./billing/IntowowBillingViewer";

const PATHS = {
  revenue: "/business/revenue",
  billing: "/business/billing",
};

class IntowowBusinessViewer extends React.Component {
  componentDidMount() {
    document.body.style.backgroundColor = "#fcfcfc";
  }

  render() {
    const activeTabClass =
      "py-2 px-6 mr-2 bg-gray-200 text-gray-900 rounded-t-lg border-b-4 border-gray-200";
    const inactiveTabClass =
      "py-2 px-6 mr-2 rounded-t-lg text-gray-500 bg-white hover:bg-gray-100";

    return (
      <div>
        <div className="bg-white px-12">
          <h1 className="font-extrabold text-gray-900 text-4xl pt-8 pb-4">
            Intowow Business Dashboard
          </h1>

          <div className="flex">
            <NavLink
              key={"Revenue"}
              to={PATHS.revenue}
              className={inactiveTabClass}
              // activeClassName="border-b border-blue-500"
              activeClassName={activeTabClass}
            >
              Revenue
            </NavLink>
            <NavLink
              key={"Billing"}
              to={PATHS.billing}
              className={inactiveTabClass}
              // activeClassName="border-b border-blue-500"
              activeClassName={activeTabClass}
            >
              Billing
            </NavLink>
          </div>
        </div>

        {/* <NavLink
                  key={tab.name}
                  to={tab.path}
                  className={inactiveTabClass}
                  // activeClassName="border-b border-blue-500"
                  activeClassName={activeTabClass}
                >
                  {tab.name}
                </NavLink> */}

        <>
          <Switch>
            <Route
              path={PATHS.billing}
              component={IntowowBillingViewer}
            ></Route>
          </Switch>

          <Switch>
            <Route
              path={PATHS.revenue}
              component={IntowowRevenueViewer}
            ></Route>
          </Switch>
        </>
      </div>
    );
  }
}

export default IntowowBusinessViewer;
