import React from "react";
// import _ from "lodash";
import moment from "moment-timezone";
import { FaArrowRight } from "react-icons/fa";
import MetricTooltip from "../../common/MetricTooltip";
import NumberFormat from "../../common/NumberFormat";
import FadeInWrapper from "./FadeInWrapper";
import InfoDescriptionTooltip from "./InfoDescriptionTooltip";
import InventoryScoreGaugeChart from "./InventoryScoreGaugeChart";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

class YbMockupHomeInventorySection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { inventoryData, selectedYieldSetId, updatedTime } = this.props;
    const isYieldSetFilter = selectedYieldSetId !== -1;

    const ut = moment(updatedTime);
    const updatedTimeStr = `${ut.format("YYYY/MM/DD HH:mm")} (${ut.fromNow()})`;

    return (
      <div style={fixedWidthStyle}>
        <div className="flex justify-between items-center mb-4">
          <div className="text-2xl font-bold">Inventory</div>

          <div className="text-gray-600 text-xs">
            Updated at: {updatedTimeStr}
          </div>
        </div>

        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-6">
            <div className="flex justify-around p-4 border rounded shadow">
              <div className="flex flex-col items-center">
                <div className="font-semibold text-gray-700 text-sm flex items-center">
                  Total Units
                  {/* <PopoverForInfoDescription
                    metricKey="TOTAL_UNITS"
                    place="top">
                  </PopoverForInfoDescription> */}
                  <InfoDescriptionTooltip
                    metricKey="TOTAL_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-3xl pb-1">
                  <FadeInWrapper key={inventoryData.totalUnits}>
                    {isYieldSetFilter ? (
                      "-"
                    ) : (
                      <NumberFormat
                        value={inventoryData.totalUnits}
                        format="0,0"
                      ></NumberFormat>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="flex items-center text-gray-600">
                <FaArrowRight></FaArrowRight>
              </div>

              <div className="flex flex-col items-center">
                <div className="font-semibold text-gray-700 text-sm flex items-center">
                  Compatible Units
                  <InfoDescriptionTooltip
                    metricKey="COMPATIBLE_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-3xl pb-1">
                  <FadeInWrapper key={inventoryData.compatibleUnits}>
                    {isYieldSetFilter ? (
                      "-"
                    ) : (
                      <MetricTooltip
                        tooltipKey="compatible-unit-tooltip"
                        tooltipMessage={`Compatible rate: ${inventoryData.compatibleUnitsRate}%`}
                        place="bottom"
                      >
                        <NumberFormat
                          value={inventoryData.compatibleUnits}
                          format="0,0"
                        ></NumberFormat>
                      </MetricTooltip>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="flex items-center text-gray-600">
                <FaArrowRight></FaArrowRight>
              </div>

              <div className="flex flex-col items-center">
                <div className="font-semibold text-gray-700 text-sm flex items-center">
                  Onboarded Units
                  <InfoDescriptionTooltip
                    metricKey="ONBOARDED_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-3xl pb-1">
                  <FadeInWrapper key={inventoryData.onboardedUnits}>
                    {isYieldSetFilter ? (
                      <NumberFormat
                        value={inventoryData.onboardedUnits}
                        format="0,0"
                      ></NumberFormat>
                    ) : (
                      <MetricTooltip
                        tooltipKey="onboard-unit-tooltip"
                        tooltipMessage={`Onboarded rate: ${inventoryData.onboardedUnitsRate}%`}
                        place="bottom"
                      >
                        <NumberFormat
                          value={inventoryData.onboardedUnits}
                          format="0,0"
                        ></NumberFormat>
                      </MetricTooltip>
                    )}
                  </FadeInWrapper>
                </div>
              </div>

              <div className="flex items-center text-gray-600">
                <FaArrowRight></FaArrowRight>
              </div>

              <div className="flex flex-col items-center">
                <div className="font-semibold text-gray-700 text-sm flex items-center">
                  Running Units
                  <InfoDescriptionTooltip
                    metricKey="RUNNING_UNITS"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div className="text-3xl pb-1">
                  <FadeInWrapper key={inventoryData.runningUnits}>
                    <MetricTooltip
                      tooltipKey="running-unit-tooltip"
                      tooltipMessage={`Running rate: ${inventoryData.runningUnitsRate}%`}
                      place="bottom"
                    >
                      <NumberFormat
                        value={inventoryData.runningUnits}
                        format="0,0"
                      ></NumberFormat>
                    </MetricTooltip>
                  </FadeInWrapper>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-2">
            <div className="flex justify-around p-4 border bg-gray-200 rounded shadow">
              <div className="flex flex-col items-center">
                <div className="font-semibold text-gray-700 text-sm flex items-center">
                  Onboarding Traffic Score
                  <InfoDescriptionTooltip
                    metricKey="ONBOARDING_SCORE"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>

                <div>
                  <FadeInWrapper key={inventoryData.onboardedRequests}>
                    {isYieldSetFilter ? (
                      <div className="h-12 flex items-center">-</div>
                    ) : (
                      <InventoryScoreGaugeChart
                        value={inventoryData.onboardingScore}
                      ></InventoryScoreGaugeChart>
                    )}
                  </FadeInWrapper>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-2">
            <div className="flex justify-around p-4 border bg-gray-200 rounded shadow">
              <div className="flex flex-col items-center">
                <div className="font-semibold text-gray-700 text-sm flex items-center">
                  Running Traffic Score
                  <InfoDescriptionTooltip
                    metricKey="RUNNING_SCORE"
                    place="top"
                  ></InfoDescriptionTooltip>
                </div>
                <div>
                  <FadeInWrapper key={inventoryData.runningRequests}>
                    <InventoryScoreGaugeChart
                      value={inventoryData.runningScore}
                    ></InventoryScoreGaugeChart>
                  </FadeInWrapper>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default YbMockupHomeInventorySection;
