import TrendGoogleRevenueDescription from "../../../images/trend-google-rev-description.jpg";

export const MetricDescription = {
  // Inventory Section
  TOTAL_UNITS: {
    title: "Total units",
    desc: "Number of ad units that are currently sending requests to your GAM.",
  },
  COMPATIBLE_UNITS: {
    title: "Compatible units",
    desc: "Number of ad units whose request types are mainly Google Publisher Tag and AMP Ad Tag that are compatible with Yieldbooster.",
  },
  ONBOARDED_UNITS: {
    title: "Onboarded units",
    desc: "Number of ad units that are onboarded to Yieldbooster platform.",
  },
  RUNNING_UNITS: {
    title: "Running units",
    desc: "Number of ad units that are activated for Yieldbooster optimization.",
  },

  ONBOARDING_SCORE: {
    title: "Onboarding traffic score",
    desc: "Score that represents how much total compatible traffic of your GAM Network is actually onboarded to Yieldbooster.",
  },
  RUNNING_SCORE: {
    title: "Running traffic score",
    desc: "Score that represents how much total onboarded traffic is actually activated for Yieldbooster optimization.",
  },

  // Summary Section
  NET_INCREASED_GOOGLE_REV: {
    title: "Net increased revenue",
    desc: "Number of increased Google ad revenue that’s net of extra ad serving cost generated by Yieldbooster.",
  },
  PERCEIVED_REVENUE_UPLIFT: {
    title: "Perceived uplift",
    desc: "The percentage increase in Google revenue from onboarded units contributed by Yieldbooster optimization.",
    desc2:
      "Calculated as: (net increased Google revenue / original Google revenue).",
  },
  MANAGED_UPLIFT: {
    title: "Managed Lift",
    desc: "The percentage increase in request RPM from managed requests contributed by Yieldbooster optimization.",
    desc2:
      "Calculated as: [(managed request RPM - benchmark request RPM) / benchmark request RPM]",
  },

  ONBOARDED_REQ: {
    title: "Onboarded requests",
    desc: "Number of compatible ad requests that are onboarded to Yieldbooster.",
  },
  ELIGIBLE_REQ: {
    title: "Eligible requests",
    desc: "Number of ad requests that are eligible to be optimized by Yieldbooster. Only requests toward which remnant and Google demand can compete are counted as eligible. ",
    desc2: "Not all onboarded requests are eligible.",
  },
  MANAGED_REQ: {
    title: "Managed requests",
    desc: "Number of requests managed by Yieldbooster.",
  },

  REQ_ELIGIBLE_RATE: {
    title: "Request eligible rate",
    desc: "The percentage of onboarded requests that are also eligible requests. ",
    desc2: "Calculated as: (eligible requests / onboarded requests)",
  },
  REQ_MANAGED_RATE: {
    title: "Request managed rate",
    desc: "The percentage of eligible requests that are managed by Yieldbooster.",
    desc2: "Calculated as: (managed requests / eligible requests)",
  },

  YIELD_SET: {
    title: "Yield Set",
    desc: "A yield set is a set of ad units associated with your Google Ad Exchange demand account. All your onboarded ad units are grouped into a default yield set under your Network name.",
    desc2:
      "Yield set can also be used as a customized labeling feature to group your ad units. For example, if you have ad units spanning across multiple websites, you can group them by websites, with each yield set containning all ad units under the same website. This makes tracking Yieldbooster performance of your inventory easier on the dashboard.",
    desc3: "Contact your Solution Engineer for further support.",
  },

  // Trend Section
  TREND_GOOGLE_REVENUE: {
    title: "Google Revenue",
    img: TrendGoogleRevenueDescription,
    metrics: [
      {
        title: "Increased Revenue",
        desc: "Number of Google revenue that Yieldbooster has helped you increase, on top of the original google revenue.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Original Revenue",
        desc: "Number of Google revenue that you would have originally earned from onboarded requests.",
        desc2:
          "Calculated as: (unmanaged Google revenue + base Google revenue)",
        symbolShape: "square", // square/circle/line
        symbolColor: "#2F5BB0", // dark blue
      },
      {
        title: "Not-Onboarded Revenue",
        desc: "Number of Google revenue generated from requests that are not onboarded to Yieldbooster.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C6DBFF", // light blue
      },
      {
        title: "Extra Ad Serving Cost",
        desc: "Number of GAM ad serving cost that Yieldbooster has generated additionally during optimization.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C5C5C5", // light gray
      },
      {
        title: "Total Requests",
        desc: "Number of ad requests that are compatible with Yieldbooster.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3B3B3F", // black
      },
    ],
  },
  TREND_REQUEST_MANAGING_SCORE: {
    title: "Request Managing Score",
    metrics: [
      {
        title: "Managing Score",
        desc: "Score that represents how much of the eligible requests are managed by Yieldbooster.",
        desc2: "Calculated as: (managed requests / eligible requests) * 100.",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3B3B3F", // black
      },
    ],
  },
  TREND_REQUEST_FUNNEL: {
    title: "Request Funnel",
    metrics: [
      {
        title: "Onboarded Requests",
        desc: "Number of compatible ad requests that are onboarded to Yieldbooster.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#C6DBFF", // light blue
      },
      {
        title: "Eligible Requests",
        desc: "Number of ad requests that are eligible to be optimized by Yieldbooster. Only requests toward which remnant and Google demand can compete are counted as eligible. ",
        desc2: "Not all onboarded requests are eligible.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#3C8FFC", // dark blue
      },
      {
        title: "Managed Requests",
        desc: "Number of requests managed by Yieldbooster.",
        symbolShape: "square", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
    ],
  },
  TREND_PERFORMANCE_LIFT: {
    title: "Performance Lift",
    metrics: [
      {
        title: "Managed Lift",
        desc: "The percentage increase in request RPM from managed requests contributed by Yieldbooster optimization.",
        desc2:
          "Calculated as: [(managed request RPM - benchmark request RPM) / benchmark request RPM]",
        symbolShape: "line", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Perceived Lift",
        desc: "The percentage increase in Google revenue from onboarded units contributed by Yieldbooster optimization.",
        desc2:
          "Calculated as: (net increased Google revenue / original Google revenue).",
        symbolShape: "line", // square/circle/line
        symbolColor: "#E44949", // red
      },
    ],
  },
  TREND_MANAGED_GOOGLE_RRPM: {
    title: "Managed Google Request RPM",
    metrics: [
      {
        title: "Managed Request RPM",
        desc: "The Google ad request RPM from managed requests.",
        desc2:
          "Calculated as: [(managed Google revenue * 1000) / managed requests].",
        symbolShape: "line", // square/circle/line
        symbolColor: "#FD9840", // orange
      },
      {
        title: "Benchamrk Request RPM",
        desc: "The Google ad request RPM from a small fraction of managed requests randomly allocated to the benchmark group for reliable simulation of your original traffic earning power in the absence of Yieldbooster.",
        desc2:
          "Calculated as: [(benchmark Google revenue * 1000) / benchmark requests].",
        symbolShape: "line", // square/circle/line
        symbolColor: "#3C8FFC", // dark blue
      },
    ],
  },
};
