import React from "react";
import _ from "lodash";
import Select from "react-select";

import { getGeoCountries } from "../../../helpers/Api";

class GeoCountrySelector extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      options: [],
      selectedValue: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount() {
    const items = await getGeoCountries();
    console.log(items);

    let options = _.map(items, (r) => {
      return {
        value: r.countryId,
        label: `${r.name} (${r.alpha2})`,
      };
    });

    this.setState({
      items,
      options,
    });
  }

  handleChange(option) {
    this.setState({
      selectedValue: option,
    });

    this.props.handleOnChange(option.value);
  }

  render() {
    const { options, selectedValue } = this.state;

    return (
      <>
        <Select
          value={selectedValue}
          onChange={this.handleChange}
          options={options}
        />
      </>
    );
  }
}

export default GeoCountrySelector;
