import React from "react";
import moment from "moment-timezone";

import DatePicker from "react-datepicker";
import { notify } from "react-notify-toast";

import { getPayments, receivePayment } from "../../../helpers/Api";
import Checkbox from "../../common/Checkbox";
import PaymentsTable from "./PaymentsTable";
import _ from "lodash";

class ReceivePaymentView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      isLoading: false,

      paymentId: null,
      tempPayment: null,
      errMsg: "",
    };

    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleInputChanged = this.handleInputChanged.bind(this);

    this.handleReceivePayment = this.handleReceivePayment.bind(this);
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });
    const paymentId = this.props.match.params.paymentId;
    const queryParams = {
      paymentId,
    };
    const payments = await getPayments(queryParams);
    console.log(payments[0]);
    this.setState({
      paymentId,

      tempPayment: {
        ...payments[0],
        collectionDate: new Date(), // default now
        receivedAmt: undefined,
        shouldIssueTWInvoice: false,
        twInvoiceAmt: undefined,
        twInvoiceNotes: `exchange_rate=
others=`,
      },
      isLoading: false,
    });
  }

  async handleReceivePayment(payment) {
    try {
      const {
        paymentId,
        receivedAmt,
        collectionDate,
        shouldIssueTWInvoice,
        twInvoiceAmt,
        twInvoiceNotes,
      } = payment;
      if (!receivedAmt || !collectionDate) {
        throw "Missing 'Received Amount', 'Collection Date'!";
      }

      if (shouldIssueTWInvoice && (!twInvoiceAmt || !twInvoiceNotes)) {
        throw "Missing 'TW Receive Amount', 'Notes'!";
      }

      let params = {
        paymentId,
        receivedAmt: receivedAmt,
        collectionDate,
        shouldIssueTWInvoice,
      };
      if (shouldIssueTWInvoice) {
        params.twInvoiceAmt = twInvoiceAmt;
        if (twInvoiceNotes) {
          // transform into json format then to string
          const lines = _.split(twInvoiceNotes, "\n");
          let jsonObject = {};
          _.forEach(lines, (line) => {
            const keyValue = _.split(line, "=");
            jsonObject[keyValue[0]] = keyValue[1];
          });
          params.twInvoiceNotes = JSON.stringify(jsonObject);
        }
      }

      this.setState({
        isSaving: true,
      });
      console.log(params);
      const result = await receivePayment(params);
      console.log(result);

      notify.show(
        "Payment received! Automatically reloading page...",
        "success"
      );

      this.setState({
        isSaving: false,
        errMsg: "",
      });

      setTimeout(() => {
        // window.location.reload(false);
        this.props.history.goBack();
      }, 3000);
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }
  }

  handleDateSelect(date) {
    console.log(date);
    this.setState({
      tempPayment: {
        ...this.state.tempPayment,
        collectionDate: date,
      },
    });
  }

  handleInputChanged({ event, key }) {
    let value = event.target.value;
    if (key === "receivedAmt") {
      value = _.floor(value, 2);
    }
    if (key === "twInvoiceAmt") {
      value = _.floor(value, 2);
    }

    this.setState({
      tempPayment: {
        ...this.state.tempPayment,
        [key]: value,
      },
    });
  }

  render() {
    const { paymentId, tempPayment, isLoading, isSaving, errMsg } = this.state;

    const basicInputClass =
      "bg-white focus:outline-none focus:shadow-outline border border-gray-500 rounded py-1 px-2 block appearance-none leading-normal";

    if (isLoading) {
      return "Loading ...";
    }

    if (!tempPayment) {
      return "Cannot find payment " + paymentId;
    }

    return (
      <div>
        <div className="bg-white px-12 pt-8 pb-4">
          <div className="font-extrabold text-gray-900 text-4xl">
            Receive Payment
          </div>
          <PaymentsTable items={[tempPayment]}></PaymentsTable>
        </div>

        <div className="bg-gray-200 px-12 py-8 min-h-screen">
          <div>
            <div className="p-4 border rounded shadow">
              <div className="flex">
                <div className="w-1/2">
                  <div className="mb-4">
                    <label className="font-semibold text-gray-800">
                      Receive Amount: (in {tempPayment.invoice.currency})
                    </label>
                    <input
                      className={`${basicInputClass} w-64`}
                      type="number"
                      step="0.01"
                      min={0}
                      value={tempPayment.receivedAmt}
                      onChange={(e) =>
                        this.handleInputChanged({
                          event: e,
                          key: "receivedAmt",
                        })
                      }
                    ></input>
                  </div>

                  <div className="mb-4">
                    <label className="font-semibold text-gray-800">
                      Collection Date:{" "}
                      {moment(tempPayment.collectionDate).format("YYYY/MM/DD")}
                    </label>
                    <DatePicker
                      selected={tempPayment.collectionDate}
                      inline
                      maxDate={new Date()}
                      onSelect={this.handleDateSelect} //when day is clicked
                    />
                  </div>
                </div>

                <div className="w-1/2 border border-gray-400 px-4 rounded">
                  <div className="flex align-middle my-4">
                    <label className="cursor-pointer">
                      <Checkbox
                        isChecked={tempPayment.shouldIssueTWInvoice}
                        onChange={() =>
                          this.handleInputChanged({
                            event: {
                              target: {
                                value: !tempPayment.shouldIssueTWInvoice,
                              },
                            },
                            key: "shouldIssueTWInvoice",
                          })
                        }
                      ></Checkbox>
                      <span className="ml-1 text-gray-800 hover:text-gray-900 font-semibold">
                        I want to issue TW invoice
                      </span>
                    </label>
                  </div>

                  <div className="mb-4">
                    <label className="font-semibold text-gray-800">
                      TW Receive Amount: (in TWD)
                    </label>
                    <input
                      className={`${basicInputClass} ${
                        !tempPayment.shouldIssueTWInvoice &&
                        "cursor-not-allowed bg-gray-400"
                      }`}
                      type="number"
                      step="0.01"
                      min={0}
                      disabled={!tempPayment.shouldIssueTWInvoice}
                      value={tempPayment.twInvoiceAmt}
                      onChange={(e) =>
                        this.handleInputChanged({
                          event: e,
                          key: "twInvoiceAmt",
                        })
                      }
                    ></input>
                  </div>

                  <div className="mb-4">
                    <label className="font-semibold text-gray-800">
                      Notes:
                    </label>
                    <div className="text-gray-600 text-xs">ex. abc=123</div>

                    <div>
                      <textarea
                        className={`${basicInputClass} ${
                          !tempPayment.shouldIssueTWInvoice &&
                          "cursor-not-allowed bg-gray-400"
                        }`}
                        value={tempPayment.twInvoiceNotes}
                        disabled={!tempPayment.shouldIssueTWInvoice}
                        rows={3}
                        onChange={(e) =>
                          this.handleInputChanged({
                            event: e,
                            key: "twInvoiceNotes",
                          })
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-row-reverse mt-4 items-center">
              <div>
                <button
                  type="button"
                  className="px-4 py-2 bg-blue-500 rounded shadow hover:bg-blue-700 text-white font-semibold"
                  onClick={() => this.handleReceivePayment(tempPayment)}
                >
                  {isSaving ? "Saving..." : "Save"}
                </button>
              </div>
              <div>
                <button
                  type="button"
                  disabled={isSaving}
                  className={`px-4 py-2 text-blue-700 ${
                    isSaving ? "cursor-not-allowed" : ""
                  }`}
                  onClick={() => this.props.history.goBack()}
                >
                  Back
                </button>
              </div>
              <div className="text-red-600">{errMsg}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReceivePaymentView;
