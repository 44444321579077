import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import HighchartChart from "../../common/HighchartChart";
import { isWeekend } from "../../../helpers/DateTransform";

class TrendIED extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      options: createChartOptions(props.reports),
    };
  }

  render() {
    const { options } = this.state;

    return (
      <div className="pt-8">
        <HighchartChart options={options}></HighchartChart>
      </div>
    );
  }
}

function createSeriesData(reports) {
  const iedDataSeries = _.map(reports, (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.ied,
      color: r.ied > 0 ? "#90ED7D" : "#f96868",
    };
  });

  const edsiDataSeries = _.map(reports, (r) => {
    const y = r.edsi * 100;
    const isInRange = y <= 5 && y >= -5;
    return {
      x: new Date(r.date).getTime(),
      y,

      marker: {
        fillColor: isInRange ? "#7CB5EB" : "#4151e2",
        symbol: "square",
      },
    };
  });

  const managedScoreDataSeries = _.map(reports, (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.managedScore * 100,
    };
  });

  return [
    {
      name: "EDSI",
      data: edsiDataSeries,
      yAxis: 1,
      type: "line",
    },
    {
      name: "Managed Score",
      data: managedScoreDataSeries,
      yAxis: 2,
      type: "area",
      fillOpacity: 0.1,
      marker: {
        enabled: false,
      },
    },
    {
      name: "IED",
      type: "column",
      data: iedDataSeries,
      maxPointWidth: 16,

      dataLabels: {
        enabled: true,
        format: "{point.y:,.0f}",
      },
    },
  ];
}

function createChartOptions(reports) {
  const seriesData = createSeriesData(reports);
  const iedMin = _.get(_.minBy(reports, "ied"), "ied");
  const iedMax = _.get(_.maxBy(reports, "ied"), "ied");

  const edsiMin = Math.min(_.get(_.minBy(reports, "edsi"), "edsi") * 100, -6);
  const edsiMax = Math.max(_.get(_.maxBy(reports, "edsi"), "edsi") * 100, 6);

  const options = {
    title: {
      text: "",
    },
    chart: {
      type: "line",
      zoomType: "x",
      height: 380,
      backgroundColor: "rgba(0,0,0,0)",
    },
    tooltip: {
      backgroundColor: "rgba(255,255,255,1)",
      formatter() {
        const headFormat = `<div style="font-size:12px; font-weight: bold; text-align: center;">${moment(
          this.x
        ).format("YYYY-MM-DD ddd")}</div>`;

        let eidPointFormat = "";
        let eidLabel = "";
        let edsiPointFormat = "";
        let edsiLabel = "";
        let mScorePointFormat = "";
        let mScoreLabel = "";
        for (let point of this.points) {
          switch (point.series.name) {
            case "IED": {
              eidLabel = `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: `;
              eidPointFormat = `<b>$${point.y}</b>`;
              break;
            }
            case "EDSI": {
              edsiLabel = `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: `;
              edsiPointFormat = `<b>${point.y.toFixed(2)}%</b>`;
              break;
            }

            case "Managed Score": {
              mScoreLabel = `<span style="color:${point.color}">\u25CF</span> ${point.series.name}: `;
              mScorePointFormat = `<b>${point.y.toFixed(2)}%</b>`;
              break;
            }
            default: {
              // nothing
            }
          }
        }

        const format = `
          ${headFormat}
          <table style="font-size: 12px;">
            <tr style="font-size: larger; border-bottom: 1px solid lightgray;">
              <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
                ${eidLabel}
              </td>
              <td style="padding-top: 4px; text-align: right;">
                ${eidPointFormat}
              </td>
            </tr>
            <tr>
              <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
                ${edsiLabel}
              </td>
              <td style="padding-top: 4px; text-align: right;">
                ${edsiPointFormat}
              </td>
            </tr>
            <tr style="">
              <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
                ${mScoreLabel}
              </td>
              <td style="padding-top: 4px; text-align: right;">
                ${mScorePointFormat}
              </td>
            </tr>
          </table>
          `;

        return format;
      },
      shared: true,
      useHTML: true,
      crosshairs: true,
    },
    xAxis: getXAxisConfigDateTime(),
    yAxis: [
      {
        title: {
          text: "IED",
          style: {
            fontWeight: "bold",
            color: "#2d3748",
          },
        },
        floor: iedMin,
        ceiling: iedMax,
        height: "50%",
        plotLines: [
          {
            color: "lightgray",
            width: 2,
            value: 0,
          },
        ],
      },
      {
        title: {
          text: "EDSI",
          style: {
            fontWeight: "bold",
            color: "#2d3748",
          },
        },
        height: "30%",
        top: "50%",
        offset: 0,
        opposite: true,
        floor: edsiMin,
        ceiling: edsiMax,

        plotBands: [
          {
            // mark +- 5%
            enabled: true,
            color: "#D8E9F9",
            from: -5,
            to: 5,
            label: {
              text: "<5%",
              align: "right",
              zIndex: 9999999,
            },
          },
        ],
      },
      {
        title: {
          text: "Manage Score %",
          style: {
            fontWeight: "bold",
            color: "#2d3748",
          },
        },
        height: "20%",
        top: "80%",
        // floor: 0,
        // ceiling: 100,
        min: 0,
        max: 100,
        offset: 0,
      },
    ],
    series: seriesData,
  };

  return options;
}

function getXAxisConfigDateTime() {
  return {
    type: "datetime",
    labels: {
      formatter: function () {
        const m = moment(this.value);
        const d = m.format("DD");
        // const d = m.format("MM/DD");
        const wd = m.format("ddd");
        const label = `${d} <br/> ${wd}`;
        if (d === "01" || this.isFirst) {
          return `${d} <br/> ${wd} <br/> <span style="color: #3182ce;	letter-spacing: 0.05em;">${m
            .format("MMM")
            .toUpperCase()}</span>`;
        }
        if (isWeekend(this.value)) {
          return `<span style="font-weight: bold; color: black;">${label}</span>`;
        }
        return label;
      },
    },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

export default TrendIED;
