import React from "react";
import { getConsoleOperationLogs, getPublishers } from "../../helpers/Api";
import LoadingUI from "../common/LoadingUI";
import moment from "moment-timezone";
import _ from "lodash";
import OpsLogTable from "./OpsLogTable";
import PublisherSelector from "./PublisherSelector";

function _transformItems(items) {
  return _.map(items, (item) => {
    item.key = `${item.date}-${item.caller.userId}-${item.op.name}-${item.op.result}`;

    item.dateTimeStr = moment(item.date).format("MM-DD HH:mm:ss");
    item.dateTimeFromNow = moment(item.date).fromNow();

    // is intowow user?
    item.isIntowowUser = !_.endsWith(item.caller.email, "@intowow.com");
    return item;
  });
}

const LIMIT = 200;

class YbConsoleOpsLogViewer extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      // data: [],
      items: [],
      errMsg: "",

      cursor: null,

      publishers: [],
    };

    this.handleShowMore = this.handleShowMore.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
  }

  async componentDidMount() {
    document.title = `YB Console Operation Logs | YB Observer`;

    // const st = moment().startOf("day").subtract(3, "days").toISOString();
    // const et = moment().startOf("day").add(1, "days").toISOString();
    const params = {
      limit: LIMIT,
      // pubIds: "276,320",
      // st,
      // et,
    };
    const { page, items } = await this.queryOperationLogs(params);
    if (page.cursor) {
      this.setState({
        cursor: page.cursor,
      });
    }

    // console.log(page, items);
    this.setState({ items: _transformItems(items) });
    // this.setState({ isLoading: true });
    // const { startDate, endDate } = _getDateRangeFromDateRangeType(
    //   this.state.dateRangeType
    // );
    // await this.queryAndUpdateUI({ startDate, endDate, networkId });
    // this.setState({ isLoading: false, startDate, endDate });

    // const { result } = await getPublishers();
    // console.log(result);
    // this.setState({
    //   publishers: result,
    // });
  }

  async queryOperationLogs(params) {
    try {
      return getConsoleOperationLogs(params);
    } catch (err) {
      // TODO
      this.setState({
        errMsg: err,
      });
    }
  }

  async handleShowMore() {
    const { page, items } = await this.queryOperationLogs({
      limit: LIMIT,
      cursor: this.state.cursor,
    });
    if (page.cursor) {
      this.setState({
        cursor: page.cursor,
      });
    } else {
      this.setState({
        cursor: null,
      });
    }

    // console.log(page, items);
    let newItems = _transformItems(items);
    this.setState({ items: this.state.items.concat(newItems) });
  }

  async handleFilter(filterParams) {
    // filterParams:
    // pubIds
    // st
    // et
    let params = {
      limit: LIMIT,
      // pubIds: "276,320",
      // st,
      // et,
    };

    if (filterParams.pubIds && filterParams.pubIds.length > 0) {
      params.pubIds = filterParams.pubIds.join(",");
    }

    const { page, items } = await this.queryOperationLogs(params);
    if (page.cursor) {
      this.setState({
        cursor: page.cursor,
      });
    }

    this.setState({ items: _transformItems(items) });
  }

  render() {
    const { items, cursor } = this.state;

    return (
      <>
        {this.state.isLoading ? (
          <LoadingUI></LoadingUI>
        ) : (
          <>
            {this.state.errMsg ? (
              <div>{this.state.errMsg}</div>
            ) : (
              <div className="bg-white px-12">
                <h1 className="font-extrabold text-gray-900 text-4xl pt-8 pb-4">
                  YB Console Operation Logs
                </h1>
              </div>
            )}

            <div className="bg-gray-200 px-12 py-8 min-h-full">
              <div className="py-4">
                <div className="text-sm font-semibold text-gray-800">
                  Filter Publishers:
                </div>
                <PublisherSelector
                  handleOnChange={this.handleFilter}
                ></PublisherSelector>
              </div>

              {items ? (
                <>
                  <OpsLogTable items={items}></OpsLogTable>
                  {cursor && (
                    <button
                      type="button"
                      className="my-2 px-6 py-2 text-blue-700 font-medium bg-blue-200 w-full shadow hover:text-blue-900"
                      onClick={this.handleShowMore}
                    >
                      Show more
                    </button>
                  )}
                </>
              ) : (
                "no data"
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export default YbConsoleOpsLogViewer;
