import React from "react";
import _ from "lodash";
import NumberFormat from "../../common/NumberFormat";
import { Link } from "react-router-dom";

class NetworksTable extends React.PureComponent {
  render() {
    const { items, hideAction, isLoading } = this.props;
    const thClass =
      "px-4 py-2 text-gray-600 text-left text-xs tracking-wider uppercase";
    const tdClass = "px-4 py-2";

    return (
      <>
        <table className="min-w-full shadow divide-y">
          <thead className="bg-gray-100">
            <tr>
              <th className={thClass} style={{ width: "40%" }}>
                Network
              </th>
              <th className={thClass + " text-right"}>Currency</th>
              <th className={thClass + " text-right"}>Algo Cost CPM</th>
              <th className={thClass + " text-right"}>Cost CPM</th>
              <th className={thClass + " text-right"}>Free Quota</th>

              {!hideAction && <th className={thClass}>Action</th>}
            </tr>
          </thead>
          <tbody className="bg-white divide-y text-sm">
            {items.map((item) => {
              return (
                <tr key={item.networkId} className="hover:bg-gray-100">
                  <td className={tdClass}>
                    <span className="text-lg font-semibold text-gray-800">
                      {item.networkId} - {item.name}
                    </span>
                    <div className="text-gray-600 text-xs">{item.status}</div>
                  </td>

                  <td className={tdClass + " text-right"}>{item.currency}</td>

                  <td className={tdClass + " text-right"}>
                    <NumberFormat
                      value={item.algoCostCpm}
                      prefix="$"
                      format="0,0.0000"
                    ></NumberFormat>
                  </td>
                  <td className={tdClass + " text-right"}>
                    {item.adServingCost ? (
                      <NumberFormat
                        value={item.adServingCost.costCpm}
                        prefix="$"
                        format="0,0.0000"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td className={tdClass + " text-right"}>
                    {item.adServingCost ? (
                      <NumberFormat
                        value={item.adServingCost.freeQuota}
                        format="0,0"
                      ></NumberFormat>
                    ) : (
                      "-"
                    )}
                  </td>

                  {!hideAction && (
                    <td className={tdClass}>
                      {
                        // item.status !== "BUSINESS_TERMINATED" &&
                        item.accountType !== "ARCHIVED" &&
                        item.accountType !== "PROCESSING" &&
                        item.accountType !== "FAILED" ? (
                          <Link
                            to={`/ops-mgmt/account/network/${item.networkId}/update-ad-serving-cost`}
                            className={`px-2 py-1 mr-1 my-1 bg-blue-400 text-xs rounded shadow hover:bg-blue-600 text-white font-semibold
     `}
                          >
                            Update Ad Serving Cost
                          </Link>
                        ) : (
                          "-"
                        )
                      }
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default NetworksTable;
