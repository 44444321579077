import React from "react";
import _ from "lodash";

import { notify } from "react-notify-toast";

import { createPublisher } from "../../../helpers/Api";
import PublisherFormBasic from "./PublisherFormBasic";
import PublisherFormGAMProfile from "./PublisherFormGAMProfile";
import PublisherFormContactInfo from "./PublisherFormContactInfo";

const MAX_STEP = 3;

class CreatePublisherView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: "",
      isSaving: false,

      name: "",

      userEmail: "",

      geoCountryId: null,

      currentStep: 1,
    };

    this.handleCreatePublisher = this.handleCreatePublisher.bind(this);
    this.submitStep = this.submitStep.bind(this);
    this.goToLastStep = this.goToLastStep.bind(this);
  }

  async handleCreatePublisher(publisher) {
    //
    console.log("submit", publisher);
    //
    try {
      this.setState({
        isSaving: true,
      });

      const result = await createPublisher(publisher);
      console.log(result);

      notify.show(
        "Publisher created! Redirecting to previous page...",
        "success"
      );

      this.setState({
        isSaving: false,
        errMsg: "",
      });

      setTimeout(() => {
        // window.location.reload(false);
        this.props.history.push("/ops-mgmt/account/publishers");
      }, 3000);
    } catch (err) {
      console.log(err);
      this.setState({
        errMsg: err,
        isSaving: false,
      });
    }
  }

  goToLastStep() {
    let { currentStep } = this.state;
    if (currentStep === 1) return;
    this.setState({ currentStep: --currentStep });
  }

  submitStep(stepForm) {
    // last step -> submit CreatePublisher
    if (this.state.currentStep === MAX_STEP) {
      const { name, userEmail } = this.state;
      const { geoCountryId } = stepForm;
      const finalForm = {
        name,
        userEmail,
        geoCountryId,
      };
      return this.handleCreatePublisher(finalForm);
    }

    const currentStep = ++this.state.currentStep;

    this.setState({
      ...stepForm,
      currentStep,
    });
  }

  render() {
    const {
      errMsg,
      isSaving,
      currentStep,

      name,

      userEmail,

      geoCountryId,
    } = this.state;

    return (
      <div>
        <div className="bg-white px-12">
          <h1 className="font-extrabold text-gray-900 text-4xl pt-8 pb-4">
            Create Publisher
          </h1>
        </div>

        <div className="bg-gray-200 px-12 py-8 min-h-screen">
          <div className="flex">
            <div className="w-1/2">
              {currentStep === 1 && (
                <PublisherFormBasic
                  name={name}
                  handleSubmit={this.submitStep}
                ></PublisherFormBasic>
              )}

              {currentStep === 2 && (
                <PublisherFormContactInfo
                  userEmail={userEmail}
                  handleSubmit={this.submitStep}
                  handleGoToLastStep={this.goToLastStep}
                ></PublisherFormContactInfo>
              )}

              {currentStep === 3 && (
                <PublisherFormGAMProfile
                  geoCountryId={geoCountryId}
                  handleSubmit={this.submitStep}
                  handleGoToLastStep={this.goToLastStep}
                  isSaving={isSaving}
                ></PublisherFormGAMProfile>
              )}
            </div>
          </div>

          <div className="mt-4">
            {errMsg !== "" && (
              <div className="text-red-700 font-semibold bg-red-100 p-4 mb-4 rounded">
                {errMsg}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CreatePublisherView;
