import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import { FiInfo, FiChevronDown } from "react-icons/fi";
import ConfigValueControl from "./ConfigValueControl";

class AutoPilotConfigItem extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      shouldShowAdvanced: false,
    };

    this.showAdvanced = this.showAdvanced.bind(this);
  }

  showAdvanced() {
    this.setState({
      shouldShowAdvanced: true,
    });
  }

  render() {
    const {
      // sectionKey,
      configSchemas,
      sectionTitle,
      sectionConfigKey,
      currentEditingKey,
      handleStartEditMode,
      handleCancelEditMode,
      handleApplyChanges,
      newConfig,
      isUnitConfig,
      isHintConfig,
    } = this.props;

    if (!configSchemas) {
      return "";
    }

    // Deprecated!!
    // GROUP BY BASIC / ADVANCED
    // let groupedConfigSchemas = _.groupBy(configSchemas, (s) => {
    //   return isHintConfig ? s.keyLevel : undefined;
    // });

    // GROUP BY CATEGORY
    let groupedCategorySchemas = _.groupBy(configSchemas, (s) => {
      return s.groupCategory;
    });

    return (
      <>
        <div className="px-4">
          <div className="mb-3">
            <div className="text-2xl text-gray-800 font-extrabold leading-tight">
              {sectionTitle}
            </div>
          </div>

          {sectionTitle === "Prophet" && (
            <div className="flex flex-wrap items-center mb-4 text-sm">
              <div className="font-semibold text-gray-700 mr-1">Jump to: </div>
              {_.keys(groupedCategorySchemas).map((groupCategory) => {
                return (
                  <button
                    key={groupCategory}
                    type="button"
                    className="px-2 py-1 mb-1 mr-1 border text-gray-800 border-gray-200 rounded hover:border-blue-400"
                    onClick={() => {
                      document.getElementById(groupCategory).scrollIntoView();
                    }}
                  >
                    {groupCategory}
                  </button>
                );
              })}
            </div>
          )}

          {_.keys(groupedCategorySchemas).map((category) => {
            const configSchemas = groupedCategorySchemas[category];

            return (
              <div key={category} id={category} className="mb-12">
                {category !== "undefined" && (
                  <div className="pt-4 pb-2 -ml-8 pl-8 font-semibold text-xl text-blue-800 bg-blue-100 border border-blue-200 sticky top-0">
                    {`${category} Settings`}
                  </div>
                )}

                <div className="bg-white -ml-8 pl-8 border">
                  {configSchemas.map((s) => {
                    // Important! Some keys are unit only!! ex. allowed_device_categories
                    if (!isUnitConfig && s.isUnitOnly) {
                      return "";
                    }

                    // sectionConfigKey could be ""
                    const configValue = sectionConfigKey
                      ? _.get(newConfig, [sectionConfigKey, s.key])
                      : _.get(newConfig, [s.key]);
                    const configKeyTitle = _.startCase(s.key);

                    return (
                      <div
                        key={s.key}
                        className="flex py-6 border-b border-gray-400 -ml-8 pl-8"
                      >
                        <div className="w-1/3">
                          <div
                            className={`font-semibold text-sm break-words pr-8 ${
                              s.isDeprecated ? "text-orange-700" : ""
                            }`}
                          >
                            {s.title ? s.title : configKeyTitle}:
                          </div>
                          <div className="text-xs text-gray-600">
                            {s.isUnitOnly ? "(Unit level only)" : ""}
                          </div>
                          <div className="text-blue-600 mt-2 text-xs">
                            <FiInfo
                              data-tip
                              data-for={`apconfig-description-${s.key}`}
                            ></FiInfo>
                          </div>

                          <ReactTooltip
                            id={`apconfig-description-${s.key}`}
                            type="dark"
                            effect="solid"
                            place="right"
                          >
                            <div style={{ maxWidth: "300px" }}>
                              <div>
                                <b>{configKeyTitle}:</b>
                              </div>
                              {s.description}
                            </div>
                          </ReactTooltip>
                        </div>

                        <div
                          className={`w-2/3 bg-gray-200 -my-6 py-6 ${
                            currentEditingKey === s.key
                              ? "border-2 border-blue-600"
                              : ""
                          }`}
                        >
                          <ConfigValueControl
                            sectionConfigKey={sectionConfigKey}
                            configValue={configValue}
                            valueSchema={s}
                            currentEditingKey={currentEditingKey}
                            handleStartEditMode={handleStartEditMode}
                            handleCancelEditMode={handleCancelEditMode}
                            handleApplyChanges={handleApplyChanges}
                          ></ConfigValueControl>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );

            // return "";
          })}
        </div>
      </>
    );
  }
}

export default AutoPilotConfigItem;
