import React from "react";
import _ from "lodash";
import AdSizesControl from "./AdSizesControl";
// import AdSizesLayersControl from "./AdSizesLayersControl";
import SetsOfAdSizesControl from "./SetsOfAdSizesControl";
import DeviceCategoriesControl from "./DeviceCategoriesControl";
import ClientRefreshLabelsControl from "./ClientRefreshLabelsControl";

class APConfigItemForm extends React.PureComponent {
  constructor(props) {
    super(props);

    const { valueType, configValue, valueSchema } = this.props;

    let inputValue = configValue;

    if (_.isUndefined(inputValue)) {
      // give initial default value
      if (valueType === "boolean") {
        inputValue = true;
      } else if (valueType === "integer_enum" || valueType === "string_enum") {
        const options = valueSchema.enum;
        const keys = _.keys(options);
        inputValue = _.first(keys);
      } else if (valueType === "ad_sizes") {
        inputValue = [];
      } else if (valueType === "ad_sizes_layers") {
        inputValue = [];
      } else if (valueType === "sets_of_ad_sizes") {
        inputValue = [];
      } else if (valueType === "device_categories") {
        inputValue = [];
      } else if (valueType === "client_refresh_labels") {
        inputValue = [];
      } else {
        inputValue = "";
      }
    }

    props.handleNewValueChanged(inputValue);

    this.state = {
      inputValue,
    };

    this.handleInputChanged = this.handleInputChanged.bind(this);
  }

  handleInputChanged(e) {
    this.setState({
      inputValue: e.target.value,
    });

    this.props.handleNewValueChanged(e.target.value);
  }

  render() {
    const { inputValue } = this.state;
    const {
      valueType,
      configValue,
      valueSchema,
      handleNewValueChanged,
      handleHasError,
    } = this.props;
    const basicInputClass =
      "bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded py-1 px-4 block w-full appearance-none leading-normal";

    switch (valueType) {
      case "integer": {
        const { minimum, maximum } = valueSchema;
        return (
          <div>
            <input
              type="number"
              step="1"
              min={minimum}
              max={maximum}
              value={inputValue}
              onChange={this.handleInputChanged}
              className={basicInputClass}
              required
            ></input>
          </div>
        );
      }

      case "float": {
        const { minimum, maximum } = valueSchema;
        return (
          <div>
            <input
              type="number"
              step="0.01"
              min={minimum}
              max={maximum}
              value={inputValue}
              onChange={this.handleInputChanged}
              className={basicInputClass}
              required
            ></input>
          </div>
        );
      }

      case "boolean": {
        let value = inputValue;

        if (typeof inputValue === "boolean") {
          value = inputValue ? "true" : "false";
        }

        return (
          <div>
            <select value={value} onChange={this.handleInputChanged}>
              <option value="true">True</option>
              <option value="false">False</option>
            </select>
          </div>
        );
      }

      case "integer_enum": {
        let value = inputValue;

        const options = valueSchema.enum;
        const keys = _.keys(options);

        return (
          <div>
            <select value={value} onChange={this.handleInputChanged}>
              {keys.map((key) => {
                return (
                  <option key={key} value={key}>
                    {key}: {options[key]}
                  </option>
                );
              })}
            </select>
          </div>
        );
      }

      case "string_enum": {
        let value = inputValue;

        const options = valueSchema.enum;
        const keys = _.keys(options);

        return (
          <div>
            <select value={value} onChange={this.handleInputChanged}>
              {keys.map((key) => {
                return (
                  <option key={key} value={key}>
                    {key}
                  </option>
                );
              })}
            </select>
          </div>
        );
      }

      case "device_categories": {
        return (
          <div>
            <DeviceCategoriesControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              handleHasError={handleHasError}
            ></DeviceCategoriesControl>
          </div>
        );
      }

      case "client_refresh_labels": {
        return (
          <div>
            <ClientRefreshLabelsControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              handleHasError={handleHasError}
            ></ClientRefreshLabelsControl>
          </div>
        );
      }

      case "ad_sizes": {
        return (
          <div className="ml-4">
            <AdSizesControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
            ></AdSizesControl>
          </div>
        );
      }

      case "ad_sizes_layers": {
        return (
          <div className="ml-4">
            {/* <AdSizesLayersControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              allowFluidSize={true}
            ></AdSizesLayersControl> */}

            <AdSizesControl
              valueSchema={valueSchema}
              inputValue={inputValue}
              handleNewValueChanged={handleNewValueChanged}
              allowFluidSize={true}
            ></AdSizesControl>
          </div>
        );
      }

      case "sets_of_ad_sizes": {
        return (
          <SetsOfAdSizesControl
            valueSchema={valueSchema}
            inputValue={inputValue}
            handleNewValueChanged={handleNewValueChanged}
          ></SetsOfAdSizesControl>
        );
      }

      default: {
      }
    }

    return (
      <div>
        {valueType}: {configValue}
      </div>
    );
  }
}

export default APConfigItemForm;
