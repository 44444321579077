import React from "react";
import SegmentTargeting from "../observer/SegmentTargeting";
import SegmentModeIndicator from "./SegmentModeIndicator";

class SegmentBasicTable extends React.PureComponent {
  render() {
    const { segments } = this.props;

    return (
      <>
        <div
          className="bg-gray-100 overflow-y-auto"
          style={{ maxHeight: "360px" }}
        >
          <table className="table w-full text-sm border">
            <thead className="bg-gray-200 text-gray-800">
              <tr>
                <th rowSpan="2" className="text-center p-2 border">
                  Mode
                </th>
                <th rowSpan="2" className="text-center p-2 border">
                  Segment
                  <br />
                  ID
                </th>
                <th colSpan="5" className="text-center p-2 border">
                  Targeting
                </th>
              </tr>
              <tr>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Country
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  OS
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Device Category
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Hour
                </th>
                <th className="text-center border text-gray-900 font-semibold p-2">
                  Sizes
                </th>
              </tr>
            </thead>
            <tbody>
              {segments.map((segment) => {
                const targeting = segment.targeting || {};

                return (
                  <tr key={segment.segmentId}>
                    <td className="p-2 border font-bold">
                      <div className="flex items-center justify-center">
                        <SegmentModeIndicator
                          mode={segment.mode}
                        ></SegmentModeIndicator>
                        <div className="ml-1">{segment.mode}</div>
                      </div>
                    </td>
                    <td className="p-2 border text-center font-bold text-gray-800 whitespace-no-wrap">
                      {segment.segmentId === -1
                        ? "No segment"
                        : segment.segmentId}
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        target={targeting.country}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        target={targeting.os}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        target={targeting.device_category}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        target={targeting.hour}
                      ></SegmentTargeting>
                    </td>
                    <td className="p-2 border text-left break-words align-top">
                      <SegmentTargeting
                        targetKey="sizes"
                        target={targeting.sizes}
                      ></SegmentTargeting>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default SegmentBasicTable;
