import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import HighchartChart from "../../common/HighchartChart";

class BillingChart extends React.PureComponent {
  render() {
    const options = _createChartOptions(this.props.reports);

    return <HighchartChart options={options}></HighchartChart>;
  }
}

function _createChartOptions(reports) {
  const seriesData = _createSeriesData(reports);

  const options = {
    title: {
      text: "",
    },
    chart: {
      zoomType: "x",
    },
    tooltip: {
      shared: true,
      followPointer: true,
      ..._getTooltipConfig(),
    },
    xAxis: {
      ..._getXAxisConfigDateTime(),
      // // ..._createPlotBands(reports),
      ..._createPlotLines(reports),
    },
    yAxis: {
      // crosshair: {
      //   width: 2,
      //   // color: "#4fd1c5",
      //   color: "gray",
      //   dashStyle: "shortdot",
      // },
      title: {
        text: "TWD $",
      },
      // min: 0,
    },
    series: seriesData,
    // plotOptions: {
    //   series: {
    //     // max number of points: https://api.highcharts.com/highcharts/plotOptions.series.turboThreshold
    //     turboThreshold: 2000, // default is 1000, performance might be effected
    //   },
    // },
  };

  return options;
}

function _createSeriesData(reports) {
  const reportsByPaymentStatus = _.groupBy(reports, "paymentStatus");
  const pendingSeries = _.map(reportsByPaymentStatus["PENDING"], (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.amountTWD,
      // custom
      // lm: r.lastMonthBillableAccRev,
    };
  });

  const receivedSeries = _.map(reportsByPaymentStatus["RECEIVED"], (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.amountTWD,
    };
  });

  const badSeries = _.map(reportsByPaymentStatus["BAD_DEBT"], (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.amountTWD,
    };
  });

  return [
    {
      name: "Bad Debt",
      type: "column",
      stacking: "normal",
      data: badSeries,
      // color: "black",
      color: "#E04848",
      // opacity: 0.5,
    },
    {
      name: "Pending (Account Receivable)",
      type: "column",
      stacking: "normal",
      data: pendingSeries,
      color: "#98AAB9",
    },
    {
      name: "Received",
      type: "column",
      stacking: "normal",
      data: receivedSeries,
      color: "#67B7DD",
      // opacity: 0.5,
    },
  ];
}

function _getTooltipConfig() {
  return {
    backgroundColor: "rgba(255,255,255,1)",
    formatter() {
      const headFormat = `<div style="font-size:12px; font-weight: bold; text-align: center;">${moment(
        this.x
      ).format("YYYY/MM")}</div>`;

      let dataRowsFormat = "";
      let total = 0;
      // reverse points so that "billable" is on top of "potential"
      for (let point of _.reverse(this.points)) {
        total = point.total;
        const p = Highcharts.numberFormat(point.y, 0);
        let pointFormat = `<b>${p}</b>`;

        const pp = Highcharts.numberFormat(point.percentage, 0);

        const rowFormat = `
          <tr>
            <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
              <span style="color:${point.color}">\u25CF</span> ${point.series.name}: $
            </td>
            <td style="padding-top: 4px; text-align: right;">
              ${pointFormat}
            </td>

            <td style="padding-top: 4px; text-align: right;">
              (${pp}%)
            </td>
          </tr>
        `;
        dataRowsFormat += rowFormat;
      }

      // Add total
      const p = Highcharts.numberFormat(total, 0);
      let pointFormat = `<b>${p}</b>`;

      const rowFormat = `
        <tr>
          <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
            Total: $
          </td>
          <td style="padding-top: 4px; text-align: right;">
            ${pointFormat}
          </td>

          <td style="padding-top: 4px; text-align: right;">
            
          </td>
        </tr>
      `;
      dataRowsFormat += rowFormat;

      let format = `
        ${headFormat}
        <table style="font-size: 12px;">
          ${dataRowsFormat}
        </table>
        `;

      return format;
    },
    shared: true,
    useHTML: true,
    crosshairs: true,
  };
}

function _getXAxisConfigDateTime() {
  return {
    type: "datetime",
    dateTimeLabelFormats: {
      month: "%Y/%m",
    },
    minTickInterval: 28 * 24 * 3600 * 1000, // one month
    crosshair: true,
  };
}

function _createPlotLines(reports) {
  const halfMonth = 15 * 86400 * 1000;
  const months = _.reduce(
    reports,
    (result, r) => {
      const date = new Date(r.date);
      if (date.getMonth() === 0) {
        result.push({
          yearString: date.getFullYear(),
          monthString: date.toLocaleString("en", { month: "short" }),
          value: date.getTime() - halfMonth,
        });
      }
      return result;
    },
    []
  );

  const plotLines = _.map(months, (d) => {
    return {
      color: "#fbd38d",
      width: 1,
      value: d.value,
      label: {
        text: d.yearString,
        verticalAlign: "top",
        textAlign: "right",
        rotation: 0,
        // y: 24, // lower label from the top
        x: 36,
        style: {
          color: "#f29c09",
          fontWeight: "bold",
        },
      },
    };
  });

  return {
    plotLines,
  };
}

export default BillingChart;
