import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { notify } from "react-notify-toast";
import {
  getInvoices,
  adviseInvoice,
  approveInvoice,
  issueInvoice,
  voidInvoice,
  badDebtInvoice,
} from "../../../helpers/Api";
import InvoicesTable from "./InvoicesTable";
import PublisherFilter from "./PublisherFilter";

function _orderItems(items) {
  return _.orderBy(items, ["startDate", "endDate"], ["desc", "desc"]);
}

class InvoicesWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      filteredInvoices: [],
      isLoading: false,
      isSaving: false,
      isSearching: false,
      errMessage: "",
      searchText: "",
      defaultSearchText: "",
    };

    this.onHandleAdvise = this.onHandleAdvise.bind(this);
    this.onHandleIssue = this.onHandleIssue.bind(this);
    this.onHandleVoid = this.onHandleVoid.bind(this);
    this.onHandleBadDebt = this.onHandleBadDebt.bind(this);
    this.onHandleApprove = this.onHandleApprove.bind(this);
    this.handleSearchByPublisher = this.handleSearchByPublisher.bind(this);
  }

  async componentDidMount() {
    try {
      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
      });
    } catch (err) {
      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async queryInvoices() {
    const queryParams = this.props.queryParams;
    const items = await getInvoices(queryParams);

    const invoices = _.map(items, (item) => {
      item.startDateStr = moment.utc(item.startDate).format("YYYY/MM/DD");
      item.endDateStr = moment
        .utc(item.endDate)
        .add(1, "second")
        .format("YYYY/MM/DD");

      item.updatedAtStr = moment(item.updatedAt).format("YYYY/MM/DD HH:mm:ss");

      return item;
    });

    return invoices;
  }

  handleSearchByPublisher(inputValue) {
    let filterKeys = ["publisher.publisherId", "publisher.name"];

    let filteredInvoices = _.filter(this.state.invoices, (invoice) => {
      let isMatch = false;

      _.forEach(filterKeys, (fKey) => {
        const value = _.get(invoice, fKey);

        if (value && _.toLower(value).includes(_.toLower(inputValue))) {
          isMatch = true;
        }
      });

      return isMatch;
    });

    filteredInvoices = _orderItems(filteredInvoices);

    if (inputValue === "") {
      this.setState({
        isSearching: false,
        filteredInvoices,
        searchText: inputValue,
      });
    } else {
      this.setState({
        isSearching: true,
        filteredInvoices,
        searchText: inputValue,
      });
    }
  }

  async onHandleAdvise(invoice) {
    const { invoiceId, invoiceNo } = invoice;
    const userConfirm = window.confirm(
      `Are you sure to advise invoice number ${invoiceNo}?`
    );
    if (!userConfirm) return;

    try {
      const r = await adviseInvoice(invoiceId);

      notify.show("Advised invoice!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to advise", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleApprove(invoice) {
    const { invoiceId, invoiceNo } = invoice;
    const userConfirm = window.confirm(
      `Are you sure to approve invoice number ${invoiceNo}?`
    );
    if (!userConfirm) return;

    try {
      const r = await approveInvoice(invoiceId);

      notify.show("Approved invoice!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to approve", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleIssue(invoice) {
    const { invoiceId, invoiceNo } = invoice;
    const pubId = invoice.publisher.publisherId;
    const userConfirm = window.confirm(
      `Are you sure to issue invoice number ${invoiceNo}?`
    );
    if (!userConfirm) return;

    try {
      const r = await issueInvoice({ invoiceId, pubId });
      console.log(r);

      const result = _.get(r, "result.data.update.result");
      console.log(result);
      if (result !== "ok") {
        throw result;
      }
      notify.show("Issued invoice!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to issue invoice", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleVoid(invoice) {
    const { invoiceId, invoiceNo } = invoice;
    const userPrompt = window.prompt(
      `What is the reason of voiding invoice ${invoiceNo}?`,
      "Wrong amount"
    );

    if (userPrompt === "") {
      notify.show(
        "Void failed! Please give a reason in order to void invoice.",
        "error"
      );
      return;
    }
    if (!userPrompt) return;

    try {
      const r = await voidInvoice({ invoiceId, reason: userPrompt });
      console.log(r);

      const result = _.get(r, "result.data.update.result[0]");
      console.log(result);
      if (result.error) {
        throw result.error;
      }

      notify.show("Voided invoice!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to void invoice", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  async onHandleBadDebt(invoice) {
    const { invoiceNo, payment } = invoice;
    const userPrompt = window.prompt(
      `[Bad Debt] To move this invoice (invoiceNo: ${invoiceNo}) to bad debt, insert the invoiceNo below:`,
      ""
    );

    if (!userPrompt) return;
    if (userPrompt !== invoiceNo) {
      notify.show("Wrong invoiceNo! No action is performed.", "error");
      return;
    }

    try {
      const r = await badDebtInvoice({ paymentId: payment.paymentId });
      console.log(r);

      const result = _.get(r, "result.data.update.payment");
      console.log(result);
      if (result.error) {
        throw result.error;
      }

      notify.show("Successfully marked invoice as bad debt!", "success");

      this.setState({
        isLoading: true,
      });
      const invoices = await this.queryInvoices();
      const filteredInvoices = _orderItems(invoices);

      this.setState({
        filteredInvoices,
        invoices: filteredInvoices,
        isLoading: false,
        defaultSearchText: this.state.searchText, // so the search input can show this text
      });

      this.handleSearchByPublisher(this.state.searchText);
    } catch (err) {
      console.log(err);
      notify.show("Failed to mark invoice as bad debt", "error");

      this.setState({
        isLoading: false,
        errMessage: err,
      });
    }
  }

  render() {
    const {
      invoices,
      filteredInvoices,
      errMessage,
      isSaving,
      isSearching,
      isLoading,
    } = this.state;

    if (isLoading) {
      return "Loading...";
    }

    if (errMessage) {
      return <div className="text-red-700">{errMessage.toString()}</div>;
    }

    return (
      <div>
        <div className="mb-4">
          <PublisherFilter
            handleSearch={this.handleSearchByPublisher}
            placeholder={"Search by publisher id and name"}
            defaultValue={this.state.defaultSearchText}
          ></PublisherFilter>
          <div className="text-gray-700 my-1 mx-1 text-sm">
            {isSearching ? `Found ${filteredInvoices.length} out of ` : ""}
            {invoices.length} invoices
          </div>
        </div>

        <InvoicesTable
          items={filteredInvoices}
          isLoading={isLoading}
          isSaving={isSaving}
          onHandleAdvise={this.onHandleAdvise}
          onHandleApprove={this.onHandleApprove}
          onHandleIssue={this.onHandleIssue}
          onHandleVoid={this.onHandleVoid}
          onHandleBadDebt={this.onHandleBadDebt}
          actions={this.props.actions} // ISSUE, ADVISE, APPROVE, VOID, BAD_DEBT(tbd)
          columns={this.props.columns}
          tabName={this.props.tabName}
        ></InvoicesTable>
      </div>
    );
  }
}

export default InvoicesWrapper;
