import React from "react";
import ReactTooltip from "react-tooltip";
import { networkStatus } from "../../constants/NetworkStatus";

class NetworkStatusIndicator extends React.PureComponent {
  render() {
    const { status } = this.props;

    let indicatorClass = null;
    let statusName = "";

    switch (status) {
      case networkStatus.RUNNING: {
        indicatorClass = "bg-green-500 border border-green-200";
        statusName = "Running";
        break;
      }

      case networkStatus.PAUSED: {
        indicatorClass = "bg-orange-500 border border-orange-200";
        statusName = "Paused";
        break;
      }

      case networkStatus.TERMINATED: {
        indicatorClass = "bg-red-600 border border-red-200";
        statusName = "Terminated";
        break;
      }

      default: {
        // nothing
      }
    }

    if (indicatorClass) {
      return (
        <>
          <div
            className={`w-2 h-2 rounded-full ${indicatorClass}`}
            data-tip
            data-for={`network-status-${status}`}
          ></div>

          <ReactTooltip
            id={`network-status-${status}`}
            type="dark"
            effect="solid"
          >
            {statusName}
          </ReactTooltip>
        </>
      );
    } else {
      return "";
    }
  }
}

export default NetworkStatusIndicator;
