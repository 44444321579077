import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import Highcharts from "highcharts";
import HighchartChart from "../../common/HighchartChart";
// import { isWeekend } from "../../helpers/DateTransform";

class BizHighchartView extends React.PureComponent {
  render() {
    const options = _createChartOptions(this.props.reports);

    return <HighchartChart options={options}></HighchartChart>;
  }
}

function _createChartOptions(reports) {
  const seriesData = _createSeriesData(reports);

  const options = {
    title: {
      text: "",
    },
    chart: {
      zoomType: "x",
      // ..._createSelectionEvents(handleFilterByDateRange),
    },
    tooltip: {
      shared: true,
      followPointer: true,
      ..._getTooltipConfig(),
    },
    xAxis: {
      ..._getXAxisConfigDateTime(),
      // ..._createPlotBands(reports),
      ..._createPlotLines(reports),
    },
    yAxis: {
      crosshair: {
        width: 2,
        // color: "#4fd1c5",
        color: "gray",
        dashStyle: "shortdot",
      },
      title: {
        text: "TWD $",
      },
      // min: 0,
    },
    series: seriesData,
    plotOptions: {
      series: {
        // max number of points: https://api.highcharts.com/highcharts/plotOptions.series.turboThreshold
        turboThreshold: 2000, // default is 1000, performance might be effected
      },
    },
  };

  return options;
}

function _createSeriesData(reports) {
  const billableSeries = _.map(reports, (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.billableAccRev,
      // custom
      lm: r.lastMonthBillableAccRev,
      lmd: r.lastMonthComparisonDate,
      // goalReached: r.dailyGoal ? r.goalReachedPercentage : null,

      billableAccRev: r.billableAccRev,
      // revCurrentDateThisMonth: r.revCurrentDateThisMonth,
      // currentMtdProgress: r.currentMtdProgress,
      // lastMonthRevProgress: r.lastMonthRevProgress,
      // revLastDateLastMonth: r.revLastDateLastMonth,
      // revCurrentDateLastMonth: r.revCurrentDateLastMonth,
      // daysOfLastMonth: r.daysOfLastMonth,
      // lastMtdProgress: r.lastMtdProgress,
    };
  });

  const potentialSeries = _.map(reports, (r) => {
    return {
      x: new Date(r.date).getTime(),
      y: r.potentialAccRev,
    };
  });

  return [
    {
      name: "Potential Acc Rev",
      type: "column",
      stacking: "normal",
      data: potentialSeries,
      color: "#dd6b20",
    },
    {
      name: "Billable Acc Rev",
      type: "column",
      stacking: "normal",
      data: billableSeries,
      color: "#559DF0",
      // opacity: 0.5,
    },
  ];
}

function _getTooltipConfig() {
  const helpers = [
    "revCurrentDateThisMonth",
    "currentMtdProgress",
    "lastMonthRevProgress",
    "revLastDateLastMonth",
    "revCurrentDateLastMonth",
    "daysOfLastMonth",
    "lastMtdProgress",
  ];
  return {
    backgroundColor: "rgba(255,255,255,1)",
    formatter() {
      const headFormat = `<div style="font-size:12px; font-weight: bold; text-align: center;">${moment(
        this.x
      ).format("YYYY-MM-DD ddd")}</div>`;

      let dataRowsFormat = "";
      // reverse points so that "billable" is on top of "potential"
      for (let point of _.reverse(this.points)) {
        // Add Last month billable acc rev
        if (point.point.lm) {
          //   _.forEach(helpers, (h) => {
          //     const p = Highcharts.numberFormat(point.point[h], 0);
          //     let pointFormat = `<b>$ ${p}</b>`;

          //     const rowFormatEREV = `
          //     <tr>
          //       <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
          //         ${h}:
          //       </td>
          //       <td style="padding-top: 4px; text-align: right;">
          //         ${pointFormat}
          //       </td>

          //       <td style="padding-top: 4px; text-align: right;">

          //       </td>
          //     </tr>
          //   `;
          //     dataRowsFormat += rowFormatEREV;
          //   });

          const p = Highcharts.numberFormat(point.point.lm, 0);
          let pointFormat = `<b>${p}</b>`;

          let textColor = "red";
          let diff = point.point.billableAccRev - point.point.lm;
          let diffP = Highcharts.numberFormat(diff, 0);
          if (diff >= 0) {
            textColor = "green";
          }

          const rowFormatEREV = `
            <tr>
              <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
                Last Month (${point.point.lmd}) Billable Acc Rev: $
              </td>
              <td style="padding-top: 4px; text-align: right;">
                ${pointFormat}
              </td>

              <td style="padding-top: 4px; text-align: right; color: ${textColor};">
                (${diff > 0 ? "+" : ""}${diffP})
              </td>
            </tr>
          `;
          dataRowsFormat += rowFormatEREV;
        }

        const p = Highcharts.numberFormat(point.y, 0);
        let pointFormat = `<b>${p}</b>`;

        const pp = Highcharts.numberFormat(point.percentage, 0);

        const rowFormat = `
          <tr>
            <td style="padding-top: 4px;font-weight: bold; text-align: right;padding-right: 4px;">
              <span style="color:${point.color}">\u25CF</span> ${point.series.name}: $
            </td>
            <td style="padding-top: 4px; text-align: right;">
              ${pointFormat}
            </td>

            <td style="padding-top: 4px; text-align: right;">
              (${pp}%)
            </td>
          </tr>
        `;
        dataRowsFormat += rowFormat;
      }

      let format = `
        ${headFormat}
        <table style="font-size: 12px;">
          ${dataRowsFormat}
        </table>
        `;

      return format;
    },
    shared: true,
    useHTML: true,
    crosshairs: true,
  };
}

function _getXAxisConfigDateTime() {
  return {
    type: "datetime",
    dateTimeLabelFormats: {
      day: "%m/%e",
    },
    // labels: {
    //   formatter: function () {
    //     const m = moment(this.value);
    //     const d = m.format("DD");
    //     // const d = m.format("MM/DD");
    //     const wd = m.format("ddd");
    //     const label = `${d} <br/> ${wd}`;
    //     if (d === "01" || this.isFirst) {
    //       return `${d} <br/> ${wd} <br/> <span style="color: #3182ce;	letter-spacing: 0.05em;">${m
    //         .format("MMM")
    //         .toUpperCase()}</span>`;
    //     }
    //     if (isWeekend(this.value)) {
    //       return `<span style="font-weight: bold; color: black;">${label}</span>`;
    //     }
    //     return label;
    //   },
    // },
    tickInterval: 24 * 60 * 60 * 1000, // 1 day
    crosshair: true,
  };
}

// function _createPlotBands(reports) {
//   const yesterdayData = _.find(reports, "isYesterday");
//   const todayData = _.find(reports, "isToday");
//   if (!yesterdayData || !todayData) {
//     return {};
//   }

//   // To highlight yesterday
//   const halfDay = 43200 * 1000;
//   const from = new Date(yesterdayData.date).getTime() - halfDay;
//   const to = new Date(todayData.date).getTime() - halfDay;

//   // highlight months
//   // 1. get months in reports
//   // 2. highlight

//   return {
//     plotBands: [
//       {
//         from,
//         to,
//       },
//     ],
//   };
// }

function _createPlotLines(reports) {
  const halfDay = 43200 * 1000;
  const dates = _.reduce(
    reports,
    (result, r) => {
      const date = new Date(r.date);
      if (date.getDate() === 1) {
        result.push({
          monthString: date.toLocaleString("en", { month: "short" }),
          value: date.getTime() - halfDay,
        });
      }
      return result;
    },
    []
  );

  const plotLines = _.map(dates, (d) => {
    return {
      color: "#fbd38d",
      width: d.monthString === "Jan" ? 5 : 1,
      value: d.value,
      label: {
        text: d.monthString,
        verticalAlign: "top",
        textAlign: "right",
        rotation: 0,
        // y: 24, // lower label from the top
        x: 26,
        style: {
          color: "#f29c09",
          fontWeight: "bold",
        },
      },
    };
  });

  return {
    plotLines,
  };
}

// function _createSelectionEvents(handleFilterByDateRange) {
//   return {
//     events: {
//       selection: function (event) {
//         // console.log(event);

//         if (event.resetSelection) {
//           handleFilterByDateRange({ startTime: null, endTime: null });
//           return;
//         }

//         // log the min and max of the primary, datetime x-axis
//         // console.log(
//         //   Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.xAxis[0].min),
//         //   Highcharts.dateFormat("%Y-%m-%d %H:%M:%S", event.xAxis[0].max)
//         // );

//         const startTime = new Date(event.xAxis[0].min);
//         const endTime = new Date(event.xAxis[0].max);

//         handleFilterByDateRange({ startTime, endTime });
//       },
//     },
//   };
// }

export default BizHighchartView;
