import React from "react";
import _ from "lodash";
import AdSizesFormatter from "./AdSizesFormatter";
import SetOfAdSizesFormatter from "./SetOfAdSizesFormatter";

class ConfigValueReadonly extends React.PureComponent {
  render() {
    const {
      valueType,
      configValue,
      //valueSchema
    } = this.props;

    if (valueType === "boolean") {
      return configValue === true ? "true" : "false";
    }

    if (valueType === "ad_sizes") {
      return (
        <AdSizesFormatter
          adSizes={configValue}
          showRemoveButton={false}
        ></AdSizesFormatter>
      );
    }

    if (valueType === "ad_sizes_layers") {
      return (
        <AdSizesFormatter
          adSizes={configValue}
          showRemoveButton={false}
        ></AdSizesFormatter>
      );
    }

    if (valueType === "sets_of_ad_sizes") {
      return (
        <SetOfAdSizesFormatter
          setsOfAdSizes={configValue}
          showActionButtons={false}
        ></SetOfAdSizesFormatter>
      );
    }

    if (valueType === "device_categories") {
      return _.isArray(configValue) ? configValue.join(", ") : "None";
    }

    if (valueType === "client_refresh_labels") {
      return _.isArray(configValue) && _.isEmpty(configValue)
        ? "[]"
        : configValue.join(", ");
    }

    if (
      valueType === "integer" ||
      valueType === "float" ||
      valueType === "integer_enum"
    ) {
      // careful of 0
      return _.isUndefined(configValue) ? "None" : configValue;
    }

    // "string_enum"
    return configValue || "None";
  }
}

export default ConfigValueReadonly;
