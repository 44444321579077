import React from "react";
import _ from "lodash";
import LoadingUI from "../common/LoadingUI";
import { getHomeDashboardData } from "../../helpers/Api";
import HomeDashboardWrapperV1 from "./home-dashboard-v1/HomeDashboardWrapperV1";
import HomeDashboardWrapperV2 from "./home-dashboard-v2/HomeDashboardWrapperV2";

const fixedWidthStyle = { maxWidth: "1280px", margin: "auto" };

class HomeDashboardViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errMsg: null,

      pubId: null,
      isReseller: false,
      resultData: null,
      // Important! Dashboard version v1 and v2 has different dashboard body
      version: null,
    };

    this.handleChangeVersion = this.handleChangeVersion.bind(this);
  }

  async componentDidMount() {
    document.title = `Home Dashboard`;
    this.setState({ isLoading: true });
    const pubId = _.get(this.props, "match.params.pubId");

    const isReseller = _.startsWith(
      _.get(this.props, "location.pathname"),
      "/playground/console-overview-mockup-reseller"
    );

    try {
      const resultData = await getHomeDashboardData(pubId, "v2");
      if (!resultData) {
        throw new Error("No Data");
      }
      const version = _.get(resultData, "version", "v1");
      console.log(resultData);
      this.setState({
        resultData,
        isReseller,
        pubId,
        version,
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: err });
    }

    this.setState({ isLoading: false });
  }

  async handleChangeVersion() {
    this.setState({ isLoading: true });
    const newVersion = this.state.version === "v1" ? "v2" : "v1";

    try {
      const resultData = await getHomeDashboardData(
        this.state.pubId,
        newVersion
      );
      if (!resultData) {
        throw new Error("No Data");
      }
      const version = _.get(resultData, "version", "v1");
      console.log(resultData);
      this.setState({
        resultData,
        version,
      });
    } catch (err) {
      console.log(err);
      this.setState({ errMsg: err });
    }

    this.setState({ isLoading: false });
  }

  render() {
    const {
      isLoading,
      errMsg,

      resultData,
      isReseller,
      pubId,
      version,
    } = this.state;

    if (isLoading) {
      return <LoadingUI></LoadingUI>;
    }

    if (errMsg) {
      return <div className="text-white">{errMsg}</div>;
    }

    return (
      <>
        <div className="bg-white px-12 border-bottom border">
          <h1 className="pt-8 pb-2" style={fixedWidthStyle}>
            <div className="text-base leading-none font-bold text-gray-600">
              Yieldbooster Console
            </div>
            <div className="flex items-center">
              <div className="font-extrabold text-gray-900 text-4xl mr-4 flex items-center">
                {isReseller ? "Reseller" : ""} Home Dashboard {version}
                <div className="text-sm px-4">
                  <button
                    className="text-blue-600 hover:text-blue-700 hover:underline"
                    onClick={this.handleChangeVersion}
                  >
                    Switch to {version === "v1" ? "v2" : "v1"}
                  </button>
                </div>
              </div>
            </div>
          </h1>
        </div>

        {version === "v1" && (
          <HomeDashboardWrapperV1
            resultData={resultData}
            isReseller={isReseller}
            pubId={pubId}
            version={version}
          ></HomeDashboardWrapperV1>
        )}

        {version === "v2" && (
          <HomeDashboardWrapperV2
            resultData={resultData}
            isReseller={isReseller}
            pubId={pubId}
            version={version}
          ></HomeDashboardWrapperV2>
        )}
      </>
    );
  }
}

export default HomeDashboardViewer;
