import React from "react";
import _ from "lodash";
import ReactTooltip from "react-tooltip";
import MetricTooltip from "../common/MetricTooltip";
import MetricView from "../common/MetricView";
import PopupWrapper from "../common/PopupWrapper";
import DemandReportTable from "./DemandReportTable";
import DemandLayerHighchart from "./DemandLayerHighchart";
import AdSizesReportTable from "./AdSizesReportTable";

const catColors = {
  B: "#d4d3ff",
  O: "#D7F5E5",
  C: "#F4F1C6",
  DX: "#E8E8E8",
};

class ExperimentGroups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      // DX used to be unimportant but it seems to be back now for some reason
      // so... always show DX for now
      // 2020/05/14
      // shouldShowDx: false,
      shouldShowDx: true,
      maxLayers: 0,
    };

    this.handleToggleShowDx = this.handleToggleShowDx.bind(this);
  }

  componentDidMount() {
    this.setState({ maxLayers: this.getMaxLayers() });
  }

  getMaxLayers() {
    // get the maximum amount of layers other than the 10 layers of "ADX_DETECTOR"

    const { groupInfo } = this.props;
    let countMaxLayer = 0;

    _.forEach(groupInfo, (g) => {
      // update: 2020/12/01 g.type === "ADX_DETECTOR" --> now means "CUSTOM_GROUP", so remove this filter
      // if (g.type === "ADX_DETECTOR") {
      // ignore
      // } else {
      let groupMaxLayers = _.keys(g.layers).length;

      if (groupMaxLayers > countMaxLayer) {
        countMaxLayer = groupMaxLayers;
      }
      // }
    });

    return countMaxLayer;
  }

  handleToggleShowDx() {
    this.setState({ shouldShowDx: !this.state.shouldShowDx });

    if (!this.state.shouldShowDx) {
      this.setState({ maxLayers: 10 });
    } else {
      this.setState({ maxLayers: this.getMaxLayers() });
    }
  }

  render() {
    const { reports, groupInfo, expId } = this.props;
    const totalWeight = _.sumBy(reports, (r) => {
      return groupInfo[r.expGroupId].weight;
    });

    return (
      <>
        <table className="text-sm font-medium overflow-x-scroll block w-full">
          <thead>
            <tr>
              <td className="border border-gray-700 text-center">Cat</td>
              <td className="border border-gray-700 text-center">GId</td>
              <td className="border border-gray-700 text-center">Request</td>
              <td className="border border-gray-700 text-center">eCPM</td>
              <td className="border border-gray-700 text-center">NetRPM</td>
              <td className="border border-gray-700 text-center">Revenue</td>
              <td className="border border-gray-700 text-center">Cost</td>
              {this.state.maxLayers
                ? _.range(0, this.state.maxLayers).map((i) => {
                    return (
                      <td
                        key={i}
                        className="border border-gray-700 text-center"
                      >
                        L{i + 1}
                      </td>
                    );
                  })
                : null}
              <td className="border border-gray-700 text-center">
                GroupParams
              </td>
            </tr>
          </thead>
          <tbody>
            {reports.map((r) => {
              const layerReports = r.reports;

              if (r.type === "DX" && !this.state.shouldShowDx) {
                return (
                  <tr
                    key={r.expGroupId}
                    style={{ backgroundColor: catColors[r.type] }}
                  >
                    <td className="border border-gray-700 text-center">
                      {r.type}
                    </td>
                    <td
                      colSpan="16"
                      className="border border-gray-700 text-center"
                    >
                      <button
                        type="button"
                        className="text-blue-600"
                        onClick={this.handleToggleShowDx}
                      >
                        {this.state.shouldShowDx ? "Hide" : "Show"} DX reports
                      </button>
                    </td>
                  </tr>
                );
              }

              return (
                <tr
                  key={r.expGroupId}
                  style={{ backgroundColor: catColors[r.type] }}
                >
                  <td className="border border-gray-700 text-center">
                    {r.type}
                    {/* {r.type === "DX" && (
                      <button
                        type="button"
                        className="text-blue-600"
                        onClick={this.handleToggleShowDx}
                      >
                        {this.state.shouldShowDx ? "←" : "→"}
                      </button>
                    )} */}
                  </td>

                  <td className="border border-gray-700 text-right">
                    <MetricTooltip
                      tooltipKey={`exp-${expId}-${r.expGroupId}`}
                      tooltipMessage={groupInfo[r.expGroupId].name}
                    >
                      {r.expGroupId}
                    </MetricTooltip>

                    <br></br>
                    <br></br>
                    <PopupWrapper
                      place="bottom left"
                      triggerElement={
                        <button
                          type="button"
                          className="border-b border-gray-600"
                        >
                          AdSizes Reports
                        </button>
                      }
                      popupElement={
                        <AdSizesReportTable
                          reports={r.adSizesReports}
                        ></AdSizesReportTable>
                      }
                    ></PopupWrapper>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <MetricView
                      mTitle="Req"
                      mValue={r.firstLayerRequest}
                      shouldFormatNumber={true}
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="Weight"
                      mValue={groupInfo[r.expGroupId].weight}
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="Traffic Ratio"
                      mValue={_.round(100 * r.weight, 2)}
                      postfix="%"
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="Expected Traffic Ratio"
                      mValue={_.round(
                        100 * (groupInfo[r.expGroupId].weight / totalWeight),
                        2
                      )}
                      postfix="%"
                    ></MetricView>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <MetricView
                      mValue={r.ecpm}
                      prefix="$"
                      shouldFormatNumber={true}
                    ></MetricView>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <span
                      className="font-semibold"
                      style={{ color: "#f71d1d" }}
                    >
                      <MetricView
                        mValue={r.rpm}
                        shouldFormatNumber={true}
                        prefix="$"
                      ></MetricView>
                    </span>
                    <br />

                    <MetricView
                      mTitle="STR"
                      mValue={r.sellThroughRate * 100}
                      shouldFormatNumber={true}
                      postfix="%"
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="MScore"
                      mValue={r.managedScore * 100}
                      shouldFormatNumber={true}
                      postfix="%"
                    ></MetricView>

                    <br />

                    <MetricView
                      mTitle="LayerLoss"
                      mValue={r.layerLoss * 100}
                      shouldFormatNumber={true}
                      postfix="%"
                    ></MetricView>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <PopupWrapper
                      triggerElement={
                        <button
                          type="button"
                          className="border-b border-gray-600"
                        >
                          <MetricView
                            mTitle="Rev"
                            mValue={r.revenue}
                            shouldFormatNumber={true}
                            prefix="$"
                          ></MetricView>
                        </button>
                      }
                      popupElement={
                        <DemandReportTable report={r}></DemandReportTable>
                      }
                    ></PopupWrapper>

                    <br />
                    <span className="whitespace-no-wrap">
                      <MetricView
                        mTitle="Imp"
                        mValue={r.impression}
                        shouldFormatNumber={true}
                      ></MetricView>
                    </span>
                    <br />

                    <PopupWrapper
                      place="top center"
                      triggerElement={
                        <button
                          type="button"
                          className="border-b border-gray-700 text-gray-800 text-xs"
                        >
                          (Demand Detail)
                        </button>
                      }
                      popupElement={
                        <DemandLayerHighchart
                          layerReports={layerReports}
                        ></DemandLayerHighchart>
                      }
                    ></PopupWrapper>
                    <br />

                    {/* <MetricView
                      mTitle="AV Viewable Imp"
                      mValue={r.totalAvViewableImp}
                      shouldFormatNumber={true}
                    ></MetricView>
                    <br />

                    <MetricView
                      mTitle="AV Measured Imp"
                      mValue={r.totalAvMeasuredImp}
                      shouldFormatNumber={true}
                    ></MetricView>

                    <br /> */}

                    <ReactTooltip
                      id={`${r.expGroupId}-adx-view`}
                      type="dark"
                      effect="solid"
                      place="top"
                    >
                      AdX AV Viewable Imp: {r.adxAvViewableImp}
                      <br></br>
                      AdX AV Measurable Imp: {r.adxAvMeasuredImp}
                    </ReactTooltip>

                    <span data-tip data-for={`${r.expGroupId}-adx-view`}>
                      <MetricView
                        mTitle="AdX Viewability"
                        mValue={_.round(r.adxViewability, 2)}
                        shouldFormatNumber={true}
                        postfix="%"
                      ></MetricView>
                    </span>
                    <br />

                    <ReactTooltip
                      id={`${r.expGroupId}-total-view`}
                      type="dark"
                      effect="solid"
                      place="top"
                    >
                      Total AV Viewable Imp: {r.totalAvViewableImp}
                      <br></br>
                      Total AV Measurable Imp: {r.totalAvMeasuredImp}
                    </ReactTooltip>

                    <span data-tip data-for={`${r.expGroupId}-total-view`}>
                      <MetricView
                        mTitle="Total Viewability"
                        mValue={_.round(r.totalViewability, 2)}
                        shouldFormatNumber={true}
                        postfix="%"
                      ></MetricView>
                    </span>
                  </td>

                  <td className="border border-gray-700 text-right">
                    <MetricView
                      mValue={r.cost}
                      shouldFormatNumber={true}
                      prefix="$"
                    ></MetricView>
                  </td>

                  {/* Layers */}
                  {this.state.maxLayers
                    ? _.range(0, this.state.maxLayers).map((i) => {
                        const layer = layerReports[i] || {};

                        // return <div>{JSON.stringify(layer)}</div>;
                        return (
                          <td
                            key={i + 1}
                            className="border border-gray-700 text-right"
                          >
                            {layer.layer && (
                              <>
                                <div className="font-bold border-gray-700 border-b text-center">
                                  <MetricView
                                    mValue={layer.price || 0}
                                    shouldFormatNumber={true}
                                    prefix="$"
                                  ></MetricView>
                                </div>

                                <div className="flex">
                                  <div className="flex-1">
                                    <MetricView
                                      mTitle="Req"
                                      mValue={layer.request}
                                      shouldFormatNumber={true}
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="Imp"
                                      mValue={layer.impression}
                                      shouldFormatNumber={true}
                                    ></MetricView>
                                    <br />

                                    <PopupWrapper
                                      triggerElement={
                                        <button
                                          type="button"
                                          className="border-b border-gray-600"
                                        >
                                          <MetricView
                                            mTitle="Rev"
                                            mValue={layer.revenue}
                                            shouldFormatNumber={true}
                                            prefix="$"
                                          ></MetricView>
                                        </button>
                                      }
                                      popupElement={
                                        <DemandReportTable
                                          report={layer}
                                        ></DemandReportTable>
                                      }
                                    ></PopupWrapper>

                                    <br />

                                    <MetricView
                                      mTitle="Cost"
                                      mValue={layer.cost}
                                      shouldFormatNumber={true}
                                      prefix="$"
                                    ></MetricView>
                                    <br />

                                    <MetricView
                                      mTitle="eCPM"
                                      mValue={layer.ecpm}
                                      shouldFormatNumber={true}
                                      prefix="$"
                                    ></MetricView>

                                    <br />
                                    {/* 
                                    <MetricView
                                      mTitle="Viewable Imp"
                                      mValue={layer.totalAvViewableImp}
                                      shouldFormatNumber={true}
                                    ></MetricView>
                                    <br /> */}

                                    <ReactTooltip
                                      id={`${r.expGroupId}-layer-${i}-adx-view`}
                                      type="dark"
                                      effect="solid"
                                      place="top"
                                    >
                                      AdX AV Viewable Imp: {r.adxAvViewableImp}
                                      <br></br>
                                      AdX AV Measurable Imp:{" "}
                                      {r.adxAvMeasuredImp}
                                    </ReactTooltip>

                                    <span
                                      data-tip
                                      data-for={`${r.expGroupId}-layer-${i}-adx-view`}
                                    >
                                      <MetricView
                                        mTitle="AdX Viewability"
                                        mValue={_.round(
                                          layer.adxViewability,
                                          2
                                        )}
                                        shouldFormatNumber={true}
                                        postfix="%"
                                      ></MetricView>
                                    </span>

                                    <br></br>

                                    <PopupWrapper
                                      place="bottom center"
                                      triggerElement={
                                        <button
                                          type="button"
                                          className="border-b border-gray-600"
                                        >
                                          AdSizes Reports
                                        </button>
                                      }
                                      popupElement={
                                        <AdSizesReportTable
                                          reports={layer.adSizesReports}
                                        ></AdSizesReportTable>
                                      }
                                    ></PopupWrapper>
                                  </div>
                                  <div className="flex-1">
                                    <MetricView
                                      mTitle="STR"
                                      mValue={
                                        layer.request > 0
                                          ? (100 * layer.impression) /
                                            layer.request
                                          : 0
                                      }
                                      shouldFormatNumber={true}
                                      postfix="%"
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="MScore"
                                      mValue={100 * layer.managedScore}
                                      shouldFormatNumber={true}
                                      postfix="%"
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="RevRatio"
                                      mValue={
                                        r.request > 0
                                          ? (100 * layer.revenue) / r.revenue
                                          : 100
                                      }
                                      shouldFormatNumber={true}
                                      postfix="%"
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="CostRatio"
                                      mValue={
                                        r.cost > 0
                                          ? (100 * layer.cost) / r.cost
                                          : 100
                                      }
                                      shouldFormatNumber={true}
                                      postfix="%"
                                    ></MetricView>
                                    <br />
                                    <MetricView
                                      mTitle="RPM"
                                      mValue={layer.rpm}
                                      shouldFormatNumber={true}
                                      prefix="$"
                                    ></MetricView>
                                    <br />

                                    <ReactTooltip
                                      id={`${r.expGroupId}-layer-${i}-total-view`}
                                      type="dark"
                                      effect="solid"
                                      place="top"
                                    >
                                      Total AV Viewable Imp:{" "}
                                      {r.totalAvViewableImp}
                                      <br></br>
                                      Total AV Measurable Imp:{" "}
                                      {r.totalAvMeasuredImp}
                                    </ReactTooltip>

                                    <span
                                      data-tip
                                      data-for={`${r.expGroupId}-layer-${i}-total-view`}
                                    >
                                      <MetricView
                                        mTitle="Total Viewability"
                                        mValue={_.round(
                                          layer.totalViewability,
                                          2
                                        )}
                                        shouldFormatNumber={true}
                                        postfix="%"
                                      ></MetricView>
                                    </span>
                                  </div>
                                </div>
                              </>
                            )}
                          </td>
                        );
                      })
                    : null}

                  <td
                    className="border border-gray-700 text-right w-full"
                    // style={{ maxWidth: "220px" }}
                  >
                    {groupInfo[r.expGroupId].groupParams &&
                      _.keys(groupInfo[r.expGroupId].groupParams).map((key) => {
                        return (
                          <div key={key}>
                            <MetricView
                              mTitle={key}
                              mValue={JSON.stringify(
                                groupInfo[r.expGroupId].groupParams[key]
                              )}
                              whitespaceNormal={true}
                            ></MetricView>
                          </div>
                        );
                      })}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  }
}

export default ExperimentGroups;
