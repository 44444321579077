import React from "react";

class SegmentModeIndicator extends React.PureComponent {
  render() {
    const { mode } = this.props;

    return (
      <div
        className={`w-2 h-2 rounded-full ${
          mode === 1
            ? "bg-green-300 text-green-900"
            : mode === 2
            ? "bg-orange-300 text-orange-900"
            : "bg-gray-300 text-gray-900"
        }`}
      ></div>
    );
  }
}

export default SegmentModeIndicator;
