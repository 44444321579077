import React from "react";
import ConfigCard from "./ConfigCard";

class ConfigView extends React.PureComponent {
  render() {
    const { optimizationSettings } = this.props;
    const { defaultConfig, segments } = optimizationSettings;

    return (
      <div className="py-8 px-4 bg-gray-300 min-h-screen text-sm relative">
        <div className="rounded p-4 bg-gray-100 text-gray-800 mb-6">
          <div className="font-semibold uppercase text-gray-600 mb-2">
            Config settings indication guide:
          </div>
          <div>
            <div className="h-1 w-1 mr-1 rounded bg-orange-500 inline-block align-middle"></div>{" "}
            <b>xxx</b>: This setting is in config and has a <u>different</u>{" "}
            value from the system's default.
          </div>
          <div className="text-gray-800">
            <b>Black values</b>: This setting is in config but is the{" "}
            <u>same</u> as the system's default value.
          </div>
          <div>
            <b className="text-gray-600">Gray values</b>: This setting is{" "}
            <i>not</i> in config. Showing the system's default value.
          </div>
        </div>
        <div>
          {segments && segments.length > 0 && (
            <div>
              {segments.map(segment => {
                return (
                  <div key={segment.segmentId} className="mb-4">
                    <div className="text-base font-semibold text-gray-800 bg-gray-300 sticky top-0 py-2">
                      Segment {segment.segmentId}:{" "}
                    </div>
                    {segment.config ? (
                      <ConfigCard config={segment.config}></ConfigCard>
                    ) : (
                      <div className="text-gray-600">No config</div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div>
          <div className="text-base font-semibold text-gray-800 bg-gray-300 sticky top-0 py-2">
            Default Config:
          </div>
          <ConfigCard config={defaultConfig}></ConfigCard>
        </div>
      </div>
    );
  }
}

export default ConfigView;
