import _ from "lodash";
import moment from "moment-timezone";

export function transformObserverData(resultData) {
  const reportCreateTime = _.get(resultData, "updatedAt", new Date());

  resultData.reportCreateTime = moment(reportCreateTime)
    .tz("Asia/Taipei")
    .format("YYYY/MM/DD HH:mm");
  resultData.reportCreateTimeAgo = moment(reportCreateTime)
    .tz("Asia/Taipei")
    .fromNow();

  // Be careful of data with no segments...
  let hasExpWithNoSegment = false;

  resultData.expInfo = _.reduce(
    resultData.expInfo,
    (result, exp, key) => {
      exp.segmentId = exp.segmentId ? exp.segmentId : -1;
      result[key] = exp;
      return result;
    },
    {}
  );

  resultData.yieldLifts = _.map(resultData.yieldLifts, (data) => {
    data.startDateString = moment(data.startDate)
      .tz("Asia/Taipei")
      .format("YYYY/MM/DD HH:mm");
    data.startDateAgo = moment(data.startDate).tz("Asia/Taipei").fromNow();

    // once one experiment has no segment, we need to add -1 to segmentInfo
    if (!hasExpWithNoSegment) {
      hasExpWithNoSegment = data.segmentId ? false : true;
    }

    data.segmentId = data.segmentId ? data.segmentId : -1;
    const reports = data.reports;

    // Calculate Lift
    const benchReports = _.filter(reports, { type: "B" });
    if (benchReports.length > 0) {
      const totalRev = _.sumBy(reports, "revenue");
      const totalReq = _.sumBy(reports, "firstLayerRequest");
      const totalCost = _.sumBy(reports, "cost");

      const benchRev = _.sumBy(benchReports, "revenue");
      const benchReq = _.sumBy(benchReports, "firstLayerRequest");
      const benchRpm = benchReq > 0 ? (benchRev * 1000) / benchReq : 0;
      const originalRev = benchRpm * (totalReq / 1000);

      const grossLift =
        originalRev > 0
          ? _.round(((totalRev - originalRev) / originalRev) * 100, 2)
          : "∞";
      const netLift =
        originalRev > 0
          ? _.round(
              ((totalRev - originalRev - totalCost) / originalRev) * 100,
              2
            )
          : "∞";

      // data.revenue = totalRev;
      data.grossLift = grossLift;
      data.netLift = netLift;
    } else {
      data.grossLift = 0;
      data.netLift = 0;
    }
    return data;
  });

  if (hasExpWithNoSegment) {
    resultData.segmentInfo.push({ id: -1, targeting: {} });
  }

  return resultData;
}
