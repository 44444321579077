import React from "react";
import _ from "lodash";
import BasicDataTable from "./BasicDataTable";

class HealthCheckItem extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showFacts: false
    };

    this.toggleShowFacts = this.toggleShowFacts.bind(this);
  }

  toggleShowFacts() {
    this.setState({ showFacts: !this.state.showFacts });
  }

  render() {
    const { item, handleSelectTab } = this.props;
    const itemTitle = _.upperFirst(_.lowerCase(item.name));

    let itemClasses = ["rounded bg-white shadow p-4 my-3 text-sm"];
    if (item.healthScore > 75) {
      itemClasses.push("border-l-4 border-green-300");
    } else if (item.healthScore > 25) {
      itemClasses.push("border-l-4 border-orange-300");
    } else {
      itemClasses.push("border-l-4 border-red-300");
    }

    itemClasses = itemClasses.join(" ");

    let tabKey = null;
    switch (item.name) {
      case "INTOWOW_BENCHMARK_VALIDATION_CHECKS": {
        tabKey = "BENCHMARK_VALIDATION";
        break;
      }
      case "INTOWOW_UPR_IMPRESSION_RATIO_FOR_OPTIMIZATION_TRAFFIC": {
        tabKey = "UPR_VALIDATION";
        break;
      }
      default: {
        // nothing
      }
    }

    return (
      <div className={itemClasses}>
        <div className="font-semibold text-blue-800">{itemTitle}</div>
        <div className="text-gray-800 text-xs">{item.metrics}</div>
        <div className="my-3 flex justify-between items-end">
          <div className="leading-none">
            <div className="uppercase font-semibold text-blue-600 text-xs">
              Health Score
            </div>
            <div className="font-bold text-xl text-gray-700 mt-2">
              {item.healthScore}
            </div>
          </div>
          <div>
            {tabKey && (
              <button
                type="button"
                className="border border-blue-300 text-blue-500 rounded py-1 px-2 shadow"
                onClick={() => handleSelectTab(tabKey)}
              >
                View Details
              </button>
            )}
          </div>
        </div>

        <div>
          <div>
            <button
              type="button"
              className="text-blue-500 underline"
              onClick={this.toggleShowFacts}
            >
              Show facts
            </button>
          </div>

          {this.state.showFacts ? (
            <div className="w-full overflow-x-auto">
              <BasicDataTable
                headings={item.facts.columns}
                rows={item.facts.rows}
              ></BasicDataTable>
            </div>
          ) : (
            ""
          )}
        </div>

        {item.symptoms && item.symptoms.length > 0 && (
          <div className="my-4">
            <hr className="my-2"></hr>
            <div className="uppercase font-semibold text-blue-600 text-xs">
              Symptoms
            </div>
            {item.symptoms.map((sym, i) => {
              return (
                <div key={i}>
                  {i + 1}: {sym}
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

// class BasicTable extends React.PureComponent {
//   render() {
//     const headers = [
//       {
//         title: "Date",
//         key: "date", //

//         textAlign: "text-left",
//         extraStyles: ""
//         // replaceStyles: "",
//       }
//     ];
//     return (
//       <table className="w-full table text-sm border shadow">
//         <thead className="bg-gray-200 text-blue-800 text-xs border-b">
//           <tr>
//             <th className="border text-right py-1 px-4">Date</th>
//             <th className="border text-right py-1 px-4">
//               Optimization Adx Impression
//             </th>
//             <th className="border text-right py-1 px-4">
//               Intowow Rule Adx Impression
//             </th>
//             <th className="border text-right py-1 px-4">Imp Ratio</th>
//           </tr>
//         </thead>
//         <tbody className="text-gray-900 font-mono bg-white">
//           {reports.map((r, i) => {
//             const weekdayAbbr = getWeekdayAbbr(r.date);
//             return (
//               <tr key={i} className="border-b cursor-pointer hover:bg-gray-100">
//                 <td className="text-right py-1 px-4 whitespace-no-wrap border">
//                   {i === 0 && <span className="font-bold">*</span>}
//                   {r.date} {weekdayAbbr}
//                 </td>

//                 <td className="text-right py-1 px-4 border">
//                   {_.isNumber(r.optimizationAdxImpression) ? (
//                     <NumberFormat
//                       value={r.optimizationAdxImpression}
//                       format="0,0"
//                     ></NumberFormat>
//                   ) : (
//                     "-"
//                   )}
//                 </td>

//                 <td className="text-right py-1 px-4 border">
//                   {_.isNumber(r.intowowRuleAdxImpression) ? (
//                     <NumberFormat
//                       value={r.intowowRuleAdxImpression}
//                       format="0,0"
//                     ></NumberFormat>
//                   ) : (
//                     "-"
//                   )}
//                 </td>
//                 <td className="text-right py-1 px-4 border">
//                   {_.isNumber(r.impressionRatio) ? (
//                     <NumberFormat
//                       value={r.impressionRatio}
//                       format="0,0.00%"
//                     ></NumberFormat>
//                   ) : (
//                     "-"
//                   )}
//                 </td>
//               </tr>
//             );
//           })}
//         </tbody>
//       </table>
//     );
//   }
// }

export default HealthCheckItem;
