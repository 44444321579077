import React from "react";
import _ from "lodash";

class SegmentTarget extends React.PureComponent {
  render() {
    const { targetData, title } = this.props;

    return (
      <div>
        {targetData && (
          <span className={`p-1 mx-1`}>
            <span className="text-base pr-1">{title}</span>
            {targetData.includes ? (
              <span>
                <span className="text-green-600 text-lg">+</span>
                {targetData.includes.map(d => {
                  return (
                    <span
                      key={d}
                      className={`px-2 bg-${
                        targetData.includes ? "green" : "red"
                      }-200 mx-1 rounded text-sm text-${
                        targetData.includes ? "green" : "red"
                      }-900`}
                    >
                      {d}
                    </span>
                  );
                })}
              </span>
            ) : (
              <span>
                <span className="text-red-600 text-lg">-</span>
                {targetData.excludes.map(d => {
                  return (
                    <span
                      key={d}
                      className={`px-2 bg-${
                        targetData.includes ? "green" : "red"
                      }-200 mx-1 rounded text-sm  text-${
                        targetData.includes ? "green" : "red"
                      }-900`}
                    >
                      {d}
                    </span>
                  );
                })}
              </span>
            )}
          </span>
        )}
      </div>
    );
  }
}

class SegmentDetail extends React.PureComponent {
  render() {
    const { segmentData } = this.props;
    const countryTargeting = _.get(segmentData, "targeting.country");
    const osTargeting = _.get(segmentData, "targeting.os");
    const deviceCatTargeting = _.get(segmentData, "targeting.deviceCategory");
    const hourTargeting = _.get(segmentData, "targeting.hour");

    return (
      <>
        {_.keys(segmentData.targeting).length > 0 ? (
          <>
            <SegmentTarget
              targetData={countryTargeting}
              title="Country"
            ></SegmentTarget>
            <SegmentTarget targetData={osTargeting} title="OS"></SegmentTarget>
            <SegmentTarget
              targetData={deviceCatTargeting}
              title="Display Category"
            ></SegmentTarget>
            <SegmentTarget
              targetData={hourTargeting}
              title="Hour"
            ></SegmentTarget>
          </>
        ) : (
          "Not segmented"
        )}
      </>
    );
  }
}

export default SegmentDetail;
