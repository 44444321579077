import React from "react";
import _ from "lodash";

import GeoCountrySelector from "./GeoCountrySelector";
import {
  GOOGLE_ACCOUNT_TYPE,
  GAM_LOGO_TYPE,
} from "../../../constants/Publisher";
import GAMHeaderNormal from "../../../images/ad_manager_header_raw.png";
import GAMHeader360 from "../../../images/ad_manager360_header.png";
import GAMSidebarBilling from "../../../images/gam_billing_section_focus.png";
import { IoMdCheckmark } from "react-icons/io";

class PublisherFormGAMProfile extends React.Component {
  constructor(props) {
    super(props);

    const { geoCountryId } = props;

    this.state = {
      geoCountryId,

      errorMsg: "",
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.onInputChanged = this.onInputChanged.bind(this);
  }

  onInputChanged(value, key) {
    this.setState({
      [key]: value,
    });
  }

  handleNextStep() {
    // 1. validate (move inside child component)
    //  - if pass -> save to state, go to step 2
    //  - if failed -> show error message (currentErrorMsg)

    const { geoCountryId } = this.state;
    let finalForm = {};

    if (!geoCountryId || geoCountryId === "") {
      this.setState({
        errorMsg: "Please your GAM country",
      });
      return;
    }

    finalForm.geoCountryId = geoCountryId;

    this.props.handleSubmit(finalForm);
  }

  render() {
    const { handleGoToLastStep, isSaving } = this.props;
    const { errorMsg } = this.state;

    return (
      <div>
        <div className="text-3xl font-bold text-gray-700 mb-8 border-b border-gray-400">
          Invoice Setting
        </div>
        <div>
          <div className="text-lg font-semibold text-gray-800">
            What is your invoicing country?
          </div>
          <div>
            <GeoCountrySelector
              handleOnChange={(value) =>
                this.onInputChanged(value, "geoCountryId")
              }
            ></GeoCountrySelector>
          </div>
        </div>

        <div className="text-red-600 mb-6 font-semibold">{errorMsg}</div>
        <div className="flex justify-end mt-8">
          <button
            type="button"
            className="text-blue-600 rounded font-semibold px-6 py-2"
            onClick={handleGoToLastStep}
            disabled={isSaving}
          >
            Previous
          </button>

          <button
            type="button"
            className="bg-blue-500 rounded font-semibold text-white px-6 py-2"
            onClick={this.handleNextStep}
            disabled={isSaving}
          >
            {isSaving ? "Creating Publisher..." : "Create Publisher"}
          </button>
        </div>
      </div>
    );
  }
}

export default PublisherFormGAMProfile;
