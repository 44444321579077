import _ from "lodash";

/**
 * A predefined hint in frontend should look like this:
 * {
 *    type: DC_SMARTPHONE,
 *    config: {...}
 * }
 *
 * Then when saving, convert it to:
 * {
 *    targetings: {"device_category":{"includes":["30000"]}},
 *    autopilot_config: {...}
 * }
 */

const DEVICE_TARGETING = {
  Desktop: "30000",
  Smartphone: "30001",
  Tablet: "30002",
};

const TYPE_TARGETING_MAP = {
  DC_DESKTOP: {
    id: "DC_DESKTOP",
    name: "Desktop",
    targetings: {
      device_category: {
        includes: [DEVICE_TARGETING["Desktop"]],
      },
    },
  },

  DC_TABLET: {
    id: "DC_TABLET",
    name: "Tablet",
    targetings: {
      device_category: {
        includes: [DEVICE_TARGETING["Tablet"]],
      },
    },
  },

  DC_SMARTPHONE: {
    id: "DC_SMARTPHONE",
    name: "Smartphone",
    targetings: {
      device_category: {
        includes: [DEVICE_TARGETING["Smartphone"]],
      },
    },
  },
};
const PREDEFINED_TYPES = _.keys(TYPE_TARGETING_MAP);

export function getTargetingsByHintType(hintType) {
  return _.get(TYPE_TARGETING_MAP, [hintType, "targetings"]);
}

export function getHintTypeFromUserHint(userHint) {
  const h = _.find(TYPE_TARGETING_MAP, (type) => {
    return _.isEqual(userHint.targetings, type.targetings);
  });
  return _.get(h, "id");
}

export function calculateAvailableHints({ userHints }) {
  const usedHintIds = _.map(userHints, getHintTypeFromUserHint);
  const availableHintIds = _.difference(PREDEFINED_TYPES, usedHintIds);
  return _.map(availableHintIds, (id) => {
    const h = TYPE_TARGETING_MAP[id];
    // fill in default config for hints!
    // Important! only the Prophet section!
    h.config = {};
    return h;
  });
}

export function convertUserHintsForUI({ userHints }) {
  return _.map(userHints, (userHint) => {
    const id = getHintTypeFromUserHint(userHint);
    const h = TYPE_TARGETING_MAP[id];
    if (!h) {
      throw new Error(
        `Unidentified Targeting in user hints: ${JSON.stringify(userHint)}`
      );
    }
    // fill in default config for hints!
    // Important! only the Prophet section!
    h.config = _.get(userHint, "autopilot_config");

    if (
      _.has(h.config, "prophet.benchmark_abuse_sizes") &&
      h.config.prophet.benchmark_abuse_sizes.length > 0
    ) {
      h.config.prophet.benchmark_abuse_sizes = _.first(
        h.config.prophet.benchmark_abuse_sizes
      );
    }
    return h;
  });
}

export function resolveHintDefaultConfig({ unitConfig, defaultConfig }) {
  const uc = _.cloneDeep(unitConfig);
  const dc = _.cloneDeep(defaultConfig);
  return _.defaultsDeep(uc, dc);
}
