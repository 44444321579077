import React from "react";
import _ from "lodash";
import { FiEdit } from "react-icons/fi";
import AutoPilotConfigRadio from "./AutoPilotConfigRadio";
import ConfigValueReadonly from "./ConfigValueReadonly";

class ConfigValueControl extends React.PureComponent {
  constructor(props) {
    super(props);

    const isUseDefault = _.isUndefined(props.configValue);

    this.state = {
      isUseDefault,
      newValue: props.configValue,

      // TODO
      hasError: false,
    };

    this.handleIsUseDefaultChanged = this.handleIsUseDefaultChanged.bind(this);
    this.handleNewValueChanged = this.handleNewValueChanged.bind(this);
    this.handleApplyClicked = this.handleApplyClicked.bind(this);
    this.handleCancelClicked = this.handleCancelClicked.bind(this);
    this.handleHasError = this.handleHasError.bind(this);
  }

  handleHasError(hasError) {
    this.setState({ hasError });
  }

  handleIsUseDefaultChanged(isUseDefault) {
    this.setState({ isUseDefault });
  }

  handleNewValueChanged(value) {
    this.setState({ newValue: value });
  }

  handleCancelClicked() {
    const isUseDefault = _.isUndefined(this.props.configValue);
    this.setState({
      isUseDefault,
      newValue: this.props.configValue,
    });

    this.props.handleCancelEditMode();
  }

  handleApplyClicked(event) {
    event.preventDefault();
    if (this.state.hasError) {
      return;
    }

    const { isUseDefault, newValue } = this.state;
    let value = newValue;

    const {
      valueType,

      // unit,
      key,
    } = this.props.valueSchema;

    switch (valueType) {
      case "integer":
      case "integer_enum": {
        value = _.parseInt(value);
        break;
      }

      case "float": {
        value = _.floor(value, 2);
        break;
      }

      case "boolean": {
        if (typeof newValue === "string") {
          value = newValue === "true" ? true : false;
        }
        break;
      }

      // "string_enum"
      default: {
      }
    }

    this.props.handleApplyChanges({
      sectionConfigKey: this.props.sectionConfigKey,
      configKey: key,
      isUseDefault,
      newValue: value,
    });
  }

  render() {
    const {
      configValue,
      valueSchema,
      currentEditingKey,
      handleStartEditMode,
    } = this.props;

    const { valueType, defaultValue, unit } = valueSchema;
    const configKey = valueSchema.key;

    const { isUseDefault, hasError } = this.state;

    const hasConfigValue = !isUseDefault;
    const isEditingCurrentConfigValue = configKey === currentEditingKey;

    return (
      <>
        {isEditingCurrentConfigValue ? (
          <div>
            <form onSubmit={this.handleApplyClicked}>
              <div className="flex px-4">
                <div className="w-3/4">
                  <AutoPilotConfigRadio
                    configKey={configKey}
                    defaultValue={defaultValue}
                    unit={unit}
                    configValue={configValue}
                    valueSchema={valueSchema}
                    valueType={valueType}
                    isUseDefault={isUseDefault}
                    handleIsUseDefaultChanged={this.handleIsUseDefaultChanged}
                    handleNewValueChanged={this.handleNewValueChanged}
                    handleHasError={this.handleHasError}
                  ></AutoPilotConfigRadio>
                </div>
              </div>

              <div className="flex items-center pl-4 text-sm mt-8">
                <button
                  type="submit"
                  disabled={hasError}
                  className={`${
                    hasError
                      ? "bg-gray-600 cursor-not-allowed"
                      : "bg-blue-500 hover:bg-blue-700"
                  } text-white font-semibold rounded shadow py-1 px-8`}
                >
                  Apply changes
                </button>

                <button
                  type="button"
                  className="text-blue-500 font-semibold py-1 px-8"
                  onClick={this.handleCancelClicked}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div>
            <div className="flex px-4">
              <div className="w-3/4 flex justify-between">
                <div className="px-2 w-full">
                  {hasConfigValue ? (
                    <div>
                      {/* BODY */}
                      <div className="text-blue-600 font-semibold">
                        <ConfigValueReadonly
                          valueType={valueType}
                          configValue={configValue}
                          valueSchema={valueSchema}
                        ></ConfigValueReadonly>
                      </div>
                      {/* FOOT */}
                      <div className="text-gray-600 text-sm">{unit}</div>
                    </div>
                  ) : (
                    <div>
                      {/* BODY */}
                      <div className="text-gray-600 font-semibold">
                        <ConfigValueReadonly
                          valueType={valueType}
                          configValue={defaultValue}
                        ></ConfigValueReadonly>
                      </div>
                      {/* FOOT */}
                      <div className="text-gray-600 text-sm">{unit}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-1/4 text-right">
                <button
                  type="button"
                  disabled={!!currentEditingKey}
                  className={`rounded shadow p-4 ${
                    !!currentEditingKey
                      ? "bg-gray-400 text-white cursor-not-allowed"
                      : "bg-white text-gray-800"
                  }`}
                  onClick={() => handleStartEditMode(valueSchema.key)}
                >
                  <FiEdit></FiEdit>
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ConfigValueControl;
