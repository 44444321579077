import React from "react";
import _ from "lodash";
import moment from "moment-timezone";
import { FiSun, FiMoon } from "react-icons/fi";

class TimeOfDayProgressBar extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { currentTimeInTimezone, timezone } = this.props;

    let currentTime = currentTimeInTimezone;
    if (!currentTimeInTimezone) {
      currentTime = moment().tz(timezone);
    }

    const mDate = moment(currentTime);
    const date = mDate.format("YYYY-MM-DD");
    const time = mDate.format("HH:mm");
    const hour = mDate.hour();
    const weekday = mDate.format("ddd");

    const hoursLeft = 24 - hour;
    const progress = _.round((hour / 24) * 100, 0);

    return (
      <div
        className="flex items-center"
        title={`${hoursLeft} hours until midnight`}
      >
        <div className="flex items-center">
          <DayIcon hour={hour}></DayIcon>
          <div className="mx-1">
            {date} {weekday}
          </div>
          <div className="font-bold">{time}</div>
        </div>

        <div className="w-16 pr-2">
          <div
            className="bg-gray-400 ml-1 -mr-2"
            style={{
              position: "relative",
              overflow: "hidden",
              height: "4px"
            }}
          >
            <div
              style={{
                position: "absolute",
                marginLeft: "48%",
                width: "1px",
                height: "8px",
                backgroundColor: "indigo"
              }}
            ></div>
            <div
              className="bg-indigo-400"
              style={{ width: `${progress}%`, height: "8px" }}
            ></div>
          </div>
        </div>
        <div className="ml-1 text-gray-600">{progress}%</div>
      </div>
    );
  }
}

const DayIcon = ({ hour }) => {
  if (hour <= 6 || hour > 18) {
    return (
      <div className="text-gray-600 text-xs">
        <FiMoon></FiMoon>
      </div>
    );
  } else {
    return (
      <div className="text-gray-600 text-xs">
        <FiSun></FiSun>
      </div>
    );
  }
};

export default TimeOfDayProgressBar;
